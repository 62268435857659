import FONTS from 'constants/Fonts'
import { TextProps } from 'pages/Terms/Components/Subtitle'
import useResize from 'utils/useResize'

const ScienceParagraph = ({ children }: TextProps) => {
  const screenSize = useResize()
  return (
    <p
      style={{
        color: '#FCFCFC',
        marginBottom: '20px',
        fontFamily: FONTS.regular,
        fontSize: screenSize.width > 765 ? '16px' : '14px',
        opacity: 0.8,
        lineHeight: '140%'
      }}>
      {children}
    </p>
  )
}

export default ScienceParagraph
