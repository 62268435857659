import ImageAssets from 'assets/ImageAssets'
import Stars from 'components/Background/Stars/Stars'
import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import { useEffect, useState } from 'react'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import useResize from 'utils/useResize'
import './styles.css'

interface ComingSoonProps {
  endTime: Date
}

const ComingSoon = ({ endTime }: ComingSoonProps) => {
  const screenSize = useResize()
  const [playAnimation, setPlayAnimation] = useState(false)
  const [visible, setVisible] = useState(true)

  const RenderTimer = ({ days, hours, minutes, seconds }: CountdownRenderProps) => {
    return (
      <p
        className="font-bold text-center"
        style={{
          color: COLORS.white,
          fontSize: screenSize.width > 765 ? '90px' : '30px'
        }}>
        {days}d : {hours}h : {minutes}m : {seconds}s
      </p>
    )
  }

  useEffect(() => {
    if (playAnimation) {
      setTimeout(() => {
        setVisible(false)
      }, 2000)
    }
  }, [playAnimation])

  return (
    <div
      style={{
        display: visible ? 'flex' : 'none',
        backgroundColor: COLORS.backgroundColor,
        zIndex: 40,
        animation: playAnimation ? 'coming-soon 2s linear' : undefined
      }}
      className={`w-screen overflow-hidden h-screen fixed items-center justify-center`}>
      <Stars />
      <div className="relative container flex items-center justify-center flex-col mx-auto px-5 md:px-0">
        <div className="flex items-center gap-2">
          <img
            style={{
              width: '42px'
            }}
            src={ImageAssets.logo}
            alt=""
          />
          <img
            style={{
              height: '18px'
            }}
            src={ImageAssets.textLogo}
            alt=""
          />
        </div>
        <SizedBox height={20} />
        <h2
          className="text-center"
          style={{
            color: COLORS.white,
            fontSize: '34px'
          }}>
          Metawise is coming soon
        </h2>
        <Countdown
          onComplete={() => setPlayAnimation(true)}
          renderer={RenderTimer}
          date={endTime}
        />
      </div>
    </div>
  )
}

export default ComingSoon
