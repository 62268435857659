import { IndicatorProps } from 'pages/About/Components/SlideIndicator'

const SlideIndicator = ({ selected }: IndicatorProps) => {
  return (
    <div>
      <div
        style={{
          backgroundColor: selected ? '#B1B9EE' : '#FCFCFC',
          opacity: selected ? 1 : 0.4
        }}
        className={`${selected ? 'w-4' : 'w-2'} h-2 mx-1 rounded-full`}
      />
    </div>
  )
}

export default SlideIndicator
