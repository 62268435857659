/* eslint-disable @typescript-eslint/no-unused-vars */
import IconAssets from 'assets/IconAssets'
import SizedBox from 'components/Divider/SizedBox'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import useResize from 'utils/useResize'
import ModalInput from './ModalInput'

export interface ModalContentProps {
  nextAction?: any
}

const RegisterModal = ({ nextAction }: ModalContentProps) => {
  const screenSize = useResize()
  const [agree, setAgree] = useState(false)
  return (
    <div
      className="relative flex flex-col items-center self-center"
      style={{
        width: screenSize.width > 765 ? undefined : '95vw',
        backgroundColor: '#FCFCFC',
        borderRadius: '20px',
        padding: screenSize.width > 765 ? '64px' : '32px'
      }}>
      <img
        style={{
          marginBottom: '48px'
        }}
        src={IconAssets.whiteLogo}
        alt=""
      />
      <ModalInput placeholder="Email address" rightClick />
      <SizedBox height={24} />
      <ModalInput placeholder="Email verification code" />
      <div className="mt-10 self-center">
        <input
          checked={agree}
          onChange={() => setAgree(!agree)}
          type="checkbox"
          name=""
          id="agree"
        />
        <label className="ml-2" htmlFor="agree">
          I agree to Metawise&apos;s{' '}
          <Link
            to="/terms"
            style={{
              color: '#9D59EF'
            }}>
            Terms of Use
          </Link>{' '}
          &{' '}
          <Link
            style={{
              color: '#9D59EF'
            }}
            to="/privacy">
            Privacy Policy
          </Link>
        </label>
      </div>
      <button
        className="uppercase font-semibold"
        style={{
          marginTop: '40px',
          marginBottom: '24px',
          color: '#FCFCFC',
          fontSize: '14px',
          borderRadius: '100px',
          backgroundColor: '#9D59EF',
          width: screenSize.width > 765 ? '388px' : '85vw',
          padding: '16px'
        }}>
        SIGN UP
      </button>
      <p onClick={nextAction} className="cursor-pointer underline font-semibold" style={{}}>
        Account Login
      </p>
    </div>
  )
}

export default RegisterModal
