import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import TeachingSection from '../components/TeachingSection'
import RadioItem from '../SelectType/RadioItem'
import ActionButton from '../components/ActionButton'
import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'

export interface StudentPracticeProps {
  back: any
  next: any
  save?: any
}

const StudentPractice2 = ({ back, next }: StudentPracticeProps) => {
  const { expertise } = useGetStarted()
  const updateProperty = useUpdateGetStarted()
  return (
    <div className="flex mb-10 md:mb-0 items-center flex-col justify-center gap-3 w-[400px]">
      <h3
        className="text-center font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Tell Us More About You
      </h3>
      <p
        className="text-sm text-center opacity-60"
        style={{
          color: COLORS.white
        }}>
        To tailor your Metawise experience and help us understand your needs better, please fill out
        this questionnaire
      </p>
      <SizedBox height={10} />
      <div
        className="w-[90%] md:w-full mx-auto gap-2 flex flex-col items-start"
        style={{
          backgroundColor: '#2e2633',
          borderRadius: '10px',
          padding: '20px'
        }}>
        <TeachingSection number={2} />
        <h4
          className="font-semibold"
          style={{
            color: COLORS.white,
            fontSize: '16px'
          }}>
          Your Meditation Expertise
        </h4>
        <p
          className="opacity-60"
          style={{
            fontSize: '14px',
            color: COLORS.white
          }}>
          What is your level of experience with meditation?
        </p>
        <Divider top={10} bottom={10} />
        <div>
          <p
            className="text-sm opacity-60"
            style={{
              color: COLORS.white
            }}>
            Are you interested{' '}
          </p>
          <SizedBox height={10} />
          <RadioItem
            onClick={() => updateProperty('expertise', 'beginner')}
            subtitle="I'm just getting started"
            label="Beginner  (0 - 2 years)"
            active={expertise === 'beginner'}
          />
          <RadioItem
            onClick={() => updateProperty('expertise', 'intermediate')}
            subtitle="I have some experience"
            label="Intermediate (2 -5 years regular practice)"
            active={expertise === 'intermediate'}
          />
          <RadioItem
            onClick={() => updateProperty('expertise', 'advanced')}
            subtitle="I meditate regularly"
            label="Advanced (5-10 years, 1-2 enlightenment experiences)"
            active={expertise === 'advanced'}
          />
          <RadioItem
            onClick={() => updateProperty('expertise', 'master')}
            subtitle="Multiple natural enlightenment experiences, operate in high consciousness states "
            label="Master (10+ years)"
            active={expertise === 'master'}
          />
        </div>
        <SizedBox height={40} />
        <div className="w-full flex items-center justify-stretch gap-3">
          <ActionButton type="outline" onClick={back}>
            Back
          </ActionButton>
          <ActionButton onClick={next}>Continue</ActionButton>
        </div>
      </div>
    </div>
  )
}

export default StudentPractice2
