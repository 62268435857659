import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import { Link } from 'react-router-dom'

export interface LogoProps {
  size?: 'large' | 'small'
  showText?: boolean
}

const Logo = ({ size = 'large', showText = true }: LogoProps) => {
  return (
    <Link className="flex flex-row items-center" to="/">
      <img
        style={size === 'large' ? styles.largeImg : styles.smallImg}
        src={ImageAssets.headerLogo}
        alt=""
      />
      {showText && (
        <div className="flex flex-row items-center">
          <SizedBox width={'20px'} />
          <img
            style={size === 'large' ? styles.largeText : styles.smallText}
            src={IconAssets.textLogo}
            alt=""
          />
        </div>
      )}
    </Link>
  )
}

const styles = {
  largeImg: {
    width: '46px'
  },
  smallImg: {
    width: '28px'
  },
  largeText: {
    height: '20px'
  },
  smallText: {
    height: '13px'
  }
}

export default Logo
