import COLORS from 'constants/Colors'
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useScrollPercentage } from 'react-scroll-percentage'
import useResize from 'utils/useResize'
import TeamMemberItem from './TeamMemberItem'
import SizedBox from 'components/Divider/SizedBox'
import ImageAssets from 'assets/ImageAssets'

interface HorizontalTeamSectionProps {
  showModal: any
}

const HorizontalTeamSection = ({ showModal }: HorizontalTeamSectionProps) => {
  const screenSize = useResize()

  const scrollRef = useRef<any>(null)
  const ghostRef = useRef<any>(null)

  const [scrollRange, setScrollRange] = useState(0)
  const [viewportW, setViewportW] = useState(0)

  const scrollPerc = useMotionValue(0)

  useLayoutEffect(() => {
    scrollRef && setScrollRange(scrollRef.current.scrollWidth)
  }, [scrollRef])

  const onResize = useCallback((entries: any) => {
    for (const entry of entries) {
      setViewportW(entry.contentRect.width)
    }
  }, [])

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => onResize(entries))
    resizeObserver.observe(ghostRef.current)
    return () => resizeObserver.disconnect()
  }, [onResize])

  const [containerRef, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0.1
  })

  useEffect(() => {
    scrollPerc.set(percentage)
  }, [percentage])

  const transform = useTransform(scrollPerc, [0, 1], [0, -scrollRange + (viewportW - 150)])
  const physics = { damping: 20, mass: 0.25, stiffness: 75 }
  const spring = useSpring(transform, physics)
  return (
    <div
      className="hidden md:block"
      style={{
        marginTop: screenSize.width > 765 ? '0px' : '80px'
      }}
      ref={containerRef}>
      <div className="scroll-container">
        <motion.section ref={scrollRef} style={{ x: spring }} className="flex thumbnails-container">
          <h1
            className="font-semibold"
            style={{
              marginLeft: '200px',
              marginRight: '80px',
              width: '300px',
              lineHeight: '48px',
              padding: '0px 40px',
              fontSize: '48px',
              color: COLORS.white
            }}>
            Meet the team
          </h1>
          <div className="thumbnails">
            <TeamMemberItem
              onClick={() => showModal('ramzi')}
              image={ImageAssets.ramzi}
              name="Ramzi Do"
              offset={100}
              title="Founder & CEO"
            />
            <SizedBox width={20} />
            <TeamMemberItem
              onClick={() => showModal('rafael')}
              image={ImageAssets.rafael}
              name="Rafael Espericueta"
              title="Director of Machine Learning"
            />
            <SizedBox width={20} />
            <TeamMemberItem
              onClick={() => showModal('daniel')}
              image={ImageAssets.daniel}
              name="Daniel Isaac"
              offset={100}
              title="Director of Engineering"
            />
            <div style={{ width: '250px' }} />
          </div>
        </motion.section>
      </div>
      <div ref={ghostRef} style={{ height: scrollRange }} className="ghost" />
    </div>
  )
}

export default HorizontalTeamSection
