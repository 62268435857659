import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import { motion } from 'framer-motion'
import { ReactNode } from 'react'
import { slideInNoDelay, staggerContainer } from 'utils/motion'
import useResize from 'utils/useResize'

interface StudentDetailSectionProps {
  image: string
  direction?: 'left' | 'right'
  subtitle: string
  children: ReactNode
  step: number
}

const StudentDetailSection = ({
  image,
  direction = 'right',
  subtitle,
  children,
  step
}: StudentDetailSectionProps) => {
  const screenSize = useResize()
  return (
    <motion.div
      variants={staggerContainer()}
      viewport={{ once: true, amount: 0.25 }}
      initial="hidden"
      whileInView="show"
      style={{
        marginBottom: '62px'
      }}
      className={`container mx-auto px-5 md:px-0 justify-between flex ${
        screenSize.width > 765
          ? direction === 'right'
            ? 'flex-row'
            : 'flex-row-reverse'
          : 'flex-col'
      } items-start`}>
      <motion.img
        className="md:mb-0 mb-3"
        variants={slideInNoDelay('right')}
        style={{
          width: screenSize.width > 765 ? '47%' : undefined
        }}
        src={image}
        alt=""
      />
      <motion.div
        variants={slideInNoDelay('left')}
        style={{
          width: screenSize.width > 765 ? '40%' : undefined
        }}>
        <div
          className="rounded-lg flex items-center justify-center"
          style={{
            padding: '6px',
            width: '70px',
            backgroundColor: 'rgba(157, 89, 239, 1)'
          }}>
          <p
            className="text-sm"
            style={{
              color: COLORS.white
            }}>
            Step {step}
          </p>
        </div>
        <SizedBox height={20} />
        <h2
          style={{
            color: '#FCFCFC',
            lineHeight: '97%',
            margin: screenSize.width > 765 ? undefined : '30px 0px',
            fontSize: screenSize.width > 765 ? '48px' : '18px'
          }}>
          {subtitle}
        </h2>
        <SizedBox height={10} />
        {children}
      </motion.div>
    </motion.div>
  )
}

export default StudentDetailSection
