import COLORS from 'constants/Colors'
import { Link } from 'react-router-dom'

interface AcceptTermsProps {
  checked: boolean
  setChecked: any
}

const AcceptTerms = ({ checked, setChecked }: AcceptTermsProps) => {
  return (
    <div onClick={setChecked} className="cursor-pointer flex items-center gap-2">
      <div
        className="rounded-sm"
        style={{
          backgroundColor: checked ? COLORS.gold : 'transparent',
          width: '16px',
          border: `1px solid ${COLORS.white}`,
          height: '16px'
        }}
      />
      <p
        className="text-sm opacity-90"
        style={{
          color: COLORS.white
        }}>
        I agree with the{' '}
        <Link
          target="_blank"
          to="/terms"
          style={{
            color: COLORS.gold
          }}
          className="underline">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link
          target="_blank"
          to="/privacy"
          style={{
            color: COLORS.gold
          }}
          className="underline">
          Privacy Policy
        </Link>
      </p>
    </div>
  )
}

export default AcceptTerms
