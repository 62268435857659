import { ReactNode } from 'react'
import useResize from 'utils/useResize'

interface DivItemProps {
  icon: any
  children: ReactNode
}
const DivItem = ({ icon, children }: DivItemProps) => {
  const screenSize = useResize()
  return (
    <div
      style={{
        width: 320,
        margin: screenSize.width > 765 ? undefined : '10px 0px'
      }}
      className="flex flex-row items-center">
      <img
        style={{
          width: '70px'
        }}
        src={icon}
        alt=""
      />
      <p
        style={{
          marginLeft: '10px',
          fontSize: '18px',
          color: '#FCFCFC'
        }}>
        {children}
      </p>
    </div>
  )
}

export default DivItem
