import ScienceParagraph from 'pages/Science/Components/ScienceParagraph'
import TeamMemberDetail from '../Components/TeamMemberDetail'
import ImageAssets from 'assets/ImageAssets'

export const renderModal = (visibleModal: string, setVisibleModal: any) => {
  switch (visibleModal) {
    case 'david':
      return (
        <TeamMemberDetail
          close={() => setVisibleModal('none')}
          detailHeader="Exploring the Depths of Human Potential: David Verdesi's Journey in Mind Training and Mysticism"
          name="David Verdesi"
          title="Spiritual Advisor"
          image={ImageAssets.david}>
          <ScienceParagraph>
            David Verdesi is a distinguished spiritual anthropologist, researcher, and life coach,
            celebrated for his extensive travels and profound studies in the pursuit of life&apos;s
            ultimate purpose and the enhancement of human potential. His 30-year odyssey has taken
            him to the farthest corners of the world, where he has learned from 120 mystics, yogis,
            cave-dwellers, saints, and enlightened beings across a spectrum of traditions, including
            Siddhas from India, Xian immortals from China and Southeast Asia, Shamans from Central
            and South America and Africa, and mystics from Europe and the northern Steppes of
            Russia, Tibet, and Mongolia.
          </ScienceParagraph>
          <ScienceParagraph>
            Verdesi&apos;s work concentrates on the actualization of individuation through applied
            mind training, drawing comparisons across wisdom traditions and methods of askesis. His
            exploration into the phenomenology of human potential and altered states of
            consciousness has led to the development of an algorithm for navigating, exploring, and
            integrating the human psyche into wholeness. This holistic approach is dedicated to
            fostering human potential and achieving happiness.
          </ScienceParagraph>
          <ScienceParagraph>
            Verdesi&apos;s range of expertise spans traditional Chinese Qi Gong, Daoism, Shamanism,
            and the communication and codification of myth, mysticism, and spirituality. His
            collaborative research with major universities and hospitals in China and the United
            States has gained international recognition. His work includes being featured as the
            main character in Elionor Coppola&apos;s documentary “Superhuman” and collaborations
            with scientists from Stanford, MIT, and Cornell, as well as serving as a UN expert on
            human potential.
          </ScienceParagraph>
          <ScienceParagraph>
            Against this backdrop of relentless pursuit and deep exploration, David Verdesi&apos;s
            path converges at a single, pivotal question. This question, echoing through the
            corridors of ancient temples and modern research labs, resonates at the core of each
            human heart. It&apos;s a question that transcends time, culture, and belief, yet remains
            intimately personal. In the silence that follows his profound teachings, as the dust of
            our existential journeys settles, we find ourselves standing at the threshold of the
            most fundamental discovery. Here, at the intersection of ancient wisdom and modern
            inquiry, each of us is invited to confront the query that is both the beginning and the
            end of our search, a question that defines the essence of our existence: &quot;Who am
            I?&quot;
          </ScienceParagraph>
        </TeamMemberDetail>
      )
    case 'dat':
      return (
        <TeamMemberDetail
          close={() => setVisibleModal('none')}
          detailHeader="Machine Learning Advisor and Mind Explorer, Driving Innovation in AI and Consciousness Research"
          name="Dat Do"
          title="Machine Learning Advisor"
          image={ImageAssets.dat}>
          <ScienceParagraph>
            Dat Do, our Machine Learning advisor and current Director of Machine Learning at
            ElementaryML.com, brings a wealth of expertise to our team. Working at the cutting-edge
            of the AI industry, he leverages camera-based machine learning algorithms to identify
            product supply chain defects.
          </ScienceParagraph>
          <ScienceParagraph>
            Under Dat&apos;s leadership, ElementaryML.com has successfully secured $50 million in
            funding, a testament to his remarkable acumen and the impact of his work. His
            professional achievements underscore his prowess in driving growth and innovation in the
            machine learning space.
          </ScienceParagraph>
          <ScienceParagraph>
            A lifelong explorer of the mind, Dat harbors a deep fascination with the enigma of
            consciousness. His exploration of the cognitive realms complements his advanced
            technical expertise, adding a unique depth to his approach.
          </ScienceParagraph>
          <ScienceParagraph>
            While his cousin Ramzi pursued spirituality, Dat continued to advance in computer
            science, leading him to the forefront of machine learning research today. His combined
            interest in cognitive mysteries and technological progress contributes to our
            team&apos;s unique blend of mindfulness and innovation.
          </ScienceParagraph>
        </TeamMemberDetail>
      )
    case 'james':
      return (
        <TeamMemberDetail
          close={() => setVisibleModal('none')}
          detailHeader="Blockchain Visionary and Innovator, Pioneering the Future of Decentralized Finance"
          name="James Anderson"
          title="Web 3 Advisor"
          image={ImageAssets.james}>
          <ScienceParagraph>
            James Anderson, our Blockchain advisor and a key figure in decentralized finance, brings
            extensive expertise from his time as CEO and co-founder of RioDeFi. His deep engagement
            with blockchain technology since 2013 has been instrumental in advancing the field.
          </ScienceParagraph>
          <ScienceParagraph>
            Under James&apos; leadership, RioDeFi aimed to bridge traditional and decentralized
            financial systems, highlighting his commitment to innovation and his vision for a more
            accessible financial ecosystem.
          </ScienceParagraph>
          <ScienceParagraph>
            As a strong advocate for blockchain and a serial entrepreneur, James has been at the
            forefront of social entrepreneurship in the digital currency and decentralized finance
            space, contributing significantly to the industry&apos;s growth and understanding.
          </ScienceParagraph>
          <ScienceParagraph>
            His journey in blockchain and decentralized finance is marked by a dedication to
            pioneering new paths and exploring the potentials of these technologies for creating a
            more inclusive and efficient financial world.
          </ScienceParagraph>
        </TeamMemberDetail>
      )

    case 'daniel':
      return (
        <TeamMemberDetail
          close={() => setVisibleModal('none')}
          detailHeader="The Engineering Prodigy Turning Vision into Reality"
          name="Daniel Isaac"
          title="Director of Engineering"
          image={ImageAssets.daniel}>
          <ScienceParagraph>
            Daniel Issac, our dedicated and dynamic engineer, brings a youthful vitality and sharp
            acumen to our team. Equipped with an innate aptitude for logic and efficiency, Daniel
            has made an indelible mark in the realm of software engineering.
          </ScienceParagraph>
          <ScienceParagraph>
            His leadership credentials are underscored by his tenure as a Senior Software Engineer
            for a prominent Cambodian food-delivery app. In this role, Daniel honed his skills in
            leading a team of developers, transforming abstract code into functional,
            revenue-generating solutions.
          </ScienceParagraph>
          <ScienceParagraph>
            In our team, Daniel&apos;s role is integral and multifaceted. He&apos;s been the driving
            force behind translating our vision into tangible code, shaping everything from our
            website and platform to the integration of advanced technologies such as EEGs and
            Machine Learning.
          </ScienceParagraph>
          <ScienceParagraph>
            The embodiment of our &quot;ideas into reality&quot; ethos, Daniel&apos;s ability to
            marry vision with technical execution has been pivotal to our success. His work serves
            as the foundational bedrock of our platform, turning conceptual ideas into implementable
            and impactful solutions.
          </ScienceParagraph>
        </TeamMemberDetail>
      )
    case 'rafael':
      return (
        <TeamMemberDetail
          close={() => setVisibleModal('none')}
          detailHeader="Director of Machine Learning and Spiritual Advisor, Merging AI, Education, and Meditation"
          name="Rafael Espericueta"
          title="Director of Machine Learning"
          image={ImageAssets.rafael}>
          <ScienceParagraph>
            Rafael Espericueta, our esteemed Director of Machine Learning, stands as a beacon of
            wisdom and technical mastery. Combining charisma and intellect, Rafael initiated his
            journey with meditation in the 60s, sparked by the profound teachings of Alan Watts. His
            meditative practice and insight also enable him to serve as a spiritual advisor within
            our team.
          </ScienceParagraph>
          <ScienceParagraph>
            A seasoned veteran in high-level mathematics with 30 years of teaching experience,
            Rafael is now advancing his academic pursuits with a PhD in Artificial Intelligence. His
            research is centered on Large Language Models (LLMs) and their applications in
            education, reflecting his enduring commitment to learning and his passion for bridging
            technology with human cognition.
          </ScienceParagraph>
          <ScienceParagraph>
            Rafael&apos;s AI portfolio includes pioneering a groundbreaking heart catheter visual
            system utilizing Optical Coherence Tomography. This potential game-changer in the realm
            of heart surgery underscores his ability to translate cutting-edge AI technology into
            real-world solutions.
          </ScienceParagraph>
          <ScienceParagraph>
            Rafael&apos;s personal meditation experiences, complemented by his knowledge of EEGs,
            give him a deep understanding of the significant electrophysiological shifts occurring
            during deep meditation. Capitalizing on this understanding, he is currently crafting an
            innovative machine learning model for meditative-state detection, soon to be a
            cornerstone of our platform.
          </ScienceParagraph>
        </TeamMemberDetail>
      )
    case 'kyle':
      return (
        <TeamMemberDetail
          close={() => setVisibleModal('none')}
          detailHeader=" A Chief Strategy Officer with a Humanitarian Heart, Melding Neuropsychology with Blockchain for Greater Good"
          name="Kyle Mohamed"
          title="Chief Strategy Officer"
          image={ImageAssets.kyle}>
          <ScienceParagraph>
            Kyle Mohamed, our esteemed Chief Strategy Officer, possesses a unique interdisciplinary
            background blending psychology with a keen interest in data sciences and A.I. As a
            well-read and thoughtful individual, Kyle lends his profound insights to our team,
            enriching our collective knowledge base.
          </ScienceParagraph>
          <ScienceParagraph>
            Kyle&lsquo;s leadership acumen is underscored by his past experience managing large
            charity-driven organizations, with a notable tenure in Nepal. His time in Nepal, marked
            by a significant earthquake, offered him firsthand exposure to large-scale humanitarian
            efforts, shaping his understanding of social impact.
          </ScienceParagraph>
          <ScienceParagraph>
            His professional journey took a significant turn in 2017, when he ventured into the
            crypto domain with his first purchase. This initiation has since blossomed into a
            central aspect of his professional endeavors.
          </ScienceParagraph>
          <ScienceParagraph>
            Self-identified as a utilitarian and an anti-capitalist, Kyle&lsquo;s philosophical
            convictions guide his approach towards his work and life. These guiding principles
            infuse our team with a unique perspective, broadening our understanding and approach
            towards our collective goals.
          </ScienceParagraph>
        </TeamMemberDetail>
      )

    case 'ramzi':
      return (
        <TeamMemberDetail
          close={() => setVisibleModal('none')}
          detailHeader="Ramzi is a Lifelong Explorer, Bridging Spirituality and Technology for Personal Growth"
          name="Ramzi Do"
          title="Founder & CEO"
          image={ImageAssets.ramzi}>
          <ScienceParagraph>
            Born in California to a Vietnamese-Lebanese family, Ramzi represents a confluence of
            Eastern and Western cultures. At an early age, his passion for technology and gaming was
            ignited, establishing the bedrock for his future pursuits. The ideological dichotomy at
            home—with an atheist father and a spiritually inclined, non-practicing Muslim
            mother—catalyzed Ramzi&lsquo;s exploration into existential questions, triggering a deep
            dive into spirituality and the nature of consciousness.
          </ScienceParagraph>
          <ScienceParagraph>
            This journey took root at the age of 10 and further deepened when he started exploring
            philosophy and neuroscience at 14, developing a keen interest in lucid dreaming.
          </ScienceParagraph>
          <ScienceParagraph>
            At 16, Ramzi and his cousin Dat made the audacious choice to responsibly explore the use
            of psychedelics for self-discovery. This shared journey would significantly influence
            their future collaboration.
          </ScienceParagraph>
          <ScienceParagraph>
            In his late teens, Ramzi began his experimentation with transcendence tech, integrating
            daily meditation into his routine, and adopted Bitcoin, setting the stage for a future
            at the intersection of spirituality and technology.
          </ScienceParagraph>
          <ScienceParagraph>
            Encouraged by personal struggles and various mystical traditions, he decided to leave
            college in his early 20s to focus on inner work and spiritual growth. At 22, a profound
            self-realization experience triggered by the question &quot;Who am I?&quot; led him to a
            21-day retreat in Thailand, a crucial turning point in his spiritual journey.
          </ScienceParagraph>
          <ScienceParagraph>
            This journey was further amplified at 25 with a transformative realization, akin to a
            lucid dream but in waking life. It was a potent fusion of yin and yang, where he felt as
            though he carried the voltage of a lightning bolt in his lower belly.
          </ScienceParagraph>
          <ScienceParagraph>
            This transformative event led Ramzi to seek guidance from masters such as David Verdesi,
            who had dedicated his early life to seeking out individuals with superhuman powers. One
            such extraordinary individual is a powerful Chinese master whom David brought to the
            West to heal people in secret. Meeting this Chinese master, who had permanently
            harnessed the lightning bolt power Ramzi had briefly experienced, was a pivotal point in
            deepening his spiritual journey. These mentors emphasized the need for harmonizing
            spiritual exploration with tangible worldly success.
          </ScienceParagraph>
          <ScienceParagraph>
            Today, Ramzi dedicates himself to Metawise, an EEG meditation platform. Guided by
            synchronicity and serendipity, he reunited with Dat, now a successful AI professional,
            and orchestrated the formation of the Metawise team. Together, they strive to create a
            future where Self-knowledge, compassion and high consciousness states are financially
            rewarded, and spirituality and science seamlessly coexist.
          </ScienceParagraph>
        </TeamMemberDetail>
      )
    default:
      return <></>
  }
}
