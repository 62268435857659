import IconAssets from 'assets/IconAssets'
// import ImageAssets from 'assets/ImageAssets'
import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import Header from 'components/Header/Header'
import useResize from 'utils/useResize'
import ContactForm from './ContactForm'
import { motion } from 'framer-motion'
import { slideInNoDelay, slideUpNoDelay } from 'utils/motion'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import Stars from 'components/Background/Stars/Stars'

const ContactHeroSection = () => {
  const screenSize = useResize()
  return (
    <div className="relative">
      <Stars />
      <Header />
      <div className="relative z-[5]">
        <SizedBox height={screenSize.width > 765 ? 85 : 70} />
        <div
          style={{
            zIndex: 2
          }}
          className="px-5 md:px-0 relative container mx-auto">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <StaggerWrapper>
              <div
                style={{
                  width: screenSize.width > 765 ? '500px' : undefined
                }}
                className="flex flex-col items-center md:items-start">
                <motion.h1
                  variants={slideUpNoDelay()}
                  style={{
                    lineHeight: screenSize.width > 765 ? '48px' : '50px',
                    fontSize: screenSize.width > 765 ? '48px' : '50px'
                  }}
                  className="text-center md:text-left text-white">
                  Get in touch
                </motion.h1>
                <SizedBox height={screenSize.width > 765 ? 40 : 24} />
                <motion.p
                  variants={slideUpNoDelay()}
                  style={{
                    color: '#FCFCFC',
                    fontSize: screenSize.width > 765 ? '20px' : '14px',
                    opacity: 0.8,
                    lineHeight: '140%'
                  }}
                  className="text-center md:text-left w-9/12">
                  Fill up the contact form or email us at the address below. Our client service
                  would love to hear from you!
                </motion.p>
                <SizedBox height={screenSize.width > 765 ? 40 : 24} />
                <motion.a variants={slideUpNoDelay()} href="mailto:info@metawise.gg">
                  <p
                    style={{
                      width: 'auto',
                      borderBottom: '2px solid #9D59EF',
                      color: '#FCFCFC',
                      fontSize: screenSize.width > 765 ? '24px' : '14px',
                      lineHeight: '140%'
                    }}
                    className="text-center md:text-left w-9/12">
                    info@metawise.gg
                  </p>
                </motion.a>
                <SizedBox height={screenSize.width > 765 ? 95 : 44} />
                <div className="flex flex-row items-center w-full md:justify-start justify-center">
                  <motion.a variants={slideInNoDelay('left')} href="https://discord.gg/J9Mmhf57uP">
                    <img
                      style={{
                        width: screenSize.width > 765 ? undefined : '30vw'
                      }}
                      src={IconAssets.discordB}
                      alt=""
                    />
                  </motion.a>
                  <SizedBox width={40} />
                  <motion.a
                    variants={slideInNoDelay('left')}
                    href="https://twitter.com/MetawiseDojo">
                    <img
                      style={{
                        width: screenSize.width > 765 ? undefined : '30vw'
                      }}
                      src={IconAssets.twitter}
                      alt=""
                    />
                  </motion.a>
                </div>
              </div>
            </StaggerWrapper>
            <SizedBox height={screenSize.width > 765 ? 0 : 40} />
            <StaggerWrapper delay={0.75}>
              <ContactForm />
            </StaggerWrapper>
          </div>
          <SizedBox height={100} />
          <Divider />
        </div>
      </div>
    </div>
  )
}

export default ContactHeroSection
