import IconAssets from 'assets/IconAssets'
import SizedBox from 'components/Divider/SizedBox'
import useResize from 'utils/useResize'
import CompanyItem from './CompanyItem'
import { motion } from 'framer-motion'
import { textVariant } from 'utils/motion'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'

const CompaniesSection = () => {
  const screenSize = useResize()
  return (
    <div className="relative container mx-auto px-5 md:px-0">
      <div className="relative flex flex-col">
        <motion.p
          viewport={{ once: true, amount: 'some' }}
          variants={textVariant(0.25)}
          initial="hidden"
          whileInView="show"
          className="self-center text-center"
          style={{
            width: screenSize.width > 765 ? '600px' : undefined,
            color: '#FCFCFC',
            fontSize: '32px'
          }}>
          We&apos;re proud to partner with innovative companies and organizations who support our
          vision.
        </motion.p>
        <SizedBox height={100} />
        <div>
          <StaggerWrapper delay={0.5}>
            <CompanyItem
              image={IconAssets.neuro}
              subtitle="Neurosity "
              actionTitle="GO TO NEUROSITY"
              description="There are a number of EEG headsets currently available on the marketplace and, having done our due diligence, we feel that the Neurosity CROWN is the best EEG device for our project's needs."
            />
            <CompanyItem
              image={IconAssets.bws}
              subtitle="Blockchain Web Services (BWS)"
              actionTitle="GO TO BWS"
              description="Metawise will use BWS's blockchain-powered ML algorithms to analyze user-generated data and incentivize community members to become miners on the BWS chain."
            />
            <CompanyItem
              image={IconAssets.halolab}
              subtitle="Halo Lab"
              actionTitle="GO TO Halo Lab"
              description="Ukraine's award-winning design-driven web development agency, chosen to meet our project's needs with unrivaled expertise"
            />
          </StaggerWrapper>
        </div>
      </div>
    </div>
  )
}

export default CompaniesSection
