import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import COLORS from 'constants/Colors'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router'
import { textVariantNoDelay } from 'utils/motion'
import useResize from 'utils/useResize'
const PreAlphaSection = () => {
  const screenSize = useResize()
  const navigate = useNavigate()
  return (
    <StaggerWrapper delay={0.75}>
      <motion.div
        className="container mx-auto px-5 md:px-0"
        style={{
          marginTop: 20
        }}>
        <div
          style={{
            backgroundImage: `url(${ImageAssets.etherealBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: screenSize.width > 765 ? '100%' : '220%',
            backgroundPosition: 'center',
            backgroundPositionX: screenSize.width > 765 ? '20px' : '-190px',
            backgroundPositionY: screenSize.width > 765 ? undefined : '-30px'
          }}
          className="container px-5 md:px-0 mx-auto flex flex-col items-center">
          <SizedBox height={screenSize.width > 765 ? 200 : 0} />
          <motion.p
            variants={textVariantNoDelay()}
            style={{
              fontSize: screenSize.width > 765 ? '32px' : '18px',
              color: '#FCFCFC',
              width: screenSize.width > 765 ? '618px' : '90%'
            }}
            className="text-center mb-10">
            Become an Alpha Tester today and begin shaping the reality of tomorrow
          </motion.p>
          <motion.p
            className="text-center mb-10"
            style={{
              width: screenSize.width > 765 ? '618px' : '90%',
              color: COLORS.white,
              opacity: 0.6
            }}>
            {
              'It is difficult to say what is impossible,\nfor the dream of yesterday\nis the hope of today\nand the reality of tomorrow.\n—Robert H. Goddard'
            }
          </motion.p>
          <button
            onClick={() => {
              navigate('/get-started')
            }}
            className="uppercase hover:bg-transparent transition-all bg-[#9d59ef] cursor-pointer text-sm"
            style={{
              border: '1px solid #9d59ef',
              color: COLORS.white,
              padding: '12px 24px',
              borderRadius: '24px'
            }}>
            Get started
          </button>
          <SizedBox height={200} />
        </div>
      </motion.div>
    </StaggerWrapper>
  )
}

export default PreAlphaSection
