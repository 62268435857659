import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import ScienceParagraph from 'pages/Science/Components/ScienceParagraph'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'
import useResize from 'utils/useResize'

interface CompanyProps {
  description: string
  actionTitle: string
  subtitle: string
  image: string
}
const CompanyItem = ({ description, actionTitle, subtitle, image }: CompanyProps) => {
  const screenSize = useResize()
  return (
    <motion.div
      variants={slideUpNoDelay()}
      style={{
        marginTop: '40px'
      }}>
      <Divider />
      <SizedBox height={40} />
      <div
        className={`flex ${
          screenSize.width > 765 ? 'flex-row' : 'flex-col'
        } items-start justify-between`}>
        <div
          style={{
            margin: screenSize.width > 765 ? undefined : '20px 0px',
            width: screenSize.width > 765 ? '30%' : '35%'
          }}>
          <img src={image} alt="" />
        </div>

        <h3
          style={{
            color: '#FCFCFC',
            margin: screenSize.width > 765 ? undefined : '10px 0px 20px 0px',
            fontSize: '20px',
            width: '160px'
          }}>
          {subtitle}
        </h3>
        <div
          style={{
            width: screenSize.width > 765 ? '35%' : undefined
          }}>
          <ScienceParagraph>{description}</ScienceParagraph>
          <Link to="/">
            <button
              style={{
                padding: '7px 22px'
              }}
              className="hidden md:block border hover:bg-white hover:text-black transition-colors text uppercase text-sm rounded-3xl text-white">
              {actionTitle}
            </button>
          </Link>
        </div>
      </div>
    </motion.div>
  )
}

export default CompanyItem
