import Divider from 'components/Divider/Divider'
import { useState } from 'react'
import PhaseItem from './PhaseItem'
import { motion } from 'framer-motion'
interface PhaseProps {
  duration: string
  number: number
  status: string
  title: string
  phases: any
  description?: string | undefined
}

const Phase = ({ duration, number, status, title, phases, description }: PhaseProps) => {
  const [expanded, setExpanded] = useState(false)
  const toggleHeight = () => {
    setExpanded(!expanded)
  }

  const capitalizeExceptInfinity = (duration: string): string => {
    return duration === '∞ and Beyond'
      ? duration
      : duration.toLowerCase().replace(/\b(\w)/g, (s: string) => s.toUpperCase())
  }

  return (
    <motion.div
      initial={{
        height: 'auto'
      }}
      animate={
        expanded
          ? {}
          : {
              height: 70,
              transition: {
                height: {
                  duration: 0.4
                }
              }
            }
      }
      className="px-5 h-auto md:px-0 transition-all overflow-hidden">
      <div className="container mx-auto">
        <Divider />
        <div
          onClick={toggleHeight}
          style={{
            zIndex: 2
          }}
          className="cursor-pointer relative flex py-5 flex-row items-center justify-between container mx-auto">
          <h1
            style={{
              color: '#FCFCFC',
              fontSize: '20px',
              width: '40%'
            }}>
            Phase {number}
          </h1>
          <p
            style={{
              color: '#FCFCFC',
              fontSize: '16px'
            }}
            className="text-center">
            {capitalizeExceptInfinity(duration)}
          </p>
          <p
            style={{
              color: '#FCFCFC',
              fontSize: '16px'
            }}
            className="flex-1 text-end capitalize">
            {status}
          </p>
        </div>
        <Divider />
        <PhaseItem description={description} title={title} phases={phases} />
      </div>
    </motion.div>
  )
}

export default Phase
