import SizedBox from 'components/Divider/SizedBox'
import Footer from 'components/Footer/Footer'
// import useResize from 'utils/useResize'
import DividerSection from './Components/DividerSection'
import NewsletterSection from './Components/NewsletterSection'
import ResearchCategorySection from './Components/ResearchCategorySection'
import ResearchHeroSection from './Components/ResearchHeroSection'
import { useEffect } from 'react'

const Research = () => {
  useEffect(() => {
    document.title = 'Metawise | Research'
  }, [])
  return (
    <div>
      <ResearchHeroSection />
      <div className="relative">
        <DividerSection />
        <SizedBox height={144} />
        <ResearchCategorySection />
        <SizedBox height={140} />
        <NewsletterSection />
        <Footer />
      </div>
    </div>
  )
}

export default Research
