import COLORS from 'constants/Colors'

interface RadioItemProps {
  label: string
  onClick?: any
  active: boolean
  subtitle?: string
}

const RadioItem = ({ label, active, onClick, subtitle = '' }: RadioItemProps) => {
  return (
    <div
      onClick={onClick}
      style={{
        marginBottom: '10px'
      }}
      className="flex cursor-pointer items-center gap-2">
      <div
        className="flex items-center justify-center rounded-full"
        style={{
          height: '18px',
          width: '18px',
          border: `1px solid ${active ? COLORS.gold : COLORS.white}`
        }}>
        <div
          className="rounded-full"
          style={{
            width: '12px',
            height: '12px',
            background: active ? COLORS.gold : 'transparent'
          }}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <p
          style={{
            color: COLORS.white
          }}>
          {label}
        </p>
        {subtitle !== '' ? (
          <p
            className="text-xs opacity-60"
            style={{
              color: COLORS.white
            }}>
            {subtitle}
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default RadioItem
