import ImageAssets from 'assets/ImageAssets'
import Divider from 'components/Divider/Divider'
import { useState } from 'react'

interface ContactItemProps {
  question: string
  answer: string
}

const ContactItem = ({ question, answer }: ContactItemProps) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div className="transition-all">
      <Divider />
      <div
        style={{
          margin: '30px 0px'
        }}>
        <div className="flex items-center justify-between">
          <p
            style={{
              fontSize: '24px',
              color: '#FCFCFC'
            }}>
            {question}
          </p>
          <img
            onClick={() => {
              setExpanded(!expanded)
            }}
            style={{
              cursor: 'pointer',
              width: '48px'
            }}
            src={expanded ? ImageAssets.contactButton2 : ImageAssets.contactButton}
            alt=""
          />
        </div>
        {expanded && (
          <p
            className="text-sm"
            style={{
              color: '#FCFCFC',
              opacity: 0.8,
              margin: '10px 0'
            }}>
            {answer}
          </p>
        )}
      </div>
      <Divider />
    </div>
  )
}

export default ContactItem
