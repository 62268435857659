import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import SlideButton from 'pages/Home/Components/SlideButton'
import useResize from 'utils/useResize'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'
import IconAssets from 'assets/IconAssets'

const StartTripSection = () => {
  const screenSize = useResize()
  return (
    <StaggerWrapper>
      <div
        style={{
          backgroundImage: `url(${ImageAssets.etherealBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: screenSize.width > 765 ? '100%' : '190%',
          backgroundPosition: 'center',
          backgroundPositionX: screenSize.width > 765 ? '20px' : '-170px',
          backgroundPositionY: screenSize.width > 765 ? undefined : '130px'
        }}
        className="container px-5 md:px-0 mx-auto flex flex-col items-center">
        <SizedBox height={screenSize.width > 765 ? 120 : 40} />
        <motion.h3
          variants={slideUpNoDelay()}
          className="text-center"
          style={{
            width: screenSize.width > 765 ? '620px' : undefined,
            marginTop: '30px',
            color: '#FCFCFC',
            fontSize: '32px',
            marginBottom: '30px'
          }}>
          Join us on this scientific and spiritual journey into the mysterious world of meditation.
        </motion.h3>
        <motion.p
          variants={slideUpNoDelay()}
          style={{
            fontSize: '18px',
            color: '#FCFCFC',
            opacity: 0.8,
            width: screenSize.width > 765 ? '418px' : undefined
          }}
          className="text-center mb-10">
          Let&apos;s unlock the secrets of the mystical and bring them into the modern world, where
          anyone can access the transformative power of meditation and neurofeedback training.
        </motion.p>
        <SlideButton
          variant={slideUpNoDelay()}
          href="https://dojo.metawise.gg"
          image={
            <img
              style={{
                width: '40px'
              }}
              src={IconAssets.discord}
            />
          }>
          Take a Trip
        </SlideButton>
        <SizedBox height={200} />
      </div>
    </StaggerWrapper>
  )
}

export default StartTripSection
