import COLORS from 'constants/Colors'
import { ReactNode, useState } from 'react'
import OnboardingItem from './OnboardingItem'
import IconAssets from 'assets/IconAssets'
import SizedBox from 'components/Divider/SizedBox'

interface OnboardingMenuItemProps {
  active: boolean
  children: ReactNode
}
const OnboardingMenuItem = ({ active, children }: OnboardingMenuItemProps) => {
  const [show, setShow] = useState(false)
  return (
    <div
      className="relative cursor-pointer"
      style={{
        marginLeft: '40px'
      }}>
      <p
        onClick={() => setShow(true)}
        style={{
          fontSize: '14px',
          borderBottom: active ? '2px solid #9D59EF' : undefined,
          color: '#FCFCFC',
          opacity: active ? 1 : 0.8
        }}>
        {children}
      </p>
      {show && (
        <>
          <div
            className="fixed top-0 left-0 right-0 bottom-0 bg-transparent"
            onClick={() => setShow(false)}
          />
          <div
            style={{
              top: '35px',
              width: '300px',
              padding: '12px',
              backgroundColor: COLORS.white
            }}
            className="rounded-lg absolute">
            <p className="text-sm">Instructions for getting started</p>
            <SizedBox height={15} />
            <div>
              <OnboardingItem
                image={IconAssets.teacher}
                title="For teachers"
                description="Working with the platform"
                to="/onboarding/teacher"
              />
              <SizedBox height={15} />
              <OnboardingItem
                image={IconAssets.teacher}
                title="For practitioners"
                description="Simple steps to perfection"
                to="/onboarding/student"
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default OnboardingMenuItem
