import moment from 'moment'
import { useEffect, Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function ScrollToTop({ children, endTime }: any) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (pathname !== '/') {
      const timeDifference = moment().diff(moment(endTime)) / 1000
      if (timeDifference < 0) {
        navigate('/')
      }
    }
    window.scrollTo(0, 0)
  }, [pathname])

  return <Fragment>{children}</Fragment>
}

export default ScrollToTop
