import { useState } from 'react'
import GetStartedHeader from './components/GetStartedHeader'
import SelectType from './SelectType/SelectType'
import SizedBox from 'components/Divider/SizedBox'
import PersonalInformation from './PersonalInformation/PersonalInformation'
import EnterPlatform from './EnterPlatform/EnterPlatform'
import TeachingPractice3 from './Teacher/TeachingPractice3'
import TeachingPractice2 from './Teacher/TeachingPractice2'
import TeachingPractice1 from './Teacher/TeachingPractice1'
import Stars from 'components/Background/Stars/Stars'
import StudentPractice1 from './Student/StudentPractice1'
import StudentPractice2 from './Student/StudentPractice2'
import StudentPractice3 from './Student/StudentPractice3'
import StudentPractice4 from './Student/StudentPractice4'
import StudentPractice5 from './Student/StudentPractice5'
import axios from 'axios'
import ProvideEmailPage from './ProvideEmailPage'
import { toast } from 'react-toastify'

const GetStarted = () => {
  const [_type, setType] = useState('')
  const [page, setPage] = useState(0)

  const saveData = (data: any) => {
    axios
      .post(
        'https://metawisedojoapi.metawise.gg/api/v1/website-signup',
        { ...data, update: true },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(({ data }) => {
        toast('You can now access the beta platform')
        console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const renderPage = () => {
    switch (page) {
      case 0:
        return <ProvideEmailPage next={() => setPage(1)} />
      case 1:
        return (
          <SelectType
            onClick={(type: string) => {
              setType(type)
              setPage(2)
            }}
          />
        )
      case 2:
        return <PersonalInformation back={() => setPage(1)} next={setPage} />
      case 3:
        return <EnterPlatform />
      // case 3:
      //   return <PurchaseCrown skip={() => setPage(4)} back={setPage} next={() => setPage(4)} />
      case 4:
        return <EnterPlatform />
      case 5:
        return <TeachingPractice1 back={() => setPage(2)} next={() => setPage(6)} />
      case 6:
        return <TeachingPractice2 back={() => setPage(5)} next={() => setPage(7)} />
      case 7:
        return <TeachingPractice3 save={saveData} back={() => setPage(6)} next={setPage} />
      case 8:
        return <StudentPractice1 back={() => setPage(2)} next={() => setPage(9)} />
      case 9:
        return <StudentPractice2 back={() => setPage(8)} next={() => setPage(10)} />
      case 10:
        return <StudentPractice3 back={() => setPage(9)} next={() => setPage(11)} />
      case 11:
        return <StudentPractice4 back={() => setPage(10)} next={() => setPage(12)} />
      case 12:
        return <StudentPractice5 save={saveData} back={() => setPage(11)} next={setPage} />
      default:
        return <></>
    }
  }

  return (
    <div className="relative">
      <GetStartedHeader currentPage={1} />
      <Stars />
      <div className="relative z-[5]">
        <SizedBox height={100} />
        <div className="container mx-auto flex items-center justify-center">{renderPage()}</div>
      </div>
    </div>
  )
}

export default GetStarted
