import { motion } from 'framer-motion'
interface SizedBoxProps {
  height?: number | string | undefined
  width?: number | string | undefined
}
const SizedBox = ({ height = 0, width = 0 }: SizedBoxProps) => {
  return (
    <motion.div
      className="transparent"
      style={{
        height,
        width
      }}
    />
  )
}

export default SizedBox
