import React from 'react'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import FONTS from 'constants/Fonts'

interface CardItemProps {
  name: string
  authors: string
  year: number
  imgSrc?: string
}

const CardItem: React.FC<CardItemProps> = ({ name, authors, year, imgSrc }) => {
  // ...
  return (
    <div
      style={{
        overflow: 'hidden',
        margin: '20px 0px',
        borderBottomRightRadius: '12px',
        borderBottomLeftRadius: '12px',
        width: '400px',
        height: '470px'
      }}>
      <img
        src={imgSrc ?? ImageAssets.research}
        style={{
          height: '300px',
          width: '100%',
          objectFit: 'cover'
        }}
        alt={name}
      />
      <div
        style={{
          padding: '24px',
          backgroundColor: '#2E2633'
        }}>
        <div
          style={{
            marginBottom: '10px'
          }}>
          <p
            className="uppercase"
            style={{
              color: '#9D59EF',
              fontSize: '10px'
            }}>
            Data analysis
          </p>
        </div>
        <p
          style={{
            fontFamily: FONTS.bold,
            color: '#FCFCFC',
            fontSize: '22px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          className="title">
          {name}
        </p>
        <div className="flex mt-5 items-center flex-row">
          <div className="flex items-center justify-center ">
            <img
              className="object-center w-[32px]"
              style={{
                backgroundColor: '#2E2633'
              }}
              src={ImageAssets.logo}
              alt=""
            />
          </div>
          <div
            className="text-[#FCFCFC]"
            style={{
              marginLeft: '10px'
            }}>
            <p
              style={{
                fontFamily: FONTS.medium
              }}
              className="text-[16px]">
              {authors}
            </p>
            <p
              className="text-[16px]"
              style={{
                opacity: '0.8'
              }}>
              {year}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardItem
