import ImageAssets from 'assets/ImageAssets'
import Footer from 'components/Footer/Footer'
// import useResize from 'utils/useResize'
import ScienceHeroSection from './Components/ScienceHeroSection'
import ScienceParagraph from './Components/ScienceParagraph'
import ScienceQuoteSection from './Components/ScienceQuoteSection'
import ScienceSection from './Components/ScienceSection'
import StartTripSection from './Components/StartTripSection'
import { useEffect } from 'react'

const Science = () => {
  useEffect(() => {
    document.title = 'Metawise | Science'
  }, [])
  // const screenSize = useResize()
  return (
    <div>
      <ScienceHeroSection />
      <div className="relative overflow-x-hidden md:overflow-x-auto">
        <div className="container mx-auto px-5 md:px-0">
          <ScienceSection image={ImageAssets.dojo} subtitle="The Science of Meditation">
            <ScienceParagraph>
              Meditation, a practice deeply ingrained in human history, has been a key element of
              spiritual and personal development across diverse cultures worldwide. Mystics from
              every continent and cultural background have ventured into the depths of the mind,
              underlining the transformative power of meditation.
            </ScienceParagraph>
            <ScienceParagraph>
              Today, we have access to advanced technologies that allow us to measure the effects of
              meditation on the brain and body, deepening our understanding of this ancient
              practice.
            </ScienceParagraph>
          </ScienceSection>
          <ScienceSection image={ImageAssets.eeg} subtitle="EEG Technology">
            <ScienceParagraph>
              The development of electroencephalography (EEG) technology has allowed researchers to
              investigate the effects of meditation on brain activity. It has been demonstrated that
              the electrophysiological signals emitted by beginner meditators exhibit a
              characteristic EEG signature, characterized by an increase in the power and
              synchronization of theta and alpha bands, and a decrease in gamma activity.
            </ScienceParagraph>
            <ScienceParagraph>
              Studies using EEGs have shown that during meditative states, beginner meditators tend
              to have an increase in the power and synchronization of theta and alpha brainwave
              activity, while experienced meditators may show a unique pattern of brain activity,
              marked by an increase in gamma activity alongside increased theta and alpha
              frequencies and synchronization.
            </ScienceParagraph>
          </ScienceSection>
          <ScienceSection image={ImageAssets.mri} subtitle="MRI Technology">
            <ScienceParagraph>
              Other techniques, such as magnetic resonance imaging (MRI), which measures blood flow
              activity in the brain and its various regions, have also been used to study the brain
              in the context of meditation and mystical experiences.
            </ScienceParagraph>
            <ScienceParagraph>
              The Default Mode Network (DMN), one of a number of resting state functional networks
              active across multiple regions of the brain, has received particular attention in this
              regard. During meditative or mystical states, activity in the DMN is reduced and
              energy is redistributed throughout the brain, breaking the chain of automatic thinking
              and allowing for the higher function of conscious awareness.
            </ScienceParagraph>
          </ScienceSection>
        </div>
        <ScienceQuoteSection />
        <div className="container mx-auto px-5 md:px-0">
          <ScienceSection
            direction="left"
            image={ImageAssets.fusion}
            subtitle="The Fusion of Advanced Technology">
            <ScienceParagraph>
              Traditionally, neurofeedback training has only been accessible to those with the
              financial means to afford the necessary equipment and coaching. With the emergence of
              blockchain technology and the concept of non-fungible tokens (NFTs), there is now an
              opportunity to democratize access to neurofeedback training and create a virtual world
              in which users can engage in research, social activities, and personal development,
              all while being financially compensated for their contributions.
            </ScienceParagraph>
            <ScienceParagraph>
              By integrating neurofeedback training with blockchain technology and NFTs, we have the
              opportunity to create a virtual world in which users can have fun and be financially
              rewarded while also optimizing their minds and bodies through research-based methods.
              From the perspective of researchers, this integration also allows us to conduct
              large-scale scientific studies that can validate, invalidate, and advance existing
              research, leading to the creation of products and programs with the potential for
              engagement that would not be possible without the integration of neurofeedback
              training with the blockchain-based metaverse.
            </ScienceParagraph>
          </ScienceSection>
          <ScienceSection direction="left" image={ImageAssets.mission} subtitle="Our Mission">
            <ScienceParagraph>
              At Metawise, we are committed to advancing the science of meditation and neurofeedback
              training through the integration of cutting-edge technologies and centuries of
              spiritual wisdom.
            </ScienceParagraph>
            <ScienceParagraph>
              Our platform aims to democratize access to these transformative practices, allowing
              anyone to optimize their minds and bodies, and contribute to the advancement of our
              understanding of these techniques.
            </ScienceParagraph>
          </ScienceSection>
          <ScienceSection
            direction="left"
            image={ImageAssets.future}
            subtitle="The Future of Meditation">
            <ScienceParagraph>
              As we continue to develop our platform, we are constantly exploring new ways to deepen
              our understanding of the benefits of meditation and the ways in which it affects the
              brain and body. By leveraging the latest technologies and centuries of spiritual
              wisdom, we offer a truly enchanting and transformative meditation experience. We
              believe that by combining the insights of traditional spiritual practices with the
              rigor of modern scientific techniques, we can gain a deeper understanding of the true
              nature of meditation and its potential to enhance human wellbeing.
            </ScienceParagraph>
          </ScienceSection>
        </div>
        <StartTripSection />
        <Footer />
      </div>
    </div>
  )
}

export default Science
