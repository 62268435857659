import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import ActionButton from '../components/ActionButton'
import TeachingSection from '../components/TeachingSection'
import Divider from 'components/Divider/Divider'
import { TeachingPracticeProps } from './TeachingPractice3'
import WebInput from '../components/WebInput'
import WebTextArea from '../components/WebTextArea'
import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'
import { useState } from 'react'

const TeachingPractice2 = ({ back, next }: TeachingPracticeProps) => {
  const { aimForStudents, certification, enlightenmentExperiences, socialMedia } = useGetStarted()
  const updateProperty = useUpdateGetStarted()
  const [errorMessage, setErrorMessage] = useState('')
  const checkInformation = () => {
    setErrorMessage('')
    if (!aimForStudents || !certification || !enlightenmentExperiences || !socialMedia) {
      setErrorMessage('please enter all information')
      return false
    }
    return true
  }

  const toNext = () => {
    if (!checkInformation()) {
      return
    }
    next()
  }
  return (
    <div className="flex mb-10 md:mb-0 items-center flex-col justify-center gap-3 w-[400px]">
      <h3
        className="text-center font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Tell Us More About Your Teaching Practice
      </h3>
      <SizedBox height={10} />
      <div
        className="w-[90%] md:w-full mx-auto gap-2 flex flex-col items-start"
        style={{
          backgroundColor: '#2e2633',
          borderRadius: '10px',
          padding: '20px'
        }}>
        <TeachingSection number={2} />
        <h4
          className="font-semibold"
          style={{
            color: COLORS.white,
            fontSize: '16px'
          }}>
          Your Teaching Practice
        </h4>
        <p
          className="opacity-60"
          style={{
            fontSize: '14px',
            color: COLORS.white
          }}>
          Tell us more about your meditation teaching practice
        </p>
        <Divider top={10} bottom={10} />
        <div className="w-full">
          <WebInput
            setValue={(value: string) => updateProperty('aimForStudents', value)}
            value={aimForStudents}
            label="What are the key outcomes you aim for in your students' meditation journey?"
            placeholder="Write about keys "
          />
          <WebTextArea
            setValue={(value: string) => updateProperty('certification', value)}
            value={certification}
            label="Do you have any certification or training in teaching meditation/mindfulness practices? If yes, please provide details."
            placeholder="Write about your certification"
          />
          <WebTextArea
            setValue={(value: string) => updateProperty('enlightenmentExperiences', value)}
            value={enlightenmentExperiences}
            label="Let us know about any enlightenment experiences you've had and how they've shaped your teachings"
            placeholder="Write about enlightenment experiences"
          />
          <WebTextArea
            setValue={(value: string) => updateProperty('socialMedia', value)}
            value={socialMedia}
            label="Please share any social media profiles or websites where we can learn more about your teaching practice "
            placeholder="Share links to your social media profiles"
          />
          {errorMessage !== '' ? (
            <p className="text-sm text-center text-red-400 mt-2 mb-2">{errorMessage}</p>
          ) : (
            <></>
          )}
        </div>
        <SizedBox height={20} />
        <div className="w-full flex items-center justify-stretch gap-3">
          <ActionButton type="outline" onClick={back}>
            Back
          </ActionButton>
          <ActionButton onClick={toNext}>Continue</ActionButton>
        </div>
      </div>
    </div>
  )
}

export default TeachingPractice2
