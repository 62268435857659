import useResize from 'utils/useResize'

export interface ModalInputProps {
  placeholder: string
  valid?: boolean
  rightClick?: any
}
const ModalInput = ({ placeholder, valid, rightClick }: ModalInputProps) => {
  const screenSize = useResize()
  return (
    <div
      className="flex items-center"
      style={{
        border: `1px solid ${valid ? '#3CBC81' : '#C4C1C6'}`,
        borderRadius: '100px',
        padding: '12px',
        width: screenSize.width > 765 ? '388px' : '85vw'
      }}>
      <input className="outline-none flex-1" placeholder={placeholder} type="text" />
      {rightClick && (
        <p
          style={{
            fontSize: '14px',
            cursor: 'pointer'
          }}
          className="font-semibold">
          Send Code
        </p>
      )}
    </div>
  )
}

export default ModalInput
