import { ReactNode } from 'react'
import useResize from 'utils/useResize'
import { motion } from 'framer-motion'
import { slideInNoDelay } from 'utils/motion'

interface ScienceProps {
  subtitle: string
  image: string
  children: ReactNode
  direction?: 'left' | 'right'
}
const ScienceSection = ({ subtitle, children, image, direction = 'right' }: ScienceProps) => {
  const screenSize = useResize()
  return (
    <motion.div
      variants={slideInNoDelay('right')}
      viewport={{ once: true }}
      initial="hidden"
      whileInView="show"
      style={{
        marginTop: '50px',
        marginBottom: '80px'
      }}
      className={`flex ${
        screenSize.width > 765
          ? direction === 'right'
            ? 'flex-row'
            : 'flex-row-reverse'
          : 'flex-col-reverse'
      } justify-between items-center`}>
      <motion.div
        style={{
          width: screenSize.width > 765 ? '24%' : undefined
        }}>
        <h2
          style={{
            lineHeight: '110%',
            marginBottom: '25px',
            color: '#FCFCFC',
            fontSize: screenSize.width > 765 ? '48px' : '18px'
          }}>
          {subtitle}
        </h2>
        <div>{children}</div>
      </motion.div>
      <motion.img
        style={{
          marginBottom: screenSize.width > 765 ? undefined : '20px',
          width: screenSize.width > 765 ? '65%' : undefined
        }}
        src={image}
        alt=""
      />
    </motion.div>
  )
}

export default ScienceSection
