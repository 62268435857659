import { ReactNode } from 'react'

export interface TextProps {
  children: ReactNode
}
const Subtitle = ({ children }: TextProps) => {
  return (
    <h3
      style={{
        marginTop: '30px',
        color: '#FCFCFC',
        fontSize: '32px',
        marginBottom: '30px'
      }}>
      {children}
    </h3>
  )
}

export default Subtitle
