import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

export interface MenuProps {
  to: string
  children: ReactNode
  active: boolean
}
const Menu = ({ to, children, active }: MenuProps) => {
  return (
    <Link
      style={{
        marginLeft: '40px'
      }}
      to={to}>
      <p
        style={{
          fontSize: '14px',
          borderBottom: active ? '2px solid #9D59EF' : undefined,
          color: '#FCFCFC',
          opacity: active ? 1 : 0.8
        }}>
        {children}
      </p>
    </Link>
  )
}

export default Menu
