import COLORS from 'constants/Colors'
import { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'

interface AboutItemProps {
  to: string
  children: ReactNode
}

const HoverItem = ({ to, children }: AboutItemProps) => {
  const [hover, setHover] = useState(false)
  return (
    <Link onMouseLeave={() => setHover(false)} onMouseEnter={() => setHover(true)} to={to}>
      <div
        style={{
          borderRadius: '12px',
          padding: '7px',
          backgroundColor: hover ? '#413847' : COLORS.white
        }}
        className="flex items-start gap-3">
        <h3
          style={{
            color: hover ? COLORS.white : undefined
          }}>
          {children}
        </h3>
      </div>
    </Link>
  )
}

export default HoverItem
