import ImageAssets from 'assets/ImageAssets'

const services = [
  {
    image: ImageAssets.serviceDojo,
    title: 'Metawise Dojo',
    description:
      'Embark on a tailored journey of mind training and guided meditations designed uniquely for you. Freemium and premium options open up a rich library of guided meditations, expertly constructed for personal development and mental wellbeing. Unveil your subconscious with lucid dreaming tools, including a dream journal and advanced dream analysis. Our meditation timer, group meditation feature "Harmonize", and progress tracker enrich your exploration. Awaken your mind and track your transformative journey.'
  },
  {
    image: ImageAssets.serviceLabs,
    title: 'Metawise Labs',
    description:
      'Spearheading a revolutionary convergence of EEG analysis, AI, and blockchain for meditation enhancement. This initiative empowers the creation of a unique brainwave dataset, unlocking the mysteries of consciousness. Engage in our studies and community research, explore human consciousness, and optimize your meditation practices through this harmonious concoction of science and spirituality.'
  },
  {
    image: ImageAssets.serviceUniversity1,
    title: 'Metawise University',
    description:
      'Connect with renowned teachers and fellow students on our interactive platform. Access workshops & courses. Deepen your understanding and enhance your meditation practice through exclusive workshops, courses, and resources'
  },
  {
    image: ImageAssets.serviceCommunity,
    title: 'Metawise Community',
    description:
      'Join our conscious social media platform to connect with like-minded individuals and share your journey. Engage in meaningful conversations, support one another, and contribute to a global network focused on mental wellbeing'
  },
  {
    image: ImageAssets.serviceWeb3,
    title: 'Metawise GameFi',
    description:
      'Experience our Level Up system, challenges, and tokenized rewards. Earn $BE and $CE tokens, unlock exclusive content and workshops, and participate in decision-making with Soulbound NFT voting'
  },
  {
    image: ImageAssets.serviceAssistant,
    title: 'Metawise Assistant',
    description:
      'Meet your personalized AI spiritual assistant and NFT avatar. Trained on spiritual and scientific texts, SSAIJ provides guidance based on various traditions and sciences, helping you achieve your goals. Your NFT avatar represents your progress and achievements'
  }
]

export default services
