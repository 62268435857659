interface TeachingSectionProps {
  number: number
}
const TeachingSection = ({ number }: TeachingSectionProps) => {
  return (
    <div
      className="flex items-center justify-center rounded-lg"
      style={{ backgroundColor: '#B1B9EE', padding: '7px' }}>
      <p
        className="text-center font-semibold text-xs"
        style={{
          color: 'black'
        }}>
        Section {number}
      </p>
    </div>
  )
}

export default TeachingSection
