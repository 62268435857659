const GetStartedButton = () => {
  return (
    <a
      href="https://dojo.metawise.gg"
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer md:h-auto h-[45px] flex items-center justify-center py-[5px] px-[15px] rounded-[100px]"
      style={{
        border: '1px solid #FCFCFC',
        textDecoration: 'none' // to remove underline from link
      }}>
      <p className="font-[14px] text-[#FCFCFC]">Enter Dojo</p>
    </a>
  )
}

export default GetStartedButton
