import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'

interface HamburgerProps {
  onClick: any
  size?: 'large' | 'small'
}

const HamburgerButton = ({ onClick, size = 'large' }: HamburgerProps) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <div
        style={{
          width: size === 'large' ? '56px' : '32px',
          height: '2px',
          backgroundColor: COLORS.white
        }}
      />
      <SizedBox height={10} />
      <div
        style={{
          width: size === 'large' ? '56px' : '32px',
          height: '2px',
          backgroundColor: COLORS.white
        }}
      />
    </div>
  )
}

export default HamburgerButton
