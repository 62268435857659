import COLORS from 'constants/Colors'
import { ReactNode } from 'react'

interface ActionButtonProps {
  children: ReactNode
  onClick: any
  type?: 'outline' | 'fill'
}

const ActionButton = ({ children, onClick, type = 'fill' }: ActionButtonProps) => {
  return (
    <div
      onClick={onClick}
      className="rounded-2xl flex-1 cursor-pointer flex items-center justify-center"
      style={{
        border: type === 'fill' ? undefined : `1px solid ${COLORS.white}`,
        backgroundColor: type === 'fill' ? '#9d59ef' : 'transparent',
        padding: '7px 10px'
      }}>
      <p
        className="text-sm uppercase text-center"
        style={{
          color: COLORS.white
        }}>
        {children}
      </p>
    </div>
  )
}

export default ActionButton
