import { ReactNode } from 'react'
import useResize from 'utils/useResize'
import { motion } from 'framer-motion'

import { slideInNoDelay, staggerContainer } from 'utils/motion'
interface DetailProps {
  image: string
  subtitle: string
  children: ReactNode
  direction?: 'left' | 'right'
}
const DetailSection = ({ image, subtitle, children, direction = 'right' }: DetailProps) => {
  const screenSize = useResize()
  return (
    <motion.div
      variants={staggerContainer()}
      viewport={{ once: true, amount: 0.25 }}
      initial="hidden"
      whileInView="show"
      style={{
        marginBottom: '62px'
      }}
      className={`container mx-auto px-5 md:px-0 justify-between flex ${
        screenSize.width > 765
          ? direction === 'right'
            ? 'flex-row'
            : 'flex-row-reverse'
          : 'flex-col'
      } items-center`}>
      <motion.img
        variants={slideInNoDelay('right')}
        style={{
          width: screenSize.width > 765 ? '45%' : undefined
        }}
        src={image}
        alt=""
      />
      <motion.div
        variants={slideInNoDelay('left')}
        style={{
          width: screenSize.width > 765 ? '40%' : undefined
        }}>
        <h2
          style={{
            color: '#FCFCFC',
            margin: screenSize.width > 765 ? undefined : '30px 0px',
            fontSize: screenSize.width > 765 ? '48px' : '18px'
          }}>
          {subtitle}
        </h2>
        {children}
      </motion.div>
    </motion.div>
  )
}

export default DetailSection
