import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import Header from 'components/Header/Header'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import useResize from 'utils/useResize'
import { motion } from 'framer-motion'
import { slideInNoDelay } from 'utils/motion'
import Stars from 'components/Background/Stars/Stars'

const RoadMapHeroSection = () => {
  const screenSize = useResize()
  return (
    <div className="relative">
      <Stars />
      <Header />
      <div className="relative z-[5]">
        <SizedBox height={screenSize.width > 765 ? 140 : 70} />
        <StaggerWrapper>
          <div className="container mx-auto px-5 md:px-0 flex flex-col md:flex-row md:items-start items-center justify-between">
            <div
              className="flex flex-col items-center md:items-start"
              style={{
                width: screenSize.width > 620 ? '510px' : undefined
              }}>
              <SizedBox height={screenSize.width > 765 ? 60 : 0} />
              <motion.h1
                variants={slideInNoDelay('left')}
                style={{
                  lineHeight: screenSize.width > 765 ? '78px' : '50px',
                  fontSize: screenSize.width > 765 ? '78px' : '50px'
                }}
                className="text-center md:text-left text-white">
                Take a step with us...
              </motion.h1>
              <SizedBox height={screenSize.width > 765 ? 40 : 24} />
              <motion.p
                variants={slideInNoDelay('left')}
                style={{
                  color: '#FCFCFC',
                  fontSize: screenSize.width > 765 ? '20px' : '14px',
                  opacity: 0.8,
                  lineHeight: '140%'
                }}
                className="text-center md:text-left w-9/12">
                Illuminating the mysteries of consciousness one brainwave at a time
              </motion.p>
            </div>
            <motion.div
              variants={slideInNoDelay('right')}
              style={{
                marginTop: screenSize.width > 765 ? -100 : '50px'
              }}
              className="px-5 md:px-0 relative">
              <img
                src={ImageAssets.roadmap1}
                alt=""
                style={{
                  marginRight: '-50px',
                  transform: 'scale(0.65)' // Add this line to scale the roadmap1 image down by 40%
                }}
              />
              <motion.img
                animate={{
                  top: [
                    screenSize.width > 765 ? '30%' : '25%',
                    screenSize.width > 765 ? '27%' : '22%',
                    screenSize.width > 765 ? '30%' : '25%'
                  ]
                }}
                transition={{ repeat: Infinity, repeatDelay: 0.25, duration: 2.5 }}
                style={{
                  left: screenSize.width > 765 ? '2%' : '0%',
                  top: screenSize.width > 765 ? '30%' : '25%',
                  width: screenSize.width > 765 ? undefined : '120px'
                }}
                className="absolute"
                src={IconAssets.entering}
                alt=""
              />
              <motion.img
                animate={{
                  top: [
                    screenSize.width > 765 ? '12%' : '5%',
                    screenSize.width > 765 ? '15%' : '8%',
                    screenSize.width > 765 ? '12%' : '5%'
                  ]
                }}
                transition={{ repeat: Infinity, repeatDelay: 0.75, duration: 1.5 }}
                style={{
                  right: screenSize.width > 765 ? '0%' : '0%',
                  top: screenSize.width > 765 ? '12%' : '5%',
                  width: screenSize.width > 765 ? undefined : '100px'
                }}
                className="absolute"
                src={IconAssets.CE}
                alt=""
              />
              <motion.img
                animate={{
                  bottom: [
                    screenSize.width > 765 ? '15%' : '5%',
                    screenSize.width > 765 ? '18%' : '8%',
                    screenSize.width > 765 ? '15%' : '5%'
                  ]
                }}
                transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
                style={{
                  right: screenSize.width > 765 ? '0%' : '0%',
                  bottom: screenSize.width > 765 ? '15%' : '5%',
                  width: screenSize.width > 765 ? undefined : '140px'
                }}
                className="absolute"
                src={IconAssets.collectiveMeditation}
                alt=""
              />
            </motion.div>
          </div>
        </StaggerWrapper>
      </div>
    </div>
  )
}

export default RoadMapHeroSection
