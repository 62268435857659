import { useState, useEffect } from 'react'
import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import Header from 'components/Header/Header'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import useResize from 'utils/useResize'
import SlideButton from './SlideButton'
import { motion } from 'framer-motion'
import { slideInNoDelay, slideUpNoDelay } from 'utils/motion'
import Stars from 'components/Background/Stars/Stars'
import { Link } from 'react-router-dom'

interface HeroProps {
  apply: any
}
const HeroSection = ({ apply }: HeroProps) => {
  const screenSize = useResize()

  const [mindTrainingIndex, setMindTrainingIndex] = useState(0)
  const mindTrainingWords = [
    'Mind Training',
    'Meditation',
    'Neurofeedback',
    'Mindfulness',
    'Cognition',
    'Awareness',
    'Self-Growth',
    'Inner Peace',
    'Concentration',
    'Wellness',
    'Contemplation',
    'Compassion',
    'Introspection',
    'Dream Yoga',
    'Enlightenment',
    'Transcendence',
    'Non-Duality',
    'Insight',
    'Spirituality',
    'Wisdom'
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setMindTrainingIndex((prevIndex) => (prevIndex + 1) % mindTrainingWords.length)
    }, 2000)

    return () => clearInterval(interval) // Clear interval on component unmount
  }, [])

  return (
    <div className="relative">
      <div
        className="absolute top-0 left-0 right-0 bottom-0"
        style={{
          zIndex: 3,
          backgroundImage:
            screenSize.width > 765 ? `url(${ImageAssets.heroBG1})` : `url(${ImageAssets.heroBG1})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'top',
          backgroundPositionY: screenSize.width > 765 ? '2vh' : '60%'
        }}
      />
      <Stars />
      <Header />
      <div className="relative z-[5]">
        <SizedBox height={screenSize.width > 765 ? 120 : 80} />
        <div className="mx-auto px-5 md:px-0 container flex flex-col md:flex-row items-center justify-between">
          <StaggerWrapper>
            <div
              className="flex flex-col items-center md:items-start"
              style={{
                width: screenSize.width > 765 ? '510px' : undefined
              }}>
              <motion.h1
                style={{
                  fontSize: screenSize.width > 765 ? '78px' : '50px',
                  lineHeight: screenSize.width > 765 ? '78px' : '50px'
                }}
                className="text-center md:text-left text-white">
                AI-Powered
              </motion.h1>
              <motion.h1
                style={{
                  fontSize: screenSize.width > 765 ? '78px' : '50px',
                  lineHeight: screenSize.width > 765 ? '78px' : '50px'
                }}
                className="text-center md:text-left text-white">
                {mindTrainingWords[mindTrainingIndex]}
              </motion.h1>
              <motion.h1
                style={{
                  fontSize: screenSize.width > 765 ? '78px' : '50px',
                  lineHeight: screenSize.width > 765 ? '78px' : '50px'
                }}
                className="text-center md:text-left text-white">
                Platform
              </motion.h1>
              <SizedBox height={screenSize.width > 765 ? 40 : 24} />
              <motion.p
                variants={slideUpNoDelay()}
                style={{
                  color: '#FCFCFC',
                  fontSize: screenSize.width > 765 ? '20px' : '14px',
                  opacity: 0.8,
                  lineHeight: '140%'
                }}
                className="text-center md:text-left w-9/12">
                Optimize Your Self
                <br />
                Optimize the World
              </motion.p>
              <SizedBox height={screenSize.width > 765 ? 40 : 24} />
              <SlideButton
                variant={slideUpNoDelay()}
                href="https://dojo.metawise.gg"
                image={<img style={{ width: '40px' }} src={IconAssets.discord} />}>
                ENTER THE DOJO
              </SlideButton>
            </div>
          </StaggerWrapper>
          <SizedBox height={screenSize.width > 765 ? 0 : 354} />
          <StaggerWrapper>
            <div
              className="flex flex-row md:flex-col items-start"
              style={{
                width: screenSize.width > 765 ? '255px' : undefined
              }}>
              <div>
                <motion.p
                  variants={slideInNoDelay('right')}
                  style={{
                    color: '#FCFCFC',
                    opacity: '0.8',
                    fontSize: screenSize.width > 765 ? '16px' : '12px',
                    lineHeight: '140%'
                  }}>
                  We bring everyone together to explore the boundaries of our consciousness.
                </motion.p>
                <SizedBox height={24} />
                <Link to="/onboarding">
                  <motion.p
                    variants={slideInNoDelay('right')}
                    style={{
                      fontSize: screenSize.width > 765 ? '16px' : '14px',
                      color: '#FCFCFC'
                    }}
                    className="underline">
                    Explore onboarding
                  </motion.p>
                </Link>
              </div>
              <SizedBox width={screenSize.width > 765 ? 0 : 100} height={26} />
              <div className="hidden md:block">
                <SizedBox height={200} />
              </div>
            </div>
          </StaggerWrapper>
        </div>
        <div className="container px-5 md:px-0 mx-auto">
          <SizedBox height={144} />
          <Divider />
          <SizedBox height={20} />
        </div>
      </div>
    </div>
  )
}

export default HeroSection
