import COLORS from 'constants/Colors'
import ActionButton from '../components/ActionButton'
import { StudentPracticeProps } from './StudentPractice2'
import SizedBox from 'components/Divider/SizedBox'
import Divider from 'components/Divider/Divider'
import WebInput from '../components/WebInput'
import TeachingSection from '../components/TeachingSection'
import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'
import { useState } from 'react'

const StudentPractice4 = ({ back, next }: StudentPracticeProps) => {
  const { meditatePerWeek, exercisePerWeek, sleepPerDay, mentalAppsUsed } = useGetStarted()
  const updateProperty = useUpdateGetStarted()
  const [errorMessage, setErrorMessage] = useState('')
  const checkInformation = () => {
    setErrorMessage('')
    if (!meditatePerWeek || !exercisePerWeek || !sleepPerDay || !mentalAppsUsed) {
      setErrorMessage('please enter all information')
      return false
    }
    return true
  }

  const toNext = () => {
    if (!checkInformation()) {
      return
    }
    next()
  }
  return (
    <div className="flex mb-10 md:mb-0 items-center flex-col justify-center gap-3 w-[400px]">
      <h3
        className="text-center font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Tell Us More About You
      </h3>
      <p
        className="text-sm text-center opacity-60"
        style={{
          color: COLORS.white
        }}>
        To tailor your Metawise experience and help us understand your needs better, please fill out
        this questionnaire
      </p>
      <SizedBox height={10} />
      <div
        className="w-[90%] md:w-full mx-auto gap-2 flex flex-col items-start"
        style={{
          backgroundColor: '#2e2633',
          borderRadius: '10px',
          padding: '20px'
        }}>
        <TeachingSection number={4} />
        <h4
          className="font-semibold"
          style={{
            color: COLORS.white,
            fontSize: '16px'
          }}>
          Health & Lifestyle Questions
        </h4>
        <p
          className="opacity-60"
          style={{
            fontSize: '14px',
            color: COLORS.white
          }}>
          Let us know more about your lifestyle
        </p>
        <Divider top={10} bottom={10} />
        <div className="w-full">
          <WebInput
            setValue={(value: string) => updateProperty('sleepPerDay', value)}
            value={sleepPerDay}
            label="How many hours do you sleep on average per day?"
            placeholder="Write hours"
          />
          <WebInput
            setValue={(value: string) => updateProperty('exercisePerWeek', value)}
            value={exercisePerWeek}
            label="How often do you exercise in a week?"
            placeholder="Please specify"
          />
          <WebInput
            setValue={(value: string) => updateProperty('meditatePerWeek', value)}
            value={meditatePerWeek}
            label="How often do you meditate in a week?"
            placeholder="Please specify"
          />
          <WebInput
            value={mentalAppsUsed}
            setValue={(value: string) => updateProperty('mentalAppsUsed', value)}
            label="Have you used any mental wellbeing apps or devices before? If yes, which ones?"
            placeholder="Share with us"
          />
          {errorMessage !== '' ? (
            <p className="text-sm text-center text-red-400 mt-2 mb-2">{errorMessage}</p>
          ) : (
            <></>
          )}
        </div>
        <SizedBox height={40} />
        <div className="w-full flex items-center justify-stretch gap-3">
          <ActionButton type="outline" onClick={back}>
            Back
          </ActionButton>
          <ActionButton onClick={toNext}>Continue</ActionButton>
        </div>
      </div>
    </div>
  )
}

export default StudentPractice4
