import moment from 'moment'

const DashboardTableContext = ({ data = [] }: any) => {
  return (
    <>
      {data.map((item: any) => {
        return (
          <div
            key={item._id}
            className="p-2 border-b border-[#FCFCFC33] flex items-center justify-between">
            <p className="w-3/12">{item.name}</p>
            <p className="w-3/12">{item.email}</p>
            <p className="w-3/12">{item.type}</p>
            <p className="w-3/12">{moment(item.createdAt).format('Do MMM YYYY')}</p>
          </div>
        )
      })}
    </>
  )
}

export default DashboardTableContext
