import IconAssets from 'assets/IconAssets'
import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'

interface StudenListItemProps {
  title: string
  subtitle: string
}

const StudentListItem = ({ title, subtitle }: StudenListItemProps) => {
  return (
    <div className="flex items-start gap-2">
      <img className="rounded-full" src={IconAssets.tip} alt="" />
      <div>
        <h3
          style={{
            color: COLORS.white
          }}>
          {title}
        </h3>
        <SizedBox height={5} />
        <p
          className="text-sm opacity-60"
          style={{
            color: COLORS.white
          }}>
          {subtitle}
        </p>
      </div>
    </div>
  )
}

export default StudentListItem
