import { ReactNode } from 'react'
import useResize from 'utils/useResize'
import { motion } from 'framer-motion'
import { slideInNoDelay } from 'utils/motion'

interface CategoryProps {
  children: ReactNode
  active: boolean
  onClick: any
}
const CategoryItem = ({ children, active, onClick }: CategoryProps) => {
  const screenSize = useResize()
  return (
    <motion.div variants={slideInNoDelay('right')} onClick={onClick}>
      <p
        className="cursor-pointer uppercase"
        style={{
          border: '1px solid #FCFCFC',
          borderRadius: '100px',
          margin: '10px 10px',
          padding: screenSize.width > 765 ? '11px 24px' : '5px 12px',
          fontSize: screenSize.width > 765 ? '14px' : '12px',
          backgroundColor: active ? '#FCFCFC' : 'transparent',
          color: active ? 'black' : '#FCFCFC'
        }}>
        {children}
      </p>
    </motion.div>
  )
}

export default CategoryItem
