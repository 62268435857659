import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import useResize from 'utils/useResize'

interface NewGenCardProps {
  image?: string
  icon?: string
  offset?: number
  title: string
  details: string
}
const NewGenCard = ({
  image = ImageAssets.medit,
  icon = IconAssets.download,
  offset = 0,
  title,
  details
}: NewGenCardProps) => {
  const screenSize = useResize()
  return (
    <div
      style={{
        minWidth: screenSize.width > 765 ? '300px' : '200px',
        margin: `0px 120px`
      }}
      className="relative">
      <SizedBox height={offset} />
      <div
        className="absolute"
        style={{
          borderRadius: '12px',
          border: '1px solid rgba(136, 98, 247, 0.65)',
          background: 'rgba(49, 38, 55, 0.45)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          padding: '20px',
          left: '-100px',
          bottom: screenSize.width > 765 ? 180 - offset : '10px',
          width: '256px'
        }}>
        <img src={icon} alt="" />
        <SizedBox height={10} />
        <p
          className="capitalize font-semibold"
          style={{
            color: '#FCFCFC',
            fontSize: screenSize.width > 765 ? '20px' : '16px',
            width: screenSize.width > 765 ? '231px' : '150px'
          }}>
          {title}
        </p>
        <p
          className="opacity-60 text-sm mt-2"
          style={{
            color: COLORS.white
          }}>
          {details}
        </p>
      </div>
      <img
        style={{
          height: '400px'
        }}
        src={image}
        alt=""
      />
    </div>
  )
}

export default NewGenCard
