/* eslint-disable @typescript-eslint/no-unused-vars */
import SizedBox from 'components/Divider/SizedBox'
import { ReactElement, ReactNode } from 'react'
import { motion } from 'framer-motion'
import { slideUp } from 'utils/motion'

interface SlideButtonProps {
  image: ReactElement
  children: ReactNode
  direction?: 'left' | 'right'
  variant?: any
  href: string // Make href mandatory since it's always acting as a link
}

const SlideButton = ({
  image,
  children,
  direction = 'right',
  variant = slideUp(0.25, 0.25),
  href
}: SlideButtonProps) => {
  const buttonContent = (
    <div
      style={{
        maxWidth: '300px',
        border: '1px solid #FCFCFC4D',
        borderRadius: '100px',
        padding: '8px'
      }}
      className="cursor-pointer flex flex-row items-center">
      {direction === 'right' ? (
        <>
          {image}
          <SizedBox width={'24px'} />
          <h4 style={{ fontSize: '14px' }} className="text-white font-bold uppercase">
            {children}
          </h4>
          <SizedBox width={24} />
        </>
      ) : (
        <>
          <SizedBox width={24} />
          <h4 style={{ fontSize: '14px' }} className="text-white font-bold uppercase">
            {children}
          </h4>
          <SizedBox width={'24px'} />
          {image}
        </>
      )}
    </div>
  )

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <motion.div variants={variant}>{buttonContent}</motion.div>
    </a>
  )
}

export default SlideButton
