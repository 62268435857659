import { useState } from 'react'
import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import AcceptTerms from '../components/AcceptTerms'
import ActionButton from '../components/ActionButton'
import WebInput from '../components/WebInput'
import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'

interface PersonalInformationProps {
  back: any
  next: any
}

const PersonalInformation = ({ back, next: onClick }: PersonalInformationProps) => {
  const [checked, setChecked] = useState(false)
  const { type, name, password, confirmPassword } = useGetStarted()
  const updateProperty = useUpdateGetStarted()
  const [errorMessage, setErrorMessage] = useState('')
  const checkInformation = () => {
    setErrorMessage('')
    if (!name || !password || !confirmPassword) {
      setErrorMessage('please enter all information')
      return false
    }
    if (password !== confirmPassword) {
      setErrorMessage('The passwords do not match')
      return false
    }
    if (!checked) {
      setErrorMessage('You need to accept the terms and conditions')
      return false
    }
    return true
  }

  const next = () => {
    if (!checkInformation()) {
      return
    }
    // check the type the user selected
    if (type === 'teacher') {
      onClick(5)
    } else {
      onClick(8)
    }
  }
  return (
    <div className="flex md:w-[400px] w-[90%] mb-10 md:mb-0 mx-auto items-center gap-3 flex-col">
      <h3
        className="font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Personal Information
      </h3>
      <p
        className="opacity-60"
        style={{
          fontSize: '14px',
          color: COLORS.white
        }}>
        {`Let's Get to Know You`}
      </p>
      <SizedBox height={10} />
      <div
        className="w-full"
        style={{
          backgroundColor: '#2e2633',
          borderRadius: '10px',
          padding: '20px'
        }}>
        <WebInput
          value={name}
          setValue={(value: string) => updateProperty('name', value)}
          label="Full Name"
          placeholder="etc. Jelani Keita"
        />
        <WebInput
          value={password}
          setValue={(value: string) => updateProperty('password', value)}
          isPassword
          label="Password"
          placeholder="Create your password"
        />
        <WebInput
          value={confirmPassword}
          setValue={(value: string) => updateProperty('confirmPassword', value)}
          isPassword
          label="Confirm Password"
          placeholder="Confirm entered password"
        />
        <AcceptTerms checked={checked} setChecked={() => setChecked(!checked)} />
        {errorMessage !== '' ? (
          <p className="text-sm text-center text-red-400 mt-2 mb-2">{errorMessage}</p>
        ) : (
          <></>
        )}
        <SizedBox height={100} />
        <div className="w-full flex items-center justify-stretch gap-3">
          <ActionButton type="outline" onClick={back}>
            Back
          </ActionButton>
          <ActionButton onClick={next}>Continue</ActionButton>
        </div>
      </div>
    </div>
  )
}

export default PersonalInformation
