import ImageAssets from 'assets/ImageAssets'
import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import Header from 'components/Header/Header'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import useResize from 'utils/useResize'
import { motion } from 'framer-motion'
import { slideInNoDelay, textVariantNoDelay } from 'utils/motion'
import Stars from 'components/Background/Stars/Stars'

const ScienceHeroSection = () => {
  const screenSize = useResize()
  return (
    <div className="relative overflow-hidden">
      <Stars />
      <Header />
      <div className="relative z-[5]">
        <SizedBox height={screenSize.width > 765 ? 70 : 70} />
        <div className="px-5 md:px-0 container mx-auto">
          <StaggerWrapper>
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div
                className="flex flex-col items-center md:items-start"
                style={{
                  width: screenSize.width > 620 ? '600px' : undefined
                }}>
                <motion.h1
                  variants={slideInNoDelay('left')}
                  style={{
                    lineHeight: screenSize.width > 765 ? '78px' : '50px',
                    fontSize: screenSize.width > 765 ? '78px' : '50px'
                  }}
                  className="text-center md:text-left text-white">
                  Start the journey into mysticism and science
                </motion.h1>
                <SizedBox height={screenSize.width > 765 ? 40 : 24} />
                <motion.p
                  variants={slideInNoDelay('left')}
                  style={{
                    color: '#FCFCFC',
                    fontSize: screenSize.width > 765 ? '20px' : '14px',
                    opacity: 0.8,
                    lineHeight: '140%'
                  }}
                  className="text-center md:text-left w-9/12">
                  Our meditation platform draws on centuries of spiritual wisdom and cutting-edge
                  technologies to create a personalized and transformative experience.
                </motion.p>
              </div>
              <div
                style={{
                  marginTop: screenSize.width > 765 ? 0 : '50px'
                }}
                className="px-5 md:px-0 relative md:mr-10">
                <motion.img
                  variants={slideInNoDelay('right')}
                  style={{
                    height: screenSize.width > 765 ? '500px' : undefined,
                    background:
                      'radial-gradient(50% 50% at 50% 50%, rgba(29, 21, 33, 0.01) 80.73%, #1D1521 100%)'
                  }}
                  src={ImageAssets.mysticscience}
                  alt=""
                />
              </div>
            </div>
          </StaggerWrapper>
          <SizedBox height={100} />
          <StaggerWrapper delay={1}>
            <motion.div
              variants={textVariantNoDelay()}
              className="mx-auto flex items-center justify-center">
              <p
                style={{
                  fontSize: screenSize.width > 765 ? '32px' : '18px',
                  color: '#FCFCFC',
                  width: screenSize.width > 765 ? '618px' : undefined
                }}
                className="text-center mb-10">
                Take a deep breath, quiet your mind, and embark on this captivating journey...
              </p>
            </motion.div>
          </StaggerWrapper>
          <SizedBox height={screenSize.width > 765 ? 100 : 50} />
          <Divider />
        </div>
      </div>
    </div>
  )
}

export default ScienceHeroSection
