import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import Floaty from 'components/Floaty/Floaty'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import { motion } from 'framer-motion'
import { slideUpNoDelay, textVariantNoDelay } from 'utils/motion'
import useResize from 'utils/useResize'
import DojoImages from './DojoImages'
import { useNavigate } from 'react-router'

const DojoSection = () => {
  const screenSize = useResize()
  const navigate = useNavigate()
  return (
    <StaggerWrapper delay={0.75}>
      <div
        style={{
          marginBottom: 100
        }}>
        <div className="flex flex-col items-center container mx-auto px-5 md:px-0">
          <motion.p
            variants={textVariantNoDelay()}
            className="text-center glowing-text"
            style={{
              color: '#FCFCFC',
              fontSize: screenSize.width > 765 ? '32px' : '18px',
              lineHeight: '140%',
              width: screenSize.width > 765 ? '620px' : undefined,
              alignItems: 'center'
            }}>
            Discover your True Self with personalized neurofeedback, earn rewards and collectively
            advance the science of meditation
          </motion.p>
          <SizedBox height={171} />
          {screenSize.width > 765 ? (
            <DojoImages />
          ) : (
            <motion.img variants={slideUpNoDelay()} src={ImageAssets.keyFunctions} alt="" />
          )}
          <div
            className="relative z-[5]"
            style={{
              marginTop: screenSize.width > 765 ? '-100px' : '-40px'
            }}>
            <a
              href="https://dojo.metawise.gg"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}>
              <Floaty size={screenSize.width > 765 ? 'large' : 'small'}>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#FCFCFC'
                  }}>
                  WHO AM I?
                </p>
              </Floaty>
            </a>
          </div>
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default DojoSection
