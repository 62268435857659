import IconAssets from 'assets/IconAssets'
import COLORS from 'constants/Colors'

interface StudentReasonProps {
  label: string
  active: boolean
  onClick: any
}
const StudentReason = ({ label, active, onClick }: StudentReasonProps) => {
  return (
    <div
      onClick={() => onClick(label)}
      className="p-5 cursor-pointer flex items-start justify-between gap-3"
      style={{
        border: active ? `1px solid ${COLORS.white}4A` : undefined,
        borderRadius: '12px',
        backgroundColor: 'rgba(65, 56, 71, 1)'
      }}>
      <p
        className="text-sm mb-10 opacity-60"
        style={{
          color: COLORS.white
        }}>
        {label}
      </p>
      <div>
        <div
          className="rounded-full flex items-center justify-center top-3 right-3"
          style={{
            height: '24px',
            width: '24px',
            border: active ? undefined : `1px solid ${COLORS.white}4A`
          }}>
          {active && <img src={IconAssets.chosen} alt="" />}
        </div>
      </div>
    </div>
  )
}

export default StudentReason
