import { useEffect, useState } from 'react'
import CodeModal from './CodeModal'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'

export interface ModalProps {
  visible: boolean
  close: any
  modalType: 'register' | 'login' | 'code'
}
const ModalHolder = ({ visible, close, modalType }: ModalProps) => {
  const [type, setModalType] = useState(modalType)
  const renderModal = () => {
    switch (type) {
      case 'register':
        return <RegisterModal nextAction={() => setModalType('login')} />
      case 'login':
        return <LoginModal nextAction={() => setModalType('code')} />
      case 'code':
        return <CodeModal />
    }
  }
  useEffect(() => {
    if (!visible) {
      setModalType('register')
    }
  }, [visible])
  return (
    <div
      className="fixed flex items-center justify-center top-0 left-0 bottom-0 right-0"
      style={{
        zIndex: 20,
        display: visible ? 'flex' : 'none'
      }}>
      {/* background */}
      <div
        style={{
          backgroundColor: 'rgba(29, 21, 33, 0.7)'
        }}
        className="absolute top-0 left-0 bottom-0 right-0"
        onClick={close}
      />
      {/* modal content */}
      {renderModal()}
    </div>
  )
}

export default ModalHolder
