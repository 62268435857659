import COLORS from 'constants/Colors'
import { useState } from 'react'
import { Link } from 'react-router-dom'

interface OnboardingItemProps {
  to: string
  title: string
  image: string
  description: string
}

const OnboardingItem = ({ to, title, image, description }: OnboardingItemProps) => {
  const [hover, setHover] = useState(false)
  return (
    <Link to={to}>
      <div
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
        style={{
          padding: '12px',
          backgroundColor: hover ? '#413847' : COLORS.white
        }}
        className="flex rounded-lg items-start gap-3">
        <img
          style={{
            marginTop: '5px'
          }}
          src={image}
          alt=""
        />
        <div>
          <h3
            className="font-semibold"
            style={{
              color: hover ? COLORS.white : undefined
            }}>
            {title}
          </h3>
          <p
            style={{
              color: hover ? COLORS.white : undefined
            }}>
            {description}
          </p>
        </div>
      </div>
    </Link>
  )
}

export default OnboardingItem
