import COLORS from 'constants/Colors'
import SlideIndicator from './SlideIndicator'
import 'react-slideshow-image/dist/styles.css'
import { useState } from 'react'
import { Slide } from 'react-slideshow-image'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'

interface AdvisorSlideShowProps {
  showModal: any
}

const AdvisorSlideShow = ({ showModal }: AdvisorSlideShowProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const slideImages = [
    {
      key: 'dat',
      name: 'Dat Do',
      title: 'Machine Learning Advisor',
      image: ImageAssets.dat
    },
    {
      key: 'james',
      name: 'James Anderson',
      title: 'Blockchain Advisor',
      image: ImageAssets.james
    },
    {
      key: 'david',
      name: 'David Verdesi',
      title: 'Spiritual Advisor',
      image: ImageAssets.david
    }
  ]
  return (
    <div className="block md:hidden container mx-auto px-5 md:px-0">
      <h3
        className="text-center"
        style={{
          fontSize: '32px',
          color: COLORS.white
        }}>
        Meet the team
      </h3>
      <SizedBox height={20} />
      <Slide
        transitionDuration={0.5}
        onChange={(_, to) => setCurrentIndex(to)}
        indicators={(number) => <SlideIndicator selected={number === currentIndex} />}
        arrows={false}
        autoplay>
        {slideImages.map((slideImage, index) => (
          <div key={index} className="flex items-center justify-center">
            <div className="relative">
              {slideImage.key !== 'none' && (
                <img
                  onClick={() => showModal(slideImage.key)}
                  className="cursor-pointer absolute"
                  src={ImageAssets.teamExpand}
                  style={{
                    right: '-16px',
                    top: '20px',
                    width: '32px'
                  }}
                  alt=""
                />
              )}

              <div
                className="rounded-lg flex items-end justify-end overflow-hidden"
                style={{
                  backgroundColor: '#75547C',
                  width: '300px',
                  height: '401px'
                }}>
                <img className="max-h-full" src={slideImage.image} alt="" />
              </div>
              <SizedBox height={20} />
              <div>
                <h4
                  className="font-semibold"
                  style={{
                    color: COLORS.white
                  }}>
                  {slideImage.name}
                </h4>
                <p
                  className="text-sm opacity-60"
                  style={{
                    color: COLORS.white
                  }}>
                  {slideImage.title}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  )
}

export default AdvisorSlideShow
