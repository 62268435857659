import useResize from 'utils/useResize'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'

const NewsletterSection = () => {
  const screenSize = useResize()
  return (
    <div
      style={{
        marginTop: screenSize.width > 765 ? -150 : -200
      }}>
      <div
        style={{
          backgroundImage: `url(${ImageAssets.journeyBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: screenSize.width > 765 ? '100%' : '190%',
          backgroundPosition: 'center',
          backgroundPositionX: screenSize.width > 765 ? '20px' : '-170px',
          backgroundPositionY: screenSize.width > 765 ? undefined : '230px'
        }}
        className="container px-5 md:px-0 mx-auto flex flex-col items-center">
        <SizedBox height={screenSize.width > 765 ? 120 : 140} />
        <h3
          className="text-center"
          style={{
            maxWidth: '600px',
            marginTop: '30px',
            color: '#FCFCFC',
            fontSize: '32px',
            marginBottom: '30px'
          }}>
          Get notified of new research on the science of meditation
        </h3>
        <p
          style={{
            fontSize: '18px',
            color: '#FCFCFC',
            opacity: 0.8,
            width: screenSize.width > 765 ? '518px' : undefined
          }}
          className="text-center mb-10">
          Subscription can give access to exclusive materials such as conferences, webinars and
          other events that can be useful for professional development.
        </p>
        <div className="flex flex-row items-center">
          <input
            placeholder="email address"
            style={{
              color: '#FCFCFC',
              opacity: 0.8,
              borderRadius: '100px',
              marginRight: '10px',
              padding: '8px',
              outline: 'none',
              border: '1px solid gray',
              minWidth: screenSize.width > 765 ? '350px' : '70%',
              backgroundColor: 'transparent'
            }}
            type="email"
          />
          <button
            style={{
              padding: '8px',
              backgroundColor: '#9D59EF',
              fontSize: '14px',
              minWidth: '100px',
              borderRadius: '100px'
            }}
            className="text-white font-semibold">
            SUBMIT
          </button>
        </div>
        <SizedBox height={200} />
      </div>
    </div>
  )
}

export default NewsletterSection
