import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import Floaty from 'components/Floaty/Floaty'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import useResize from 'utils/useResize'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'
import { useNavigate } from 'react-router'

const OnboardingPlatformSection = () => {
  const screenSize = useResize()
  const navigate = useNavigate()
  return (
    <StaggerWrapper delay={0.25}>
      <SizedBox height={40} />
      <div className="px-5 md:px-0 container flex flex-col items-center mx-auto">
        <div className="flex w-full flex-row items-center justify-between">
          <motion.img
            variants={slideUpNoDelay()}
            className="hidden md:block"
            style={{
              width: '255px'
            }}
            src={ImageAssets.logobrain}
            alt=""
          />
          <motion.p
            variants={slideUpNoDelay()}
            style={{
              color: '#FCFCFC',
              fontSize: '32px',
              lineHeight: '140%',
              width: '620px',
              alignItems: 'center'
            }}
            className="text-center">
            As a teacher, access our advanced tools and tech to create personalized meditation
            experiences.
          </motion.p>
          <div className="hidden md:block">
            <Floaty onClick={() => navigate('/get-started')}>
              <p
                className="uppercase text-center"
                style={{
                  width: '60px',
                  fontSize: '14px',
                  color: '#FCFCFC'
                }}>
                come join us
              </p>
            </Floaty>
          </div>
        </div>
        <SizedBox height={screenSize.width > 765 ? 144 : 64} />
        <motion.img
          variants={slideUpNoDelay()}
          style={{
            width: '984px'
          }}
          src={ImageAssets.fusion}
          alt=""
        />
        {screenSize.width > 765 ? (
          <></>
        ) : (
          <div>
            <SizedBox height={64} />
            <div>
              <Floaty>
                <p
                  style={{
                    width: '51px',
                    fontSize: '14px',
                    color: '#FCFCFC'
                  }}>
                  HOW IT WORKS
                </p>
              </Floaty>
            </div>
          </div>
        )}
        <SizedBox height={50} />
      </div>
    </StaggerWrapper>
  )
}
export default OnboardingPlatformSection
