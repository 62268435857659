import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'

interface AboutMenuItemProps {
  active: boolean
  children: ReactNode
  title: string
  description: string
  popupDirection?: 'left' | 'right'
  to: string
}

const HoverMenuItem = ({
  active,
  children,
  title,
  description,
  popupDirection = 'right',
  to
}: AboutMenuItemProps) => {
  const [hover, setHover] = useState(false)
  return (
    <Link
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      className="relative"
      style={{
        marginLeft: '40px'
      }}
      to={to}>
      <p
        style={{
          fontSize: '14px',
          borderBottom: active ? '2px solid #9D59EF' : undefined,
          color: '#FCFCFC',
          opacity: active ? 1 : 0.8
        }}>
        {title}
      </p>
      {hover && (
        <div
          onMouseLeave={() => setHover(false)}
          style={{
            right: popupDirection === 'right' ? undefined : '0px',
            top: '20px',
            width: '300px',
            padding: '12px',
            backgroundColor: COLORS.white
          }}
          className="rounded-lg absolute">
          <p className="text-sm">{description}</p>
          <SizedBox height={15} />
          <div>{children}</div>
        </div>
      )}
    </Link>
  )
}

export default HoverMenuItem
