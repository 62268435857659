import IconAssets from 'assets/IconAssets'
import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'

interface TypeCardProps {
  name: string
  image: string
  active?: boolean
  onClick: any
}
const TypeCard = ({ name, image, onClick, active }: TypeCardProps) => {
  return (
    <div
      style={{
        opacity: active ? 1 : 0.6,
        border: `1px solid ${COLORS.white}`,
        padding: '5px',
        borderRadius: '12px'
      }}
      className="flex relative cursor-pointer items-center flex-col"
      onClick={onClick}>
      <div
        className="rounded-full flex items-center justify-center absolute top-3 right-3"
        style={{
          height: '24px',
          width: '24px',
          border: active ? undefined : `1px solid ${COLORS.white}4A`
        }}>
        {active && <img src={IconAssets.chosen} alt="" />}
      </div>
      <SizedBox height={20} />
      <img
        style={{
          height: '350px'
        }}
        src={image}
        alt=""
      />
      <SizedBox height={10} />
      <p
        className="text-sm"
        style={{
          color: COLORS.white
        }}>
        {name}
      </p>
      <SizedBox height={20} />
    </div>
  )
}

export default TypeCard
