interface DividerProps {
  top?: number
  bottom?: number
}

const Divider = ({ top, bottom }: DividerProps) => {
  return (
    <div
      className="w-full"
      style={{
        marginTop: top,
        marginBottom: bottom,
        height: '1px',
        opacity: '0.3',
        backgroundColor: '#FCFCFC'
      }}
    />
  )
}

export default Divider
