import COLORS from 'constants/Colors'
import SimpleStepItem from './SimpleStepItem'
import SizedBox from 'components/Divider/SizedBox'
import useResize from 'utils/useResize'

const SimpleStepsSection = () => {
  const screenSize = useResize()
  return (
    <div className="container mx-auto px-5 md:px-0">
      <div>
        <h1
          className="mx-auto text-center w-full md:w-6/12"
          style={{
            fontSize: screenSize.width > 765 ? '48px' : '20px',
            lineHeight: '95%',
            color: COLORS.white
          }}>
          Unlock Web 3 Functionality in 3 Simple Steps
        </h1>
        <SizedBox height={60} />
        <div className="flex flex-col md:flex-row items-center justify-between gap-10">
          <SimpleStepItem
            title="Soulbind Your NFT"
            description="Make your NFT non-transferable, signifying your commitment to self-improvement and the Metawise community"
            number={1}
          />
          <SimpleStepItem
            title="Access Web 3 Features"
            description="Unlock additional features such as earning tokens, staking tokens for challenges, and participating in the Metawise DAO"
            number={2}
          />
          <SimpleStepItem
            title="Ownership & Right to Delete"
            description="Gain full ownership of your NFT and data, with the option to delete or burn your NFT as desired"
            number={3}
          />
        </div>
      </div>
    </div>
  )
}

export default SimpleStepsSection
