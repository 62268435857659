import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import ActionButton from '../components/ActionButton'
import TeachingSection from '../components/TeachingSection'
import Divider from 'components/Divider/Divider'
import RadioItem from '../SelectType/RadioItem'
import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'

export interface TeachingPracticeProps {
  back: any
  next: any
  save?: any
}

const TeachingPractice3 = ({ back, next, save }: TeachingPracticeProps) => {
  const userData = useGetStarted()
  const { purchaseNFT } = userData
  const updateProperty = useUpdateGetStarted()
  return (
    <div className="flex mb-10 md:mb-0 items-center flex-col justify-center gap-3 w-[400px]">
      <h3
        className="text-center font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Embrace the Revolution: Consciousness as Currency
      </h3>
      <SizedBox height={10} />
      <div
        className="w-[90%] md:w-full mx-auto gap-2 flex flex-col items-start"
        style={{
          backgroundColor: '#2e2633',
          borderRadius: '10px',
          padding: '20px'
        }}>
        <TeachingSection number={3} />
        <h4
          className="font-semibold"
          style={{
            color: COLORS.white,
            fontSize: '16px'
          }}>
          Would you consider a one-time purchase of our exclusive pass?
        </h4>
        <p
          className="opacity-60"
          style={{
            fontSize: '14px',
            color: COLORS.white
          }}>
          <br />
          This pass will initiate the process for you to generate unique Base Energy ($BE) and
          Conscious Energy tokens ($CE). These tokens can then be used for purchases within our
          ecosystem, including subscriptions and other offerings.
        </p>
        <Divider top={10} bottom={10} />
        <div>
          <p
            className="text-sm opacity-60"
            style={{
              color: COLORS.white
            }}>
            Are you interested{' '}
          </p>
          <SizedBox height={10} />
          <RadioItem
            onClick={() => updateProperty('purchaseNFT', 'yes')}
            label="Yes"
            active={purchaseNFT === 'yes'}
          />
          <RadioItem
            onClick={() => updateProperty('purchaseNFT', 'no')}
            label="No"
            active={purchaseNFT === 'no'}
          />
          <RadioItem
            onClick={() => updateProperty('purchaseNFT', 'not sure')}
            label="Not sure"
            active={purchaseNFT === 'not sure'}
          />
        </div>
        {purchaseNFT === 'yes' && (
          <div>
            <h4
              className="font-semibold"
              style={{
                color: COLORS.white,
                fontSize: '16px'
              }}>
              Will you commit to binding your pass exclusively to your account?
            </h4>
            <p
              className="opacity-60"
              style={{
                fontSize: '14px',
                color: COLORS.white
              }}>
              <br />
              By making this decision, you not only unlock token generation capabilities, but also
              gain voting rights and full control over your data, including the ability to delete it
              at will.
            </p>
            <Divider top={10} bottom={10} />
            <div>
              <RadioItem
                onClick={() => updateProperty('nonTransferable', 'yes')}
                label="Yes"
                active={userData.nonTransferable === 'yes'} // Keep using `userData.nonTransferable` here
              />
              <RadioItem
                onClick={() => updateProperty('nonTransferable', 'no')}
                label="No"
                active={userData.nonTransferable === 'no'}
              />
              <RadioItem
                onClick={() => updateProperty('nonTransferable', 'not sure')}
                label="Not sure"
                active={userData.nonTransferable === 'not sure'}
              />
            </div>
          </div>
        )}
        <SizedBox height={120} />
        <div className="w-full flex items-center justify-stretch gap-3">
          <ActionButton type="outline" onClick={back}>
            Back
          </ActionButton>
          <ActionButton
            onClick={() => {
              save(userData)
              next(3)
            }}>
            Join Alpha
          </ActionButton>
        </div>
      </div>
    </div>
  )
}

export default TeachingPractice3
