import Footer from 'components/Footer/Footer'
import JourneySection from 'pages/Home/Components/JourneySection'
import Phase from './Components/Phase'
import RoadMapHeroSection from './Components/RoadMapHeroSection'
import PHASEDATA from './Data/Roadmap.json'
import { useEffect } from 'react'

const RoadMap = () => {
  useEffect(() => {
    document.title = 'Metawise | Roadmap'
  }, [])

  return (
    <div>
      <RoadMapHeroSection />
      <div className="relative z-[5]">
        <div
          style={{
            marginBottom: 100
          }}
          className="w-full">
          <Phase
            phases={PHASEDATA[4]}
            title="Pre Alpha"
            number={0}
            duration="2022 - 2024"
            status="Completed"
          />
          <Phase
            phases={PHASEDATA[0]}
            title="Alpha Launch"
            number={1}
            duration="Q4 2024"
            status="In Progress"
          />
          <Phase
            phases={PHASEDATA[1]}
            title="Beta Launch & Neurofeedback Integration"
            number={2}
            duration="Q1 2025"
            status="In Progress"
          />
          <Phase
            phases={PHASEDATA[2]}
            title="Gamma Phase - Algorithm Development & App Refinement"
            number={3}
            duration="Q2 2025"
            status="In Progress"
          />
          <Phase
            phases={PHASEDATA[3]}
            title="Public Launch - Web3 Integration & Research Platform"
            number={4}
            duration="Q3 2025"
            status="In Progress"
          />
          <Phase
            phases={PHASEDATA[5]}
            title="Expansion & UBI Integration - Ongoing Improvement"
            number={5}
            duration="∞ and Beyond"
            status="In Progress"
          />
        </div>
        <JourneySection />
        <Footer />
      </div>
    </div>
  )
}

export default RoadMap
