import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import Footer from 'components/Footer/Footer'
import Header from 'components/Header/Header'

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="container mx-auto">
        <div className="w-8/12 mx-auto">
          <h1
            style={{
              color: '#FCFCFC',
              fontSize: '78px'
            }}
            className="text-center font-bold">
            Privacy Policy
          </h1>
          <SizedBox height={40} />
          <p
            style={{
              color: '#FCFCFC',
              fontSize: '20px'
            }}
            className="text-center">
            Last Updated January 5th 2023
          </p>
          <SizedBox height={40} />
          <Divider />
          <SizedBox height={40} />
          <p
            style={{
              color: '#FCFCFC',
              marginBottom: 10
            }}>
            At Metawise, we take privacy and data protection seriously. Our meditation platform
            leverages EEG, ML, and blockchain technology to provide users with a scientifically
            verifiable and religiously neutral path towards ever-increasing happiness and
            optimization. We are committed to complying with all relevant European data laws,
            including the General Data Protection Regulation (GDPR). We collect, process and use
            personal data only to the extent necessary for the provision of our platform and the
            associated services. The data we collect includes, but is not limited to, EEG data,
            demographic information, and usage data.
          </p>
          <p
            style={{
              color: '#FCFCFC',
              marginBottom: 10
            }}>
            We collect this data to provide our users with the best possible experience and to
            improve our platform. We ensure that all personal data is collected, stored, and
            processed in a secure manner and that all appropriate technical and organizational
            measures are in place to protect the data from unauthorized access, alteration, or
            destruction. We only share personal data with third parties if it is necessary for the
            provision of our services or if we are legally obliged to do so, and only with parties
            that have been approved by the DAO. The parties seeking to purchase the data will be
            evaluated by the DAO before being approved.
          </p>
          <p
            style={{
              color: '#FCFCFC',
              marginBottom: 10
            }}>
            We inform our users about the collection, processing, and use of their personal data in
            a transparent manner and in accordance with the GDPR. Users have the right to access,
            rectify, and delete their personal data, as well as the right to object to the
            processing of their data, and the right to data portability. Users can exercise these
            rights by contacting our data protection officer. We keep our privacy policy under
            regular review and will update it as necessary to reflect changes in the platform or in
            data protection laws. If you have any questions or concerns about our privacy policy,
            please contact our{' '}
          </p>
        </div>
      </div>
      <SizedBox height={100} />
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
