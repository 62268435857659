import IconAssets from 'assets/IconAssets'
import SizedBox from 'components/Divider/SizedBox'
import useResize from 'utils/useResize'
import ModalInput from './ModalInput'

const CodeModal = () => {
  const screenSize = useResize()
  return (
    <div
      className="relative flex flex-col items-center self-center"
      style={{
        width: screenSize.width > 765 ? undefined : '95vw',
        backgroundColor: '#FCFCFC',
        borderRadius: '20px',
        padding: screenSize.width > 765 ? '64px' : '32px'
      }}>
      <img
        style={{
          marginBottom: '28px'
        }}
        src={IconAssets.whiteLogo}
        alt=""
      />
      <p
        style={{
          width: '70%',
          textAlign: 'center'
        }}>
        Receive one guest activation code after the first energy consumed
      </p>
      <SizedBox height={20} />
      <ModalInput placeholder="Activation code" />
      <SizedBox height={24} />
      <p
        className="text-center"
        style={{
          color: '#231E2F',
          fontSize: '14px'
        }}>
        Guest activation code:{' '}
      </p>
      <SizedBox height={24} />
      <p
        className="uppercase"
        style={{
          color: '#9D59EF',
          fontSize: '16px'
        }}>
        7MXKC-26TBD-D6GJ6-GCGCK-4C9TF
      </p>
      <button
        className="uppercase font-semibold"
        style={{
          marginTop: '40px',
          marginBottom: '24px',
          color: '#FCFCFC',
          fontSize: '14px',
          borderRadius: '100px',
          backgroundColor: '#9D59EF',
          width: screenSize.width > 765 ? '388px' : '85vw',
          padding: '16px'
        }}>
        START
      </button>
    </div>
  )
}

export default CodeModal
