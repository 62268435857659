import ImageAssets from 'assets/ImageAssets'
import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import Logo from 'components/Logo/Logo'
import { Link } from 'react-router-dom'
import useResize from 'utils/useResize'

const Footer = () => {
  const screenSize = useResize()
  const currentYear = () => {
    const date = new Date()
    return date.getFullYear()
  }
  return (
    <footer
      style={{
        marginTop: screenSize.width > 765 ? 10 : -10
      }}>
      <div className="container px-5 md:px-0 mx-auto">
        <div className="flex flex-col md:flex-row md:items-center justify-between">
          <div className="flex flex-row items-center justify-between">
            <Logo showText={false} />
            <button
              style={{
                padding: '7px 22px'
              }}
              className="block md:hidden border hover:bg-white hover:text-black transition-colors text uppercase text-sm rounded-3xl text-white">
              Neurosity
            </button>
          </div>
          {screenSize.width > 600 ? <></> : <SizedBox height={35.8} />}
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col md:flex-row items-center">
              <Link to="/about">
                <p
                  style={{
                    color: '#FCFCFC',
                    fontSize: '16px'
                  }}>
                  About
                </p>
              </Link>
              {screenSize.width > 765 ? <SizedBox width={80} /> : <SizedBox height={18} />}
              <Link to="/science">
                <p
                  style={{
                    color: '#FCFCFC',
                    fontSize: '16px'
                  }}>
                  Science
                </p>
              </Link>
            </div>
            {screenSize.width > 765 && <SizedBox width={80} />}
            <div className="flex flex-col md:flex-row items-center">
              <Link to="/roadmap">
                <p
                  style={{
                    color: '#FCFCFC',
                    fontSize: '16px'
                  }}>
                  Roadmap
                </p>
              </Link>
              {screenSize.width > 765 ? <SizedBox width={80} /> : <SizedBox height={18} />}
              <Link to="/contact">
                <p
                  style={{
                    color: '#FCFCFC',
                    fontSize: '16px'
                  }}>
                  Contact
                </p>
              </Link>
            </div>
            {screenSize.width > 765 && <SizedBox width={133.7} />}
            {/* socials */}
            <div className="flex flex-col md:flex-row items-center">
              <Link target="_blank" to={'https://discord.gg/7Zx6TExnhM'}>
                <img
                  style={{
                    width: '23px'
                  }}
                  src={ImageAssets.discordLogo}
                  alt=""
                />
              </Link>
              {screenSize.width > 765 ? <SizedBox width={80} /> : <SizedBox height={18} />}
              <Link target="_blank" to={'https://twitter.com/MetawiseDojo'}>
                <img
                  style={{
                    width: '21px'
                  }}
                  src={ImageAssets.twitterLogo}
                  alt=""
                />
              </Link>
            </div>
            {screenSize.width > 765 && <SizedBox width={48.7} />}
            <Link target="_blank" to="https://neurosity.co/">
              <button
                style={{
                  padding: '7px 22px'
                }}
                className="hidden md:block border hover:bg-white hover:text-black transition-colors text uppercase text-sm rounded-3xl text-white">
                Neurosity
              </button>
            </Link>
          </div>
        </div>
        <SizedBox height={screenSize.width > 765 ? 48 : 24} />
        <Divider />
        <SizedBox height={screenSize.width > 765 ? 48 : 24} />
        <div className="flex flex-col-reverse md:flex-row items-center justify-between">
          <p
            style={{
              color: '#FCFCFC',
              opacity: '0.7',
              fontSize: '14px'
            }}>{`© ${currentYear()} Metawise Ltd. All Rights Reserved.`}</p>
          <div
            style={{
              height: '32px'
            }}
            className="block md:hidden"
          />
          <div className="flex flex-row items-center">
            <Link to="/privacy">
              <p
                style={{
                  color: '#FCFCFC',
                  opacity: '0.7',
                  fontSize: '14px'
                }}>
                Privacy Policy
              </p>
            </Link>
            <SizedBox width={56} />
            <Link to="/terms">
              <p
                style={{
                  color: '#FCFCFC',
                  opacity: '0.7',
                  fontSize: '14px'
                }}>
                Terms Of Service
              </p>
            </Link>
          </div>
        </div>
        <SizedBox height={32} />
      </div>
    </footer>
  )
}

export default Footer
