import SizedBox from 'components/Divider/SizedBox'
import { useState } from 'react'
import useResize from 'utils/useResize'
import CategoryItem from './CategoryItem'
import ContactItem from './ContactItem'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import QUESTIONS from 'pages/Onboarding/data/questions.json'

interface ContactCategorySectionProps {
  mCategory?: string
}

const ContactCategorySection = ({ mCategory = 'all' }: ContactCategorySectionProps) => {
  const [category, setCategory] = useState(mCategory)
  const screenSize = useResize()

  return (
    <div className="-mt-[50px] md:mt-0 container mx-auto">
      <div className="relative">
        <SizedBox height={80} />
        <motion.div
          whileInView="show"
          initial="hidden"
          viewport={{ once: true }}
          variants={slideUpNoDelay()}
          className="flex items-center justify-center">
          <p
            style={{
              fontSize: screenSize.width > 765 ? '32px' : '18px',
              color: '#FCFCFC',
              width: screenSize.width > 765 ? '618px' : '70%'
            }}
            className="text-center mb-10">
            Here you can find answers to the most popular questions in just a few seconds
          </p>
        </motion.div>
        <SizedBox height={50} />
        <StaggerWrapper>
          <div
            style={{
              zIndex: 2
            }}
            className="flex relative items-center justify-center">
            <CategoryItem onClick={() => setCategory('all')} active={category === 'all'}>
              See All
            </CategoryItem>
            <CategoryItem onClick={() => setCategory('teachers')} active={category === 'teachers'}>
              For Teachers
            </CategoryItem>
            <CategoryItem onClick={() => setCategory('learners')} active={category === 'learners'}>
              For Learners
            </CategoryItem>
          </div>
        </StaggerWrapper>

        <SizedBox height={50} />
      </div>

      <div className="w-9/12 mx-auto">
        {QUESTIONS.map((item: any) => {
          return <ContactItem key={item} answer={item.answer} question={item.question} />
        })}
      </div>
    </div>
  )
}

export default ContactCategorySection
