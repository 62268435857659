import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import Header from 'components/Header/Header'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import useResize from 'utils/useResize'
import { motion } from 'framer-motion'
import { fadeIn, slideUpNoDelay } from 'utils/motion'
import Stars from 'components/Background/Stars/Stars'

const AboutHeroSection = () => {
  const screenSize = useResize()
  return (
    <div className="relative">
      <Stars />
      <Header />
      <div className="relative z-[5]">
        <SizedBox height={screenSize.width > 765 ? 70 : 70} />
        <StaggerWrapper>
          <div
            style={{
              zIndex: 3
            }}
            className="relative mx-auto px-5 md:px-0 container flex flex-col md:flex-row items-center justify-between">
            <div
              className="flex flex-col items-center md:items-start"
              style={{
                width: screenSize.width > 765 ? '510px' : undefined
              }}>
              <motion.h1
                style={{
                  fontSize: screenSize.width > 765 ? '78px' : '50px',
                  lineHeight: screenSize.width > 765 ? '78px' : '50px'
                }}
                className="text-center md:text-left text-white">
                We are the Metawise collective
              </motion.h1>
              <SizedBox height={screenSize.width > 765 ? 40 : 24} />
              <motion.p
                variants={slideUpNoDelay()}
                style={{
                  color: '#FCFCFC',
                  fontSize: screenSize.width > 765 ? '20px' : '14px',
                  opacity: 0.8,
                  lineHeight: '140%'
                }}
                className="text-center md:text-left w-9/12">
                Building a digital dojo for mindful growth and wellness
              </motion.p>
            </div>
            <SizedBox height={screenSize.width > 765 ? 40 : 24} />
            <motion.div variants={fadeIn('left', 'tween', 0.25, 0.25)} className="relative">
              <motion.img
                animate={{
                  top: [
                    screenSize.width > 765 ? '50px' : '150px',
                    screenSize.width > 765 ? '60px' : '160px',
                    screenSize.width > 765 ? '50px' : '150px'
                  ]
                }}
                transition={{ repeat: Infinity, repeatDelay: 0.75, duration: 1.5 }}
                style={{
                  zIndex: 1,
                  right: '5px',
                  top: screenSize.width > 765 ? '50px' : '150px'
                }}
                className="absolute"
                src={IconAssets.collective}
                alt=""
              />
              <motion.img
                animate={{
                  bottom: ['40px', '50px', '40px']
                }}
                transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
                style={{
                  zIndex: 1,
                  left: '5px',
                  bottom: '40px',
                  transform: 'scale(0.8)'
                }}
                className="absolute"
                src={IconAssets.track}
                alt=""
              />
              <img
                style={{
                  transform: 'scale(0.8)' // Add this line to scale the image down by 30%
                }}
                src={ImageAssets.community}
                alt=""
              />
            </motion.div>
          </div>
        </StaggerWrapper>
      </div>
    </div>
  )
}

export default AboutHeroSection
