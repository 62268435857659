import ImageAssets from 'assets/ImageAssets'
import './stars.css'
const Stars = () => {
  return (
    <div className="fixed w-screen h-screen">
      <div
        style={{
          background: `url(${ImageAssets.stars}) repeat top center`
        }}
        className="stars z-0"></div>
      <div
        style={{
          background: `transparent url(${ImageAssets.twinkle}) repeat top center`
        }}
        className="twinkling"></div>
    </div>
  )
}

export default Stars
