/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode, useState } from 'react'
import { zoomInNoDelay, floatTextVariant, slideIn, slideInNoDelay } from 'utils/motion'
import './styles.css'
import { motion } from 'framer-motion'
import useResize from 'utils/useResize'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'

interface FloatyProps {
  onClick?: any
  children: ReactNode
  size?: 'large' | 'small'
  expandedText?: React.ReactNode | undefined // Change the type here
  position?: 'top' | 'bottom'
  direction?: 'right' | 'left' | 'center'
}

export const renderWords = (paragraph: any) => {
  const newParagraph = paragraph as string
  return (
    <StaggerWrapper>
      <div className="flex flex-row flex-wrap gap-1">
        {newParagraph?.split(' ')?.map((item: string, index: number) => {
          return (
            <motion.p
              variants={slideIn('left', 'tween', 0.05 * (index + 1), 0.01)}
              key={index}
              style={{
                color: '#FCFCFC',
                fontSize: '14px'
              }}>
              {item}
            </motion.p>
          )
        })}
      </div>
    </StaggerWrapper>
  )
}

const Floaty = ({
  onClick = () => {},
  children,
  size = 'large',
  expandedText,
  position = 'top',
  direction = 'right'
}: FloatyProps) => {
  const screenSize = useResize()
  const [show, setShow] = useState(false)

  const variants = {
    show: { opacity: 1, transition: { duration: 0.1 } },
    hide: { opacity: 0, transition: { duration: 0.1 } }
  }
  return (
    <motion.div
      onClick={() => {
        if (expandedText) {
          setShow(!show)
        } else {
          onClick()
        }
      }}
      variants={zoomInNoDelay()}
      style={{
        height: size === 'large' ? '148px' : '100px',
        width: size === 'large' ? '148px' : '100px'
      }}
      className="cursor-pointer relative floaty flex items-center justify-center rounded-full">
      {children}
      {show && (
        <motion.div
          variants={variants}
          initial="hide"
          animate={show ? 'show' : 'hide'}
          className="absolute"
          style={{
            top: screenSize.width > 765 ? (position === 'top' ? '-180%' : undefined) : undefined,
            left:
              screenSize.width > 765
                ? position === 'top'
                  ? '-200%'
                  : undefined
                : direction === 'right'
                ? '-190%'
                : undefined,
            right:
              screenSize.width > 765
                ? position === 'bottom'
                  ? '-200%'
                  : undefined
                : direction === 'left'
                ? '-190%'
                : undefined,
            bottom:
              screenSize.width > 765 ? (position === 'bottom' ? '-270%' : undefined) : '-150%',
            boxShadow:
              'inset 0px 5.07px 13.0273px -8.37468px rgba(255, 255, 255, 0.5), inset 0px -19.0757px 15.82px -14.8883px rgba(121, 108, 145, 0.15), inset 0px 22.7977px 23.263px -11.1662px rgba(202, 172, 255, 0.2), inset 0px 0.93052px 4.18734px rgba(154, 146, 210, 0.3), inset 0px 0.23263px 9.3052px rgba(227, 222, 255, 0.2)',
            background: 'rgba(233, 207, 208, 0.01)',
            width: '290px',
            padding: '20px',
            backdropFilter: 'blur(32.7411px)',
            WebkitBackdropFilter: 'blur(32.7411px)',
            borderRadius: '12px'
          }}>
          {renderWords(expandedText)}
        </motion.div>
      )}
    </motion.div>
  )
}

export default Floaty
