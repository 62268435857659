import COLORS from 'constants/Colors'
import { useMusic } from 'contexts/MusicContextProvider'
import { motion } from 'framer-motion'

const SoundBar = () => {
  const { playing, audioRef } = useMusic()
  return (
    <div
      onClick={() => {
        if (playing) {
          audioRef.current.pause()
        } else {
          audioRef.current.play()
        }
      }}
      className="cursor-pointer flex gap-1 flex-row items-center justify-center">
      <motion.div
        animate={playing ? { height: ['12px', '20px'] } : undefined}
        transition={{ repeat: playing ? Infinity : 0, repeatDelay: 0.25 }}
        style={{
          height: '20px',
          width: '3px',
          borderRadius: '5px',
          backgroundColor: COLORS.white
        }}
      />
      <motion.div
        animate={playing ? { height: ['12px', '24px'] } : undefined}
        transition={{ repeat: playing ? Infinity : 0, repeatDelay: 0.5 }}
        style={{
          height: '24px',
          width: '3px',
          borderRadius: '5px',
          backgroundColor: COLORS.white
        }}
      />
      <motion.div
        animate={playing ? { height: ['12px', '24px'] } : undefined}
        transition={{ repeat: playing ? Infinity : 0, repeatDelay: 0.75 }}
        style={{
          height: '24px',
          width: '3px',
          borderRadius: '5px',
          backgroundColor: COLORS.white
        }}
      />
      <motion.div
        animate={playing ? { height: ['12px', '24px'] } : undefined}
        transition={{ repeat: playing ? Infinity : 0, repeatDelay: 1 }}
        style={{
          height: '24px',
          width: '3px',
          borderRadius: '5px',
          backgroundColor: COLORS.white
        }}
      />
      <motion.div
        animate={playing ? { height: ['12px', '20px'] } : undefined}
        transition={{ repeat: playing ? Infinity : 0, repeatDelay: 0.25 }}
        style={{
          height: '20px',
          width: '3px',
          borderRadius: '5px',
          backgroundColor: COLORS.white
        }}
      />
    </div>
  )
}

export default SoundBar
