/* eslint-disable @typescript-eslint/no-unused-vars */
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import Footer from 'components/Footer/Footer'
import JourneySection from 'pages/Home/Components/JourneySection'
import DetailSection from 'pages/Onboarding/Components/DetailSection'
import ScienceParagraph from 'pages/Science/Components/ScienceParagraph'
import useResize from 'utils/useResize'
import AboutHeroSection from './Components/AboutHeroSection'
import CombinationSection from './Components/CombinationSection'
import CompaniesSection from './Components/CompaniesSection'
import HorizontalTeamSection from './Components/HorizontalTeamSection'
import { useEffect, useState } from 'react'
import HorizontalAdvisorSection from './Components/HorizontalAdvisorSection'
import '../Home/Styles.scss'
import TeamSlideShow from './Components/TeamSlideShow'
import AdvisorSlideShow from './Components/AdvisorSlideShow'
import { renderModal } from './utils/LoadModal'

const About = () => {
  const [visibleModal, setVisibleModal] = useState('none')
  const screenSize = useResize()

  useEffect(() => {
    document.title = 'Metawise | About'
  }, [])

  return (
    <div>
      {renderModal(visibleModal, setVisibleModal)}
      <AboutHeroSection />
      <div
        style={{
          overflowX: screenSize.width > 756 ? undefined : 'hidden'
        }}
        className="relative container mx-auto md:px-10">
        <div
          className="relative"
          style={{
            zIndex: '2'
          }}>
          <DetailSection subtitle="Mission & Vision" image={ImageAssets.missionvision2}>
            <ScienceParagraph>
              Our endeavour is to shape a digital haven - a platform where users can learn
              meditation, delve into spirituality, nurture their well-being, and explore alternative
              economies. Central to this is the introduction of &quot;Conscious Energy&quot; ($CE),
              a unique economic medium exclusive to this Conscious Metaverse, reflecting our
              dedication to mindful transactions.
            </ScienceParagraph>
            <ScienceParagraph>
              Beyond this, our vision encompasses facilitating decentralized data sharing,
              encouraging individual control and collective cooperation. Our ultimate goal is to
              foster a thriving community that actively navigates the interconnected spheres of
              spirituality, economics, artificial intelligence, and neuroscience.
            </ScienceParagraph>
          </DetailSection>
          <DetailSection subtitle="Technology" image={ImageAssets.technology}>
            <ScienceParagraph>
              Metawise integrates inner technology innate to humans with outer technology for a
              transformative journey. We utilize neurofeedback training, drawing from a large-scale
              brainwave dataset, to unlock enhanced states of wellness. Our platform accurately
              identifies and guides users towards meditative states, incentivizing EEG data
              contribution with Base Energy ($BE) tokens. Conscious Energy ($CE), another unique
              digital token, symbolizes and rewards your spiritual growth and the value of high
              consciousness states. Each step towards higher consciousness, akin to the concept of
              &quot;karma&quot;, is digitally recognized and rewarded.
            </ScienceParagraph>
          </DetailSection>
        </div>
        <SizedBox height={100} />
        <HorizontalTeamSection showModal={setVisibleModal} />
        <TeamSlideShow showModal={setVisibleModal} />
        <CombinationSection />
        <SizedBox height={100} />
        <CompaniesSection />
        <SizedBox height={100} />
        <HorizontalAdvisorSection showModal={setVisibleModal} />
        <AdvisorSlideShow showModal={setVisibleModal} />
        <SizedBox height={140} />
        <JourneySection />
        <Footer />
      </div>
    </div>
  )
}

export default About
