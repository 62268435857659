import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'

interface TeamMemberItemProps {
  offset?: number
  name: string
  title: string
  image: string
  onClick?: any
  showButton?: boolean
}

const TeamMemberItem = ({
  offset = 0,
  name,
  title,
  image,
  onClick,
  showButton = true
}: TeamMemberItemProps) => {
  return (
    <div>
      <SizedBox height={offset} />
      <div className="relative">
        {showButton && (
          <img
            onClick={onClick}
            className="cursor-pointer absolute"
            src={ImageAssets.teamExpand}
            style={{
              right: '-16px',
              top: '20px',
              width: '32px'
            }}
            alt=""
          />
        )}
        <div
          className="rounded-lg flex justify-end items-end overflow-hidden"
          style={{
            backgroundColor: '#75547C',
            width: '300px',
            height: '401px'
          }}>
          <img className="max-h-full" src={image} alt="" />
        </div>
        <SizedBox height={10} />
        <div>
          <h4
            className="font-semibold"
            style={{
              color: COLORS.white
            }}>
            {name}
          </h4>
          <p
            className="text-sm opacity-60"
            style={{
              color: COLORS.white
            }}>
            {title}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TeamMemberItem
