import { ReactNode, createContext, useContext, useState } from 'react'

export interface CustomContextProps {
  children: ReactNode
}

interface GetStartedContextType {
  type: 'teacher' | 'student'
  name: string
  password: string
  confirmPassword: string
  email: string
  reasons: string[]
  expertise: string
  age: number
  occupation: string
  gender: string
  meditatePerWeek: string
  exercisePerWeek: string
  sleepPerDay: string
  mentalAppsUsed: string
  teachingExperience: string
  teachingExpertise: string
  teachingPhilosophy: string
  teachingFormat: string
  preferredGroup: string
  schoolsTaught: string
  aimForStudents: string
  certification: string
  enlightenmentExperiences: string
  socialMedia: string
  purchaseNFT: 'yes' | 'no' | 'not sure'
  nonTransferable: 'yes' | 'no' | 'not sure'
}

const initialData: GetStartedContextType = {
  type: 'teacher',
  name: '',
  password: '',
  confirmPassword: '',
  email: '',
  reasons: [],
  expertise: 'beginner',
  age: 18,
  occupation: '',
  gender: ' ',
  meditatePerWeek: '',
  sleepPerDay: '',
  exercisePerWeek: '',
  mentalAppsUsed: '',
  teachingExperience: '',
  teachingExpertise: '',
  teachingPhilosophy: '',
  teachingFormat: '',
  preferredGroup: '',
  schoolsTaught: '',
  aimForStudents: '',
  certification: '',
  enlightenmentExperiences: '',
  socialMedia: '',
  purchaseNFT: 'not sure',
  nonTransferable: 'not sure'
}

const GetStartedContext = createContext<GetStartedContextType>(initialData)

const UpdateGetStartedContext = createContext<any>(undefined)

export const useGetStarted = () => {
  return useContext(GetStartedContext)
}

export const useUpdateGetStarted = () => {
  return useContext(UpdateGetStartedContext)
}

export const useUpdateGetStartedContext = () => {
  return useContext(UpdateGetStartedContext)
}

const GetStartedContextProvider = ({ children }: CustomContextProps) => {
  const [data, setData] = useState<any>(initialData)

  const updateProperty = (mkey: string, value: any) => {
    const updatedObject: any = {}
    updatedObject[mkey] = value
    setData({ ...data, ...updatedObject })
  }

  return (
    <GetStartedContext.Provider value={data}>
      <UpdateGetStartedContext.Provider value={updateProperty}>
        {children}
      </UpdateGetStartedContext.Provider>
    </GetStartedContext.Provider>
  )
}

export default GetStartedContextProvider
