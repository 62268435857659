export default {
  close: 'assets/icons/close.svg',
  plus: 'assets/icons/plus.svg',
  bolt: 'assets/icons/bolt.svg',
  discord: 'assets/icons/circle-discord.svg',
  dashboard: 'assets/icons/activity-chart.svg',
  entering: 'assets/icons/entering.svg',
  CE: 'assets/icons/CE.svg',
  collectiveMeditation: 'assets/icons/collective-meditations.svg',
  discordB: 'assets/icons/discord.svg',
  twitter: 'assets/icons/twitter.svg',
  contactButton: 'assets/icons/contact-button.svg',
  slideIcon: 'assets/icons/slide-icon.svg',
  book: 'assets/icons/book.svg',
  flask: 'assets/icons/flask.svg',
  graduation: 'assets/icons/graduation.svg',
  quotes: 'assets/icons/quotes.svg',
  track: 'assets/icons/track.svg',
  collective: 'assets/icons/collective.svg',
  whiteLogo: 'assets/icons/white-logo.svg',
  supporting: 'assets/icons/supporting.svg',
  accessibility: 'assets/icons/accessibility.svg',
  globalProgress: 'assets/icons/global-progress.svg',
  bws: 'assets/icons/bws.svg',
  halolab: 'assets/icons/halo-lab.svg',
  enlightning: 'assets/icons/enlightning.svg',
  neuro: 'assets/icons/neuro.svg',
  heroBG: 'assets/icons/BG.svg',
  heroBGtest: 'assets/icons/BG_test1.svg',
  heroBGtest2: 'assets/icons/BG_test2.svg',
  heroBGtest3: 'assets/icons/BG_test3.svg',
  download: 'assets/icons/download.svg',
  coin: 'assets/icons/coin.svg',
  REcoin: 'assets/icons/REcoin.svg',
  rightArrow: 'assets/icons/right_arrow.svg',
  teacher: 'assets/icons/teacher.svg',
  textLogo: 'assets/icons/text-logo.svg',
  floatPlus: 'assets/icons/float_plus.svg',
  star: 'assets/icons/star.svg',
  dollar: 'assets/icons/dollar.svg',
  at: 'assets/icons/at.svg',
  wallet: 'assets/icons/wallet.svg',
  headphones: 'assets/icons/headphones.svg',
  trendUp: 'assets/icons/trendup.svg',
  infinity: 'assets/icons/infinity.svg',
  conscious: 'assets/icons/conscious.svg',
  tip: 'assets/icons/tip.svg',
  x: 'assets/icons/X.svg',
  chosen: 'assets/icons/chosen.svg',
  eyeClosed: 'assets/icons/eye_closed.svg'
}
