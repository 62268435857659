import ImageAssets from 'assets/ImageAssets'
import { motion } from 'framer-motion'
const DojoImages = () => {
  return (
    <div
      style={{
        height: '750px',
        marginTop: '20px'
      }}
      className="relative w-full">
      <motion.img
        animate={{
          left: [40, 50, 40]
        }}
        transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
        src={ImageAssets.activity}
        className="absolute"
        style={{
          width: '250px',
          left: 40
        }}
        alt=""
      />
      <motion.img
        animate={{
          transform: ['scale(1)', 'scale(1.01)', 'scale(1)']
        }}
        transition={{ repeat: Infinity, repeatDelay: 0.75, duration: 1.5 }}
        className="absolute"
        style={{
          right: '35%'
        }}
        src={ImageAssets.playlist}
        alt=""
      />
      <motion.img
        animate={{
          right: [40, 50, 40]
        }}
        transition={{ repeat: Infinity, repeatDelay: 0.75, duration: 1.5 }}
        className="absolute top-0"
        style={{
          right: 40
        }}
        src={ImageAssets.vote}
        alt=""
      />
      <motion.img
        animate={{
          right: [20, 30, 20]
        }}
        transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
        className="absolute"
        style={{
          right: 20
        }}
        src={ImageAssets.re}
        alt=""
      />
      <motion.img
        animate={{
          bottom: [30, 40, 30]
        }}
        transition={{ repeat: Infinity, repeatDelay: 0.75, duration: 1.5 }}
        src={ImageAssets.collectiveMeditation}
        className="absolute"
        style={{
          bottom: 30,
          left: 10
        }}
        alt=""
      />
      <motion.img
        animate={{
          bottom: [120, 130, 120]
        }}
        transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
        src={ImageAssets.ce}
        className="absolute"
        style={{
          left: 150,
          bottom: 120
        }}
        alt=""
      />
      <motion.img
        animate={{
          bottom: [0, 10, 0]
        }}
        transition={{ repeat: Infinity, repeatDelay: 0.75, duration: 2 }}
        className="absolute"
        src={ImageAssets.transmute}
        style={{
          bottom: 0,
          right: 120
        }}
        alt=""
      />
    </div>
  )
}

export default DojoImages
