import { TextProps } from './Subtitle'

const Paragraph = ({ children }: TextProps) => {
  return (
    <p
      style={{
        marginBottom: 10,
        color: '#FCFCFC',
        opacity: 0.8
      }}>
      {children}
    </p>
  )
}

export default Paragraph
