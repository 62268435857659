import IconAssets from 'assets/IconAssets'
import Logo from 'components/Logo/Logo'
import { ReactNode, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SoundBar from './SoundBar'
import GetStartedButton from './GetStartedButton'

interface SideMenuProps {
  close: any
}

interface CustomMenuProps {
  to: string
  children: ReactNode
  active: boolean
  action?: any
}

const MenuText = ({ to = '', children, active, action }: CustomMenuProps) => {
  return (
    <Link onClick={action} to={to}>
      <p
        style={{
          borderBottom: active ? '2px solid #9D59EF' : undefined,
          fontSize: '32px',
          fontWeight: active ? 'bold' : 'lighter',
          marginTop: '20px'
        }}
        className="text-white uppercase">
        {children}
      </p>
    </Link>
  )
}

const SideMenu = ({ close }: SideMenuProps) => {
  const currentPath = window.location.pathname
  const [showOptions, setShowOptions] = useState(false)
  const navigate = useNavigate()
  return (
    <div
      style={{
        backgroundColor: '#1D1521',
        width: '100vw'
      }}
      className="fixed right-0 bottom-0 top-0 left-0 p-10 flex flex-col items-center">
      <div className="flex w-full flex-row justify-between items-center">
        <Logo showText size="small" />
        <img
          onClick={close}
          className="cursor-pointer"
          style={{
            width: '22px'
          }}
          src={IconAssets.close}
          alt=""
        />
      </div>
      <div className="md:flex-1 mt-[30px] flex flex-col items-center md:block">
        <MenuText active={currentPath.includes('about')} to="/about">
          About
        </MenuText>
        <MenuText active={currentPath.includes('roadmap')} to="/roadmap">
          Roadmap
        </MenuText>
        <MenuText
          action={() => setShowOptions(!showOptions)}
          active={currentPath.includes('onboarding')}
          to="">
          Onboarding
        </MenuText>
        <div className={`${showOptions ? 'block' : 'hidden'} transition-all`}>
          <p
            style={{
              margin: '10px 0 20px 10px',
              color: '#FCFCFC'
            }}>
            Instructions for getting started
          </p>
          <Link to="/onboarding/student">
            <div
              style={{
                borderRadius: '8px',
                backgroundColor: '#FCFCFC',
                padding: '20px 50px'
              }}>
              <h3
                style={{
                  fontSize: '14px',
                  color: '#231E2F',
                  fontWeight: 'bold'
                }}>
                For practitioners
              </h3>
              <p
                style={{
                  color: '#231E2F',
                  fontSize: '14px'
                }}>
                Simple steps to perfection
              </p>
            </div>
          </Link>
          <Link to="/onboarding/teacher">
            <div
              style={{
                marginTop: '8px',
                borderRadius: '8px',
                backgroundColor: '#413847',
                padding: '20px 50px'
              }}>
              <h3
                style={{
                  fontSize: '14px',
                  color: '#FCFCFC',
                  fontWeight: 'bold'
                }}>
                For teachers
              </h3>
              <p
                style={{
                  color: '#FCFCFC',
                  fontSize: '14px'
                }}>
                Working with the platform
              </p>
            </div>
          </Link>
        </div>
        <MenuText active={currentPath.includes('science')} to="/science">
          Science
        </MenuText>
        <MenuText active={currentPath.includes('research')} to="/research">
          Research
        </MenuText>
        <MenuText active={currentPath.includes('contact')} to="/contact">
          Contact
        </MenuText>
        <div className="flex w-full mt-8 items-center justify-center">
          <GetStartedButton />
        </div>
        <div className="mt-8">
          <SoundBar />
        </div>
      </div>
    </div>
  )
}

export default SideMenu
