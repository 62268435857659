// import ImageAssets from 'assets/ImageAssets'
// import SizedBox from 'components/Divider/SizedBox'
import useResize from 'utils/useResize'
import VideoAssets from 'assets/VideoAssets'
import ImageAssets from 'assets/ImageAssets'
import WaveFloaty from './WaveFloaty'
import { useState, useEffect } from 'react'

const WaveSection = () => {
  const screenSize = useResize()

  const [insideOutside, setInsideOutside] = useState('inside')

  useEffect(() => {
    const interval = setInterval(() => {
      setInsideOutside((prevState) => (prevState === 'inside' ? 'outside' : 'inside'))
    }, 30000)

    return () => clearInterval(interval) // Clear interval on component unmount
  }, [])

  return (
    <div className="flex flex-col items-center container mx-auto px-5 md:px-0">
      <p
        className="text-center glowing-text"
        style={{
          color: '#FCFCFC',
          fontSize: screenSize.width > 765 ? '32px' : '18px',
          lineHeight: '140%',
          width: screenSize.width > 765 ? '650px' : undefined,
          alignItems: 'center',
          textShadow: '0 0 10px rgba(150, 150, 150, 0.4), 0 0 20px rgba(150, 150, 150, 0.3)'
        }}>
        <span style={{ whiteSpace: 'nowrap' }}>This is where meditation meets technology</span>
        <br />
        Where {insideOutside} becomes {insideOutside === 'inside' ? 'outside' : 'inside'}
        <br />
        And where {insideOutside === 'inside' ? 'outside' : 'inside'}, {insideOutside}
        <br />
        It is where dualities collapse
        <br />
        And True knowledge rises
      </p>
      {/* <SizedBox height={197} /> */}
      <div className="relative mt-[80px] w-full">
        <WaveFloaty
          utilities={['Transmute to Conscious Energy', 'Platform Services']}
          earnedBy="Contributing Validated EEG Data"
          tokenName="Base Energy ($BE)"
          left="20%"
          top="80%"
        />
        <img
          style={{
            left: screenSize.width > 765 ? '-5%' : '-2%',
            top: screenSize.width > 765 ? '13%' : '19%',
            width: screenSize.width > 765 ? '190px' : '80px',
            height: screenSize.width > 765 ? '190px' : undefined,
            zIndex: 5
          }}
          className="absolute left-0"
          src={ImageAssets.reFinal}
          alt=""
        />
        <video
          playsInline
          className="relative mx-auto"
          style={{
            width: '90%',
            mixBlendMode: 'screen'
          }}
          src={VideoAssets.transmutation1}
          autoPlay
          loop
          muted
        />
        <img
          style={{
            top: screenSize.width > 765 ? '15%' : '25%',
            width: screenSize.width > 765 ? '180px' : '70px',
            height: screenSize.width > 765 ? '180px' : undefined,
            zIndex: 5
          }}
          className="absolute right-0"
          src={ImageAssets.ceFinal}
          alt=""
        />
        <WaveFloaty
          utilities={[
            'Universal Basic Income (UBI)',
            'Level Up',
            'Voting Rights',
            'Data Access',
            'High-Value "Karma" Token',
            'Spiritual Progress Marker',
            'Exclusive Access and Privileges'
          ]}
          earnedBy="Inhabiting AI-Detected Meditative States"
          tokenName="Conscious Energy ($CE)"
          right="20%"
          top="10%"
        />
      </div>
    </div>
  )
}

export default WaveSection
