import ImageAssets from 'assets/ImageAssets'
import useResize from 'utils/useResize'
import NewGenCard from './NewGenCard'
import IconAssets from 'assets/IconAssets'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useMotionValue, useSpring, useTransform, motion } from 'framer-motion'
import { useScrollPercentage } from 'react-scroll-percentage'
import COLORS from 'constants/Colors'

const HorizontalSection = () => {
  const scrollRef = useRef<any>(null)
  const ghostRef = useRef<any>(null)

  const [scrollRange, setScrollRange] = useState(0)
  const [viewportW, setViewportW] = useState(0)

  const scrollPerc = useMotionValue(0)

  useLayoutEffect(() => {
    scrollRef && setScrollRange(scrollRef.current.scrollWidth)
  }, [scrollRef])

  const onResize = useCallback((entries: any) => {
    for (const entry of entries) {
      setViewportW(entry.contentRect.width)
    }
  }, [])

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => onResize(entries))
    resizeObserver.observe(ghostRef.current)
    return () => resizeObserver.disconnect()
  }, [onResize])

  const [containerRef, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0.1
  })

  useEffect(() => {
    scrollPerc.set(percentage)
  }, [percentage])

  const transform = useTransform(scrollPerc, [0, 1], [0, -scrollRange + (viewportW - 1050)])
  const physics = { damping: 20, mass: 0.25, stiffness: 75 }
  const spring = useSpring(transform, physics)

  const screenSize = useResize()
  return (
    <div
      style={{
        marginTop: screenSize.width > 765 ? undefined : '80px'
      }}
      ref={containerRef}>
      <div className="scroll-container">
        <motion.section ref={scrollRef} style={{ x: spring }} className="flex thumbnails-container">
          <h1
            className="font-semibold"
            style={{
              marginRight: '80px',
              width: '300px',
              padding: '0px 40px',
              fontSize: '48px',
              color: COLORS.white
            }}>
            Begin your journey
          </h1>
          <div className="thumbnails">
            <NewGenCard
              icon={IconAssets.download}
              details="Get started by visiting our platform and explore the Metawise ecosystem designed to elevate your meditation practice"
              title="Launch our app"
            />
            <NewGenCard
              icon={IconAssets.star}
              details="Discover our unique features, including guided meditations, group meditations, research opportunities, community connections, and more."
              title="Explore"
              image={ImageAssets.phone}
              offset={100}
            />
            {/* <NewGenCard
              icon={IconAssets.dollar}
              title="Choose membership"
              details="Select a monthly membership plan that suits your needs. For lifetime access and Web 3 functionality, consider purchasing the Metawise Pass (NFT) for $500 and Soulbinding it"
              image={ImageAssets.femonk}
            />
            <NewGenCard
              icon={IconAssets.headphones}
              details="Purchase a Neurosity CROWN™ headset to unlock real-time neurofeedback and personalized guidance."
              title="Get your headset"
              image={ImageAssets.headset}
              offset={100}
            /> */}
            <NewGenCard
              icon={IconAssets.wallet}
              title="Connect your wallet & headset"
              details="Seamlessly connect your digital wallet and EEG headset to the Metawise platform"
              image={ImageAssets.connect}
            />
            <NewGenCard
              icon={IconAssets.at}
              title="Meditate, Train & Contribute Your EEG Data"
              details="Participate in cutting-edge research by contributing your EEG data to advance the science of meditation"
              image={ImageAssets.meditate}
              offset={100}
            />
            <NewGenCard
              icon={IconAssets.at}
              title="Earn Base Energy ($BE)"
              details="Be rewarded for simply being human. Earn Base Energy ($BE) tokens for your EEG data contribution"
              image={ImageAssets.earn}
            />
            <NewGenCard
              icon={IconAssets.conscious}
              title="Transmute to 
          Conscious Energy ($CE)"
              details="Transmute your Base Energy ($BE) tokens into Conscious Energy ($CE) tokens, representing your meditative states. Conscious Energy tokens symbolize your dedication to mindfulness and self-improvement. How quickly you earn them represents your progress"
              image={ImageAssets.conscious}
              offset={100}
            />
            <NewGenCard
              icon={IconAssets.infinity}
              title="Level Up"
              details="Progress through our Level Up system, unlocking exclusive content and reaching new milestones in your meditation journey. Each level achieved signifies growth and advancement in your practice"
              image={ImageAssets.levelUp}
            />
            <NewGenCard
              icon={IconAssets.trendUp}
              offset={100}
              title="Transcend"
              details=""
              image={ImageAssets.transcend}
            />
          </div>
        </motion.section>
      </div>
      <div ref={ghostRef} style={{ height: scrollRange }} className="ghost" />
    </div>
  )
}

export default HorizontalSection
