import COLORS from 'constants/Colors'
import ActionButton from '../components/ActionButton'
import ImageAssets from 'assets/ImageAssets'
import useResize from 'utils/useResize'
import { useNavigate } from 'react-router'

const EnterPlatform = () => {
  const screenSize = useResize()
  const navigate = useNavigate()
  return (
    <div className="w-[600px] flex flex-col items-center gap-3 justify-center">
      <img
        style={{
          width: screenSize.width > 768 ? '350px' : '80%'
        }}
        src={ImageAssets.onboarding}
        alt=""
      />
      <h3
        className="font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Congratulations!
      </h3>
      <p
        className="text-center opacity-60"
        style={{
          fontSize: '14px',
          color: COLORS.white
        }}>
        {`Thank you for your application. We are delighted to welcome you to the Metawise family! You'll receive a confirmation email with further details shortly. Once again, we appreciate your engagement and are excited for the journey ahead!`}
      </p>
      <div>
        <ActionButton
          onClick={() => {
            navigate('/')
          }}>
          CONTINUE EXPLORING
        </ActionButton>
      </div>
    </div>
  )
}

export default EnterPlatform
