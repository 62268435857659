import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import Floaty from 'components/Floaty/Floaty'
import useResize from 'utils/useResize'
import { motion } from 'framer-motion'
import { slideIn } from 'utils/motion'
import { useNavigate } from 'react-router'

const PlatformSection = () => {
  const screenSize = useResize()
  const navigate = useNavigate()
  return (
    <div>
      <div className="px-5 md:px-0 mt-[40px] mb-[50px] container flex flex-col items-center mx-auto">
        <motion.div
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          variants={slideIn('top', 'tween', 0.25, 0.25)}
          className="flex w-full flex-row items-center justify-between">
          <img
            className="hidden md:block"
            style={{
              width: '255px'
            }}
            src={ImageAssets.tree}
            alt=""
          />
          <div
            className="md:ml-[-50px]"
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <h2
              style={{
                color: '#FCFCFC',
                fontSize: screenSize.width > 768 ? '36px' : '22px',
                lineHeight: '110%',
                textAlign: 'center'
              }}>
              The Fusion of Science and Spirituality
            </h2>
            <p
              className="mt-[10px] text-center text-[#FCFCFC]"
              style={{
                fontSize: screenSize.width > 768 ? '32px' : '18px',
                lineHeight: '140%',
                width: screenSize.width > 768 ? '620px' : 'auto'
              }}>
              A New Era for Personal Growth
              <br />
            </p>
          </div>
          <div className="hidden md:block">
            <Floaty onClick={() => navigate('/onboarding')}>
              <p
                style={{
                  width: '51px',
                  fontSize: '14px',
                  color: '#FCFCFC'
                }}>
                HOW IT WORKS
              </p>
            </Floaty>
          </div>
        </motion.div>
        <SizedBox height={screenSize.width > 765 ? 144 : 64} />
        <motion.img
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          variants={slideIn('top', 'tween', 0.25, 0.25)}
          style={{
            width: '984px'
          }}
          src={ImageAssets.dojo}
          alt=""
        />
        {screenSize.width > 765 ? (
          <></>
        ) : (
          <div className="md: ml-[-20px]">
            <div className="mt-[64px]">
              <Floaty>
                <p
                  style={{
                    width: '51px',
                    fontSize: '14px',
                    color: '#FCFCFC'
                  }}>
                  HOW IT WORKS
                </p>
              </Floaty>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default PlatformSection
