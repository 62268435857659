import IconAssets from 'assets/IconAssets'
import SizedBox from 'components/Divider/SizedBox'
import useResize from 'utils/useResize'
import ModalInput from './ModalInput'
import { ModalContentProps } from './RegisterModal'

const LoginModal = ({ nextAction }: ModalContentProps) => {
  const screenSize = useResize()
  return (
    <div
      className="relative flex flex-col items-center self-center"
      style={{
        width: screenSize.width > 765 ? undefined : '95vw',
        backgroundColor: '#FCFCFC',
        borderRadius: '20px',
        padding: screenSize.width > 765 ? '64px' : '32px'
      }}>
      <img
        style={{
          marginBottom: '48px'
        }}
        src={IconAssets.whiteLogo}
        alt=""
      />
      <ModalInput placeholder="Email address" />
      <SizedBox height={24} />
      <ModalInput placeholder="Password" />
      <button
        className="uppercase font-semibold"
        style={{
          marginTop: '40px',
          marginBottom: '24px',
          color: '#FCFCFC',
          fontSize: '14px',
          borderRadius: '100px',
          backgroundColor: '#9D59EF',
          width: screenSize.width > 765 ? '388px' : '85vw',
          padding: '16px'
        }}>
        LOGIN
      </button>
      <p onClick={nextAction} className="cursor-pointer underline font-semibold" style={{}}>
        Verification Login
      </p>
    </div>
  )
}

export default LoginModal
