import COLORS from 'constants/Colors'
import ActionButton from '../components/ActionButton'
import TypeCard from './TypeCard'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'

interface SelectTypeProps {
  onClick: any
}

const SelectType = ({ onClick }: SelectTypeProps) => {
  const { type } = useGetStarted()
  const updateProperty = useUpdateGetStarted()
  return (
    <div className="flex items-center gap-3 flex-col">
      <h3
        className="font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Select Your Role
      </h3>
      <p
        className="opacity-60"
        style={{
          fontSize: '14px',
          color: COLORS.white
        }}>
        Are you joining us as a practitioner or a teacher?
      </p>
      <div className="flex flex-col md:flex-row items-center gap-5">
        <TypeCard
          onClick={() => updateProperty('type', 'student')}
          active={type === 'student'}
          name="I am a practitioner"
          image={ImageAssets.science}
        />
        <TypeCard
          onClick={() => updateProperty('type', 'teacher')}
          active={type === 'teacher'}
          name="I am a teacher"
          image={ImageAssets.onboarding}
        />
      </div>
      <SizedBox height={20} />
      <ActionButton onClick={() => onClick(type)}>Continue</ActionButton>
      <SizedBox height={20} />
    </div>
  )
}

export default SelectType
