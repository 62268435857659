import IconAssets from 'assets/IconAssets'
import { useState } from 'react'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import SlideIndicator from './SlideIndicator'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'

const AboutSlideShow = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const slideImages = [
    {
      url: IconAssets.accessibility,
      caption: 'Slide 1'
    },
    {
      url: IconAssets.globalProgress,
      caption: 'Slide 2'
    },
    {
      url: IconAssets.supporting,
      caption: 'Slide 3'
    }
  ]
  return (
    <motion.div
      variants={slideUpNoDelay()}
      style={{
        width: '90vw',
        margin: '60px auto 0px auto'
      }}
      className="block md:hidden slide-container">
      <Slide
        transitionDuration={0.5}
        onChange={(_, to) => setCurrentIndex(to)}
        indicators={(number) => <SlideIndicator selected={number === currentIndex} />}
        arrows={false}
        autoplay={false}>
        {slideImages.map((slideImage, index) => (
          <div key={index} className="flex items-center justify-center">
            <img src={slideImage.url} alt="" />
          </div>
        ))}
      </Slide>
    </motion.div>
  )
}

export default AboutSlideShow
