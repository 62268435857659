export interface IndicatorProps {
  selected: boolean
}
const SlideIndicator = ({ selected }: IndicatorProps) => {
  return (
    <div>
      <div
        style={{
          backgroundColor: selected ? '#9D58EF' : '#FCFCFC',
          opacity: selected ? 1 : 0.7
        }}
        className="w-2 h-2 mx-1 rounded-full"
      />
    </div>
  )
}

export default SlideIndicator
