import { createContext, useContext, useRef, useState } from 'react'
import { CustomContextProps } from './GetStartedContextProvider'
import AudioAssets from 'assets/AudioAssets'

const MusicContext = createContext<any>(null)

export const useMusic = () => {
  return useContext(MusicContext)
}

const MusicContextProvider = ({ children }: CustomContextProps) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [playing, setPlaying] = useState(false)
  return (
    <>
      <audio
        autoPlay={false}
        loop
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        src={AudioAssets.background}
        ref={audioRef}></audio>
      <MusicContext.Provider value={{ audioRef, playing }}>{children}</MusicContext.Provider>
    </>
  )
}

export default MusicContextProvider
