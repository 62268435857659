import SizedBox from 'components/Divider/SizedBox'
import { ReactElement, ReactNode } from 'react'

interface SlideButtonProps {
  image: ReactElement
  children: ReactNode
  direction?: 'left' | 'right'
}
const ResearchSlideButton = ({ image, children, direction = 'right' }: SlideButtonProps) => {
  return (
    <div>
      {direction === 'right' ? (
        <div
          style={{
            border: '1px solid #FCFCFC4D',
            borderRadius: '100px',
            padding: '8px'
          }}
          className="cursor-pointer flex flex-row items-center">
          {image}
          <SizedBox width={'24px'} />
          <h4
            style={{
              fontSize: '14px'
            }}
            className="text-white font-bold uppercase">
            {children}
          </h4>
          <SizedBox width={24} />
        </div>
      ) : (
        <div
          style={{
            border: '1px solid #FCFCFC4D',
            borderRadius: '100px',
            padding: '8px'
          }}
          className="cursor-pointer flex flex-row items-center">
          <SizedBox width={24} />
          <h4
            style={{
              fontSize: '14px'
            }}
            className="text-white font-bold uppercase">
            {children}
          </h4>
          <SizedBox width={'24px'} />
          {image}
        </div>
      )}
    </div>
  )
}

export default ResearchSlideButton
