import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import Header from 'components/Header/Header'
import useResize from 'utils/useResize'
import ResearchSlideButton from './ResearchSlideButton'
import Stars from 'components/Background/Stars/Stars'

const ResearchHeroSection = () => {
  const screenSize = useResize()
  return (
    <div className="relative">
      <Stars />
      <Header />
      <SizedBox height={screenSize.width > 765 ? 70 : 70} />
      <div
        style={{
          zIndex: 3
        }}
        className="relative mx-auto px-5 md:px-0 container flex flex-col md:flex-row items-center justify-between">
        <div
          style={{
            width: screenSize.width > 765 ? '510px' : undefined
          }}
          className="flex flex-col items-center md:items-start">
          <h1
            style={{
              lineHeight: screenSize.width > 765 ? '78px' : '50px',
              fontSize: screenSize.width > 765 ? '78px' : '50px'
            }}
            className="text-center md:text-left text-white">
            Why research is important for meditation
          </h1>
          <SizedBox height={screenSize.width > 765 ? 40 : 24} />
          <p
            style={{
              color: '#FCFCFC',
              fontSize: screenSize.width > 765 ? '20px' : '14px',
              opacity: 0.8,
              lineHeight: '140%'
            }}
            className="text-center md:text-left w-9/12">
            Research can help practitioners better understand the mechanisms of meditation
          </p>
          <SizedBox height={screenSize.width > 765 ? 40 : 24} />
          <ResearchSlideButton
            image={
              <img
                style={{
                  width: '40px'
                }}
                src={IconAssets.slideIcon}
              />
            }>
            see recent research
          </ResearchSlideButton>
        </div>
        <SizedBox height={screenSize.width > 765 ? 0 : 54} />
        <img src={ImageAssets.research} alt="" />
      </div>
    </div>
  )
}

export default ResearchHeroSection
