import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'
import ActionButton from './components/ActionButton'
import WebInput from './components/WebInput'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import axios from 'axios'

interface ProvideEmailPageProps {
  next: () => void
}

const ProvideEmailPage = ({ next }: ProvideEmailPageProps) => {
  const navigate = useNavigate()
  const { email } = useGetStarted()
  const updateProperty = useUpdateGetStarted()

  const saveDataApiCall = async (
    onComplete: () => void = () => {
      toast('You have joined the waitlist')
      navigate('/')
    }
  ) => {
    axios
      .post(
        'https://metawisedojoapi.metawise.gg/api/v1/website-signup',
        {
          email
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(({ data }) => {
        if (!data.status) {
          toast.error(data.error)
        } else {
          onComplete()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const toNext = () => {
    if (!email) {
      toast.error('Please enter an email address')
      return
    }
    saveDataApiCall(next)
  }

  const saveEmailAndGoBack = async () => {
    if (!email) {
      toast.error('Please enter an email address')
      return
    }
    await saveDataApiCall()
  }

  return (
    <div className="flex self-center md:w-[400px] w-[90%] mb-10 md:mb-0 mx-auto items-center gap-3 flex-col">
      <h3
        className="font-semibold text-[#FCFCFC] text-[24px]"
        style={{
          fontSize: '24px'
        }}>
        Sign Up for Updates
      </h3>
      <div className="w-full mt-10">
        <WebInput
          setValue={(value: string) => updateProperty('email', value)}
          value={email}
          label="Email"
          placeholder=""
        />
        <div className="flex items-center gap-3">
          <ActionButton type="outline" onClick={saveEmailAndGoBack}>
            Join Waitlist
          </ActionButton>
          <ActionButton onClick={toNext}>Continue Onboarding</ActionButton>
        </div>
      </div>
      <p className="text-[#FCFCFC] text-sm mt-10 opacity-60">
        Continue onboarding to join the priority list
      </p>
    </div>
  )
}

export default ProvideEmailPage
