import IconAssets from 'assets/IconAssets'
import SizedBox from 'components/Divider/SizedBox'
import Floaty, { renderWords } from 'components/Floaty/Floaty'
import { motion } from 'framer-motion'
import { useState } from 'react'
import useResize from 'utils/useResize'

interface LotusMonkSectionProps {
  title: string
  description: string
  details: string
  image: string
  imageSize?: 'normal' | 'small'
}

const LotusMonkSection = ({
  title,
  description,
  details,
  image,
  imageSize = 'normal'
}: LotusMonkSectionProps) => {
  const screenSize = useResize()
  const [show, setShow] = useState(false)
  const variants = {
    show: { opacity: 1, transition: { duration: 0.1 } },
    hide: { opacity: 0, transition: { duration: 0.1 } }
  }
  return (
    <div
      style={{
        maxHeight: '900px'
      }}
      className={`container mx-auto px-5 md:px-0 flex flex-col ${
        imageSize === 'small' ? 'md:flex-row-reverse' : 'md:flex-row'
      } items-center`}>
      <motion.div
        style={{
          width: screenSize.width > 765 ? '290px' : undefined
        }}>
        <h2
          className="md:text-[48px] text-[24px]"
          style={{
            color: '#FCFCFC',
            lineHeight: '110%'
          }}>
          {title}
        </h2>
        <SizedBox height={screenSize.width > 756 ? 40 : 20} />
        <p
          className="md:text-[16px] text-[14px]"
          style={{
            color: '#FCFCFC',
            opacity: '0.8',
            lineHeight: '140%'
          }}>
          {description}
        </p>
      </motion.div>
      <motion.div
        className={`relative md:h-[1000px] md:w-[1000px] flex items-center ${
          imageSize === 'small' ? 'md:mr-[0px] lg:mr-[-60px] xl:mr-[-130px]' : 'md:ml-[-130px]'
        } justify-center`}>
        <img
          className={`max-h-full object-contain ${
            imageSize === 'small' ? 'w-[450px] md:w-[600px] lg:w-[700px]' : 'md:w-[1000px]'
          }`}
          src={image}
          alt=""
        />
        <div
          style={{
            top: imageSize === 'small' ? undefined : '30%',
            bottom: imageSize === 'small' ? '30%' : undefined,
            right: imageSize === 'small' ? undefined : '15%',
            left: imageSize === 'small' ? '15%' : undefined
          }}
          className="absolute">
          <Floaty
            direction="right"
            position={imageSize === 'small' ? 'top' : 'bottom'}
            onClick={() => setShow(!show)}
            size="small">
            <img src={IconAssets.plus} alt="" />
          </Floaty>
        </div>
        {show && (
          <motion.div
            onClick={() => setShow(false)}
            variants={variants}
            initial="hide"
            animate={show ? 'show' : 'hide'}
            className={`absolute top-[10%] w-full md:w-[290px] cursor-pointer ${
              imageSize === 'small'
                ? 'md:left-0 lg:left-[-10%] md:top-[25%]'
                : 'md:right-0 md:top-[42%]'
            }`}
            style={{
              boxShadow:
                'inset 0px 5.07px 13.0273px -8.37468px rgba(255, 255, 255, 0.5), inset 0px -19.0757px 15.82px -14.8883px rgba(121, 108, 145, 0.15), inset 0px 22.7977px 23.263px -11.1662px rgba(202, 172, 255, 0.2), inset 0px 0.93052px 4.18734px rgba(154, 146, 210, 0.3), inset 0px 0.23263px 9.3052px rgba(227, 222, 255, 0.2)',
              background: 'rgba(233, 207, 208, 0.01)',
              padding: '20px',
              backdropFilter: 'blur(32.7411px)',
              WebkitBackdropFilter: 'blur(32.7411px)',
              borderRadius: '12px'
            }}>
            {renderWords(details)}
          </motion.div>
        )}
      </motion.div>
    </div>
  )
}

export default LotusMonkSection
