import COLORS from 'constants/Colors'

interface WebTextAreaProps {
  label: string
  placeholder?: string
  value?: string
  setValue?: any
}

const WebTextArea = ({ label, placeholder, value, setValue }: WebTextAreaProps) => {
  return (
    <div className="w-full mb-5">
      <p
        className="text-xs mb-2 font-bold"
        style={{
          color: COLORS.white
        }}>
        {label}
      </p>
      <div
        className="w-full gap-2 flex items-center justify-center rounded-lg bg-[#413847]"
        style={{
          padding: '12px 16px 14px'
        }}>
        <textarea
          value={value}
          onChange={(inp) => setValue(inp.target.value)}
          placeholder={placeholder}
          className="bg-transparent flex-1 outline-none text-sm opacity-60"
          style={{
            height: '100px',
            color: COLORS.white
          }}
        />
      </div>
    </div>
  )
}

export default WebTextArea
