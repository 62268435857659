import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import useResize from 'utils/useResize'
import ScienceParagraph from './ScienceParagraph'
import { motion } from 'framer-motion'
import { zoomIn, zoomInNoDelay } from 'utils/motion'

const ScienceQuoteSection = () => {
  const screenSize = useResize()
  return (
    <div className="relative container mx-auto px-5 md:px-0">
      <StaggerWrapper>
        <motion.div
          variants={zoomInNoDelay()}
          style={{
            backgroundColor: '#2E2633',
            borderRadius: '12px',
            zIndex: 2
          }}
          className={`flex ${screenSize.width > 765 ? 'flex-row' : 'flex-col-reverse'} relative`}>
          <img
            className="absolute"
            src={IconAssets.quotes}
            style={{
              right: screenSize.width > 765 ? '20px' : undefined,
              left: screenSize.width > 765 ? undefined : '20px',
              top: '20px'
            }}
            alt=""
          />
          <div
            style={{
              padding: screenSize.width > 765 ? '48px' : '28px'
            }}
            className={`flex ${screenSize.width > 765 ? 'flex-col' : 'flex-row'} items-center`}>
            <img
              style={{
                width: screenSize.width > 765 ? '178px' : '90px',
                marginRight: screenSize.width > 765 ? undefined : '10px'
              }}
              src={ImageAssets.ml}
              alt=""
            />
            <div>
              <h4
                style={{
                  marginTop: '10px',
                  fontSize: '18px',
                  color: '#FCFCFC'
                }}>
                ML engineer at
              </h4>
              <p
                style={{
                  fontSize: '14px',
                  opacity: '0.8',
                  color: '#FCFCFC'
                }}>
                Metawise Dojo
              </p>
            </div>
          </div>
          <div
            className="hidden md:block"
            style={{
              height: '323px',
              margin: '0 30px',
              width: '1px',
              opacity: '0.3',
              backgroundColor: '#FCFCFC'
            }}
          />
          <div
            style={{
              marginTop: screenSize.width > 765 ? undefined : '45px',
              padding: screenSize.width > 765 ? '48px' : '28px'
            }}
            className="flex-1">
            <h2
              style={{
                width: screenSize.width > 765 ? '80%' : undefined,
                color: '#FCFCFC',
                fontSize: screenSize.width > 765 ? '32px' : '18px'
              }}>
              The discoveries of science provide insights that can inspire us and help to awaken in
              us profound epiphanies.
            </h2>
            <p
              style={{
                marginTop: '30px',
                color: '#FCFCFC',
                fontSize: screenSize.width > 765 ? '18px' : '14px',
                width: screenSize.width > 765 ? '30%' : undefined
              }}>
              Says Rafael Espericueta&apos;s,{'\n'}In Essays on Science and Spirituality.
            </p>
          </div>
        </motion.div>
      </StaggerWrapper>
      <SizedBox height={100} />
      <motion.div
        variants={zoomIn(0, 0.25)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 'some' }}
        className={`flex ${
          screenSize.width > 765 ? 'flex-row-reverse' : 'flex-col-reverse'
        } justify-between`}>
        <div
          style={{
            width: screenSize.width > 765 ? '24%' : undefined
          }}>
          <h2
            style={{
              lineHeight: '110%',
              marginBottom: '25px',
              color: '#FCFCFC',
              fontSize: '48px'
            }}>
            Benefits of Meditation
          </h2>
          <div>
            <ScienceParagraph>
              Research has shown that long-term meditators exhibit a permanently increased ratio of
              higher to lower frequencies in gamma wave activity, indicating a shift in their
              baseline oscillation that is higher than that of inexperienced subjects. When these
              practitioners engaged in active meditation, they produced significantly higher levels
              of gamma-band activity than had previously been recorded in healthy human subjects.
            </ScienceParagraph>
            <ScienceParagraph>
              This suggests not only the existence of an electrophysiological signature specific to
              advanced states of meditation, but also that consistent meditation practice can lead
              to permanent changes in brain activity.
            </ScienceParagraph>
          </div>
        </div>
        <img
          style={{
            marginTop: screenSize.width > 765 ? '-250px' : '-120px',
            width: screenSize.width > 765 ? '65%' : undefined
          }}
          src={ImageAssets.benefits}
          alt=""
        />
      </motion.div>
    </div>
  )
}

export default ScienceQuoteSection
