/* eslint-disable @typescript-eslint/no-unused-vars */
import { motion } from 'framer-motion'
import { fadeIn, staggerContainer } from 'utils/motion'

const StaggerWrapper = ({ children, delay = 0.25, className = '' }: any) => {
  return (
    <motion.section
      className={className}
      variants={staggerContainer(0.25, delay)}
      viewport={{ once: true, amount: 'some' }}
      initial="hidden"
      whileInView="show">
      {children}
    </motion.section>
  )
}

export default StaggerWrapper
