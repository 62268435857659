import FONTS from 'constants/Fonts'

const DashboardHeader = () => {
  return (
    <div
      style={{
        fontFamily: FONTS.bold
      }}
      className="flex border-b text-[20px] border-[#FCFCFC88] items-center justify-between p-2 w-full">
      <p className="w-3/12">Name</p>
      <p className="w-3/12">Email</p>
      <p className="w-3/12">Type</p>
      <p className="w-3/12">Sign On Date</p>
    </div>
  )
}

export default DashboardHeader
