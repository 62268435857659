import About from 'pages/About/About'
import 'react-toastify/dist/ReactToastify.css'
import ComingSoon from 'pages/ComingSoon/ComingSoon'
import Contact from 'pages/Contact/Contact'
import HomePage from 'pages/Home/Home'
import Onboarding from 'pages/Onboarding/Onboarding'
import PrivacyPolicy from 'pages/Privacy/PrivacyPolicy'
import Research from 'pages/Research/Research'
import RoadMap from 'pages/RoadMap/Roadmap'
import Science from 'pages/Science/Science'
import Terms from 'pages/Terms/Terms'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ScrollToTop from 'components/ScrollToTop'
import StudentOnboarding from 'pages/Onboarding/Student/StudentOnboarding'
import GetStarted from 'pages/GetStarted/GetStarted'
import { useEffect, useState } from 'react'
import moment from 'moment'
import GetStartedContextProvider from 'contexts/GetStartedContextProvider'
import MusicContextProvider from 'contexts/MusicContextProvider'
import { ToastContainer } from 'react-toastify'
import DashboardPage from 'pages/Dashboard/DashboardPage'

function App() {
  const [endTime] = useState(moment('01-06-2023 12:00:00', 'DD-MM-YYYY HH:mm').toDate())
  const timeDifference = moment().diff(moment(endTime)) / 1000

  useEffect(() => {
    window.onload = function () {
      try {
        window.scrollTo(0, 0)
      } catch (error) {
        console.log(error)
      }
      const root = document.getElementById('root')
      const loader = document.getElementById('metawise-preloader')
      const spinner = document.getElementById('lds-ellipsis')
      if (loader && spinner && root) {
        root.style.maxHeight = 'unset'
        root.style.overflow = 'unset'
        spinner.style.display = 'none'
        loader.style.animation = 'coming-soon 2s linear'
        setTimeout(() => {
          loader.style.display = 'none'
        }, 2000)
      }
    }
  }, [])

  return (
    <div>
      {timeDifference < 4 && <ComingSoon endTime={endTime} />}
      <BrowserRouter>
        <MusicContextProvider>
          <ScrollToTop endTime={endTime}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/roadmap" element={<RoadMap />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/research" element={<Research />} />
              <Route path="/science" element={<Science />} />
              <Route
                path="/get-started"
                element={
                  <GetStartedContextProvider>
                    <GetStarted />
                  </GetStartedContextProvider>
                }
              />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/onboarding/teacher" element={<Onboarding />} />
              <Route path="/onboarding/student" element={<StudentOnboarding />} />
              <Route path="/about" element={<About />} />
              <Route path="/xzdashboardzx" element={<DashboardPage />} />
            </Routes>
          </ScrollToTop>
        </MusicContextProvider>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default App
