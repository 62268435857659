// @ts-expect-error
import useResize from 'use-resizing'
interface ScreenSize {
  width: number
  height: number
}
export default () => {
  const screenSize: ScreenSize = useResize()
  return screenSize
}
