import COLORS from 'constants/Colors'
import { StudentPracticeProps } from './StudentPractice2'
import SizedBox from 'components/Divider/SizedBox'
import TeachingSection from '../components/TeachingSection'
import Divider from 'components/Divider/Divider'
import ActionButton from '../components/ActionButton'
import WebInput from '../components/WebInput'
import RadioItem from '../SelectType/RadioItem'
import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'
import { useEffect, useState } from 'react'

const StudentPractice3 = ({ back, next }: StudentPracticeProps) => {
  const [customGender, setCustomGender] = useState('')
  const { age, occupation, gender } = useGetStarted()
  const updateProperty = useUpdateGetStarted()
  const [errorMessage, setErrorMessage] = useState('')
  const checkInformation = () => {
    setErrorMessage('')
    if (!age || !occupation || (gender !== 'male' && gender !== 'female' && customGender === '')) {
      setErrorMessage('please enter all information')
      return false
    }
    return true
  }

  useEffect(() => {
    if (gender !== 'male' && gender !== 'female') {
      setCustomGender(gender)
    }
  }, [])

  const toNext = () => {
    if (!checkInformation()) {
      return
    }
    if (gender !== 'male' && gender !== 'female') {
      updateProperty('gender', customGender)
    }
    next()
  }
  return (
    <div className="flex mb-10 md:mb-0 items-center flex-col justify-center gap-3 w-[400px]">
      <h3
        className="text-center font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Tell Us More About You
      </h3>
      <p
        className="text-sm text-center opacity-60"
        style={{
          color: COLORS.white
        }}>
        To tailor your Metawise experience and help us understand your needs better, please fill out
        this questionnaire
      </p>
      <SizedBox height={10} />
      <div
        className="w-[90%] md:w-full mx-auto gap-2 flex flex-col items-start"
        style={{
          backgroundColor: '#2e2633',
          borderRadius: '10px',
          padding: '20px'
        }}>
        <TeachingSection number={3} />
        <h4
          className="font-semibold"
          style={{
            color: COLORS.white,
            fontSize: '16px'
          }}>
          Demographics
        </h4>
        <p
          className="opacity-60"
          style={{
            fontSize: '14px',
            color: COLORS.white
          }}>
          Please share some details about yourself
        </p>
        <Divider top={10} bottom={10} />
        <div className="w-full">
          <SizedBox height={10} />
          <WebInput
            value={age}
            setValue={(value: number) => updateProperty('age', value)}
            label="Age"
          />
          <WebInput
            value={occupation}
            setValue={(value: string) => updateProperty('occupation', value)}
            label="Occupation"
            placeholder="What is your occupation?"
          />
          <p
            className="text-xs mb-2 font-bold"
            style={{
              color: COLORS.white
            }}>
            Gender
          </p>
          <RadioItem
            onClick={() => updateProperty('gender', 'male')}
            label="Male"
            active={gender === 'male'}
          />
          <RadioItem
            onClick={() => updateProperty('gender', 'female')}
            label="Female"
            active={gender === 'female'}
          />
          <RadioItem
            onClick={() => updateProperty('gender', 'other')}
            label="Other"
            active={gender !== 'male' && gender !== ' ' && gender !== 'female'}
          />
          {gender !== 'male' && gender !== ' ' && gender !== 'female' && (
            <div
              className="w-full gap-2 flex items-center justify-center rounded-lg bg-[#413847]"
              style={{
                padding: '12px 16px 14px'
              }}>
              <input
                value={customGender}
                onChange={(inp) => setCustomGender(inp.target.value)}
                placeholder="Please specify"
                className="bg-transparent flex-1 outline-none text-sm opacity-60"
                style={{
                  color: COLORS.white
                }}
                type="text"
              />
            </div>
          )}
          {errorMessage !== '' ? (
            <p className="text-sm text-center text-red-400 mt-2 mb-2">{errorMessage}</p>
          ) : (
            <></>
          )}
        </div>
        <SizedBox height={40} />
        <div className="w-full flex items-center justify-stretch gap-3">
          <ActionButton type="outline" onClick={back}>
            Back
          </ActionButton>
          <ActionButton onClick={toNext}>Continue</ActionButton>
        </div>
      </div>
    </div>
  )
}

export default StudentPractice3
