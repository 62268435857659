/* eslint-disable @typescript-eslint/no-unused-vars */
import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import COLORS from 'constants/Colors'
import useResize from 'utils/useResize'

interface SlideItemProps {
  data: any
  hasNext: boolean
  hasBack: boolean
  slideRef: any
}
const SlideItem = ({ data, hasNext, hasBack, slideRef }: SlideItemProps) => {
  const screenSize = useResize()
  return (
    <div className="container px-5 md:px-0 mx-auto flex flex-col items-center md:flex-row gap-3 justify-between">
      <div
        style={{
          minHeight: screenSize.width > 765 ? '500px' : '200px'
        }}
        className="flex-1">
        <img src={data.image} alt="" />
      </div>
      <div className="flex-1">
        <h2
          className="font-semibold"
          style={{
            fontSize: '26px',
            color: COLORS.white
          }}>
          {data.title}
        </h2>
        <p
          style={{
            margin: '10px 0',
            opacity: 0.6,
            color: COLORS.white,
            fontSize: '16px'
          }}>
          {data.description}
        </p>
        <div className="flex flex-row items-center gap-2">
          <img
            onClick={() => {
              if (!hasBack) {
                return
              }
              slideRef.current.goBack()
            }}
            className="rotate-180 cursor-pointer"
            style={{
              opacity: hasBack ? 1 : 0.6
            }}
            src={IconAssets.rightArrow}
            alt=""
          />
          <img
            style={{
              opacity: hasNext ? 1 : 0.6
            }}
            className="cursor-pointer"
            onClick={() => {
              if (!hasNext) {
                return
              }
              slideRef.current.goNext()
            }}
            src={IconAssets.rightArrow}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default SlideItem
