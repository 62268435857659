import IconAssets from 'assets/IconAssets'
import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import DivItem from './DivItem'

const DividerSection = () => {
  return (
    <div className="px-5 md:px-0 container mx-auto">
      <Divider />
      <SizedBox height={40} />
      <div className="flex flex-col md:flex-row items-center justify-between">
        <DivItem icon={IconAssets.book}>
          Access to up-to-date research in a particular field
        </DivItem>
        <DivItem icon={IconAssets.flask}>
          Information on scientific discoveries and research
        </DivItem>
        <DivItem icon={IconAssets.graduation}>
          A useful resource for students, teachers and researchers
        </DivItem>
      </div>
      <SizedBox height={40} />
      <Divider />
    </div>
  )
}

export default DividerSection
