import SizedBox from 'components/Divider/SizedBox'
import ContactCategorySection from 'pages/Contact/Components/ContactCategorySection'
import Footer from 'components/Footer/Footer'
import PreAlphaSection from './PreAlphaSection'
import StudentOnboardingHeroSection from './StudentOnboardingHeroSection'
import ImageAssets from 'assets/ImageAssets'
import ScienceParagraph from 'pages/Science/Components/ScienceParagraph'
import StudentDetailSection from './StudentDetailSection'
import StudentListItem from './StudentListItem'
import { useEffect } from 'react'
import useResize from 'utils/useResize'

const StudentOnboarding = () => {
  const screenSize = useResize()
  useEffect(() => {
    document.title = 'Metawise | Practitioners'
  }, [])
  return (
    <div>
      <StudentOnboardingHeroSection />
      <div className="relative overflow-x-hidden md:overflow-x-auto">
        <div>
          <StudentDetailSection
            step={1}
            subtitle="Get to Know Metawise"
            image={ImageAssets.platform}>
            <ScienceParagraph>
              Before diving into the Metawise experience, familiarize yourself with our unique
              ecosystem:
            </ScienceParagraph>
            <StudentListItem
              title="Visit Our Website"
              subtitle="Navigate to metawise.gg to discover our offerings"
            />
            <SizedBox height={10} />
            <StudentListItem
              title="Alpha Testing Phase"
              subtitle="Understand that we are currently in an alpha testing phase. Your participation as an early adopter is instrumental in shaping the future of mental wellbeing technology"
            />
          </StudentDetailSection>
          <StudentDetailSection
            step={2}
            subtitle="Join the Metawise Community"
            image={ImageAssets.alphaStep1}>
            <ScienceParagraph>To begin your journey, sign up as an Alpha Tester:</ScienceParagraph>
            <StudentListItem
              title="Join"
              subtitle={`Click on "Join" to start the registration process`}
            />
            <SizedBox height={10} />
            <StudentListItem
              title="Fill in Your Details"
              subtitle="Provide the necessary information so we can know you better and offer a personalized experience"
            />
            <SizedBox height={10} />
            <StudentListItem
              title="Confirmation Email"
              subtitle="After signing up, you will receive a confirmation email. Follow the instructions in the email to verify your account.
              By signing up, you're automatically applying for our whitelist"
            />
          </StudentDetailSection>
          <StudentDetailSection
            step={3}
            subtitle="Purchase or Rent Your Neurosity Crown EEG"
            image={ImageAssets.rent}>
            <ScienceParagraph>
              {` The cornerstone of your journey and our research is the Neurosity Crown EEG device.
              After registration, you'll be directed to the purchase page. You have two options:`}
            </ScienceParagraph>
            <StudentListItem
              title="Purchase"
              subtitle="Buy the Crown for $999. Purchasing the Crown gives you top priority status on our whitelist"
            />
            <SizedBox height={10} />
            <StudentListItem
              title="Rent"
              subtitle="Rent the Crown for $99 per month with a one-time $50 installation fee. If you decide to purchase the Crown later on, you will receive a discount of up to three months of rental fees ($99/month), and priority status over those who have not purchased the Crown"
            />
          </StudentDetailSection>
          <StudentDetailSection
            step={4}
            subtitle="Embark on Your Metawise Journey"
            image={ImageAssets.dojo}>
            <ScienceParagraph>
              After acquiring your Crown, you are all set to embark on your Metawise journey:
            </ScienceParagraph>
            <StudentListItem
              title="Private Access to Our Platform"
              subtitle="As an Alpha Tester, you'll receive a private invitation 
              link to our platform."
            />
            <SizedBox height={10} />
            <StudentListItem
              title="Connect Your Crown"
              subtitle="Link your EEG headset to the Metawise platform"
            />
            <SizedBox height={10} />
            <StudentListItem
              title="Join Our Alpha Testers"
              subtitle="Participate in our alpha testing phase by contributing your EEG data. Your data is not only vital to our research but also helps in fine-tuning our features and developing our cutting-edge meditative state detection algorithm"
            />
          </StudentDetailSection>
          <StudentDetailSection
            step={5}
            subtitle="Earn Testnet Tokens"
            image={ImageAssets.tokenStep}>
            <ScienceParagraph>
              While we continue building our platform and testing its functionality, you will be
              rewarded with testnet tokens for your EEG data contributions during the alpha testing
              phase
            </ScienceParagraph>
          </StudentDetailSection>
          <StudentDetailSection
            step={6}
            subtitle="Explore Metawise Services"
            image={ImageAssets.metalabs}>
            <ScienceParagraph>
              {`As an Alpha Tester, you'll gain exclusive access to a wealth of resources to enhance
              your meditation journey:`}
            </ScienceParagraph>
            <StudentListItem
              title="Metawise Dojo"
              subtitle="Navigate to metawise.gg to discover our offerings"
            />
            <SizedBox height={10} />
            <StudentListItem title="Metawise Labs" subtitle="Join our cutting-edge research" />
            <SizedBox height={10} />
            <StudentListItem
              title="Metawise University"
              subtitle="Engage with teachers and students"
            />
            <SizedBox height={10} />
            <StudentListItem
              title="Metawise Community"
              subtitle="Become a part of our conscious social media platform"
            />
          </StudentDetailSection>
          <StudentDetailSection
            step={7}
            subtitle="Alpha Participation Incentives"
            image={ImageAssets.platform}>
            <ScienceParagraph>
              By participating in the alpha testing phase, you will be rewarded with:
            </ScienceParagraph>
            <StudentListItem
              title="Exclusive AI-Generated Art NFT"
              subtitle="Receive a one-of-a-kind piece of art generated from your unique brainwaves"
            />
            <SizedBox height={10} />
            <StudentListItem
              title="«Alpha Practitioner» Badge"
              subtitle="Be recognized with a non-transferable NFT badge showcasing your early involvement in the platform"
            />
            <SizedBox height={10} />
            <StudentListItem
              title="Whitelist Priority"
              subtitle="Get priority access for our upcoming 
              Public Launch/NFT sale"
            />
          </StudentDetailSection>
          <StudentDetailSection
            step={8}
            subtitle="Refer and Get Prioritized"
            image={ImageAssets.referralStep}>
            <ScienceParagraph>
              Encourage friends and family to participate in our study. The more people you recruit,
              the higher your priority on our whitelist. Remember, every new recruit brings us one
              step closer to revolutionizing mental wellbeing technology.
            </ScienceParagraph>
          </StudentDetailSection>
        </div>
        <SizedBox height={screenSize.width > 765 ? 140 : 0} />
        <PreAlphaSection />
        <ContactCategorySection mCategory="learners" />
        <SizedBox height={100} />
        <Footer />
      </div>
    </div>
  )
}

export default StudentOnboarding
