import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import { ReactNode } from 'react'

interface TeamMemberDetailProps {
  name: string
  title: string
  image: string
  detailHeader: string
  children: ReactNode
  close: any
}

const TeamMemberDetail = ({
  name,
  title,
  image,
  detailHeader,
  children,
  close
}: TeamMemberDetailProps) => {
  return (
    <div
      style={{
        zIndex: 20
      }}
      className="p-[10px] md:bg-[#1D1521] bg-[#2e2633] fixed md:overflow-y-clip overflow-y-scroll flex flex-col md:flex-row items-center justify-between w-screen top-0 left-0 bottom-0">
      <div>
        <div className="relative md:m-[160px] mt-10 md:mt-0 flex flex-col-reverse md:flex-col items-start ">
          <img
            onClick={close}
            src={IconAssets.x}
            className="cursor-pointer md:hidden fixed top-5 right-5"
            alt=""
          />
          <img
            className="hidden md:block cursor-pointer absolute"
            src={ImageAssets.teamClose}
            onClick={close}
            style={{
              right: '-16px',
              top: '70px',
              width: '32px'
            }}
            alt=""
          />
          <div className="rounded-lg md:mt-10 bg-[#75547C] w-[300px] flex items-end justify-end md:w-[20vw] md:h-[401px] overflow-hidden">
            <img className="max-h-full" src={image} alt="" />
          </div>
          <SizedBox height={10} />
          <div>
            <h4
              className="font-semibold"
              style={{
                color: COLORS.white
              }}>
              {name}
            </h4>
            <p
              className="text-sm opacity-60"
              style={{
                color: COLORS.white
              }}>
              {title}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{ minHeight: '100%', maxHeight: '100vh', backgroundColor: 'rgba(46, 38, 51, 1)' }}
        className="md:w-5/12 md:overflow-y-scroll md:p-[60px] p-[30px]">
        <h3
          style={{
            color: COLORS.white,
            fontSize: '24px'
          }}>
          {detailHeader}
        </h3>
        <SizedBox height={20} />
        <div>{children}</div>
      </div>
    </div>
  )
}

export default TeamMemberDetail
