import useResize from 'utils/useResize'

interface PhaseItemProps {
  title?: string
  phases: any
  description?: string | undefined
}
const PhaseItem = ({ title = '', phases, description }: PhaseItemProps) => {
  const screenSize = useResize()
  return (
    <div className="py-10 flex flex-col md:flex-row justify-between">
      <p
        style={{
          width: screenSize.width > 765 ? '40%' : undefined,
          color: '#FCFCFC',
          fontSize: screenSize.width > 765 ? '14px' : '18px',
          marginBottom: screenSize.width > 765 ? '0px' : '14px'
        }}>
        {title}
      </p>
      <div className="flex-1">
        {description && (
          <p
            style={{
              color: '#FCFCFC',
              fontSize: '16px',
              width: screenSize.width > 765 ? '40%' : undefined,
              opacity: '0.7',
              marginBottom: screenSize.width > 765 ? '10px' : '14px'
            }}>
            {description}
          </p>
        )}

        {phases.map((item: any) => {
          return (
            <div
              key={item.description}
              className="py-2 flex flex-row md:items-center justify-between">
              <div
                style={{
                  width: screenSize.width > 765 ? '40%' : '70%'
                }}
                className="flex flex-row mr-5 md:mr-0">
                <div>
                  <div
                    style={{
                      marginTop: '8px',
                      width: '5px',
                      height: '5px',
                      borderRadius: '2.5px',
                      backgroundColor: '#FCFCFC',
                      marginRight: '10px',
                      opacity: 0.7
                    }}
                  />
                </div>

                <p
                  style={{
                    color: '#FCFCFC',
                    opacity: 0.7,
                    fontSize: '14px'
                  }}>
                  {item.description}
                </p>
              </div>

              <p
                className="flex-1 text-end uppercase"
                style={{
                  color: item.status === 'completed' ? '#3CBC81' : '#E7A23B',
                  fontSize: '14px'
                }}>
                {item.status}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PhaseItem
