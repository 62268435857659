import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import ActionButton from '../components/ActionButton'
import TeachingSection from '../components/TeachingSection'
import Divider from 'components/Divider/Divider'
import { TeachingPracticeProps } from './TeachingPractice3'
import WebInput from '../components/WebInput'
import WebTextArea from '../components/WebTextArea'
import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'
import { useState } from 'react'

const TeachingPractice1 = ({ back, next }: TeachingPracticeProps) => {
  const {
    teachingExperience,
    teachingExpertise,
    teachingPhilosophy,
    teachingFormat,
    preferredGroup,
    schoolsTaught
  } = useGetStarted()
  const updateProperty = useUpdateGetStarted()
  const [errorMessage, setErrorMessage] = useState('')
  const checkInformation = () => {
    setErrorMessage('')
    if (
      !teachingExperience ||
      !teachingExpertise ||
      !teachingPhilosophy ||
      !teachingFormat ||
      !preferredGroup
    ) {
      setErrorMessage('please enter all information')
      return false
    }
    return true
  }

  const toNext = () => {
    if (!checkInformation()) {
      return
    }
    next()
  }

  return (
    <div className="flex mb-10 md:mb-0 items-center flex-col justify-center gap-3 w-[400px]">
      <h3
        className="text-center font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Tell Us More About Your Teaching Practice
      </h3>
      <SizedBox height={10} />
      <div
        className="w-[90%] md:w-full mx-auto gap-2 flex flex-col items-start"
        style={{
          backgroundColor: '#2e2633',
          borderRadius: '10px',
          padding: '20px'
        }}>
        <TeachingSection number={1} />
        <h4
          className="font-semibold"
          style={{
            color: COLORS.white,
            fontSize: '16px'
          }}>
          Your Teaching Practice
        </h4>
        <p
          className="opacity-60"
          style={{
            fontSize: '14px',
            color: COLORS.white
          }}>
          Tell us more about your meditation teaching practice
        </p>
        <Divider top={10} bottom={10} />
        <div className="w-full">
          <WebInput
            setValue={(value: string) => updateProperty('teachingExperience', value)}
            value={teachingExperience}
            label="How many years of teaching experience do you have?"
            placeholder="How many years?"
          />
          <WebInput
            setValue={(value: string) => updateProperty('teachingExpertise', value)}
            value={teachingExpertise}
            label="What are your areas of expertise in teaching?"
            placeholder="Please specify"
          />
          <WebTextArea
            setValue={(value: string) => updateProperty('teachingPhilosophy', value)}
            value={teachingPhilosophy}
            label="What is your teaching philosophy?"
            placeholder="Write about your philosophy"
          />
          <WebInput
            setValue={(value: string) => updateProperty('teachingFormat', value)}
            value={teachingFormat}
            label="What is your preferred teaching format?"
            placeholder="Please specify"
          />
          <WebInput
            setValue={(value: string) => updateProperty('schoolsTaught', value)}
            value={schoolsTaught}
            label="What are the main mystical traditions or schools of thought that influence your teaching?"
            placeholder="Write about mystical traditions"
          />
          <WebInput
            setValue={(value: string) => updateProperty('preferredGroup', value)}
            value={preferredGroup}
            label="Are there any specific groups you have experience teaching or prefer to teach?"
            placeholder="Please specify"
          />
          {errorMessage !== '' ? (
            <p className="text-sm text-center text-red-400 mt-2 mb-2">{errorMessage}</p>
          ) : (
            <></>
          )}
        </div>
        <SizedBox height={20} />
        <div className="w-full flex items-center justify-stretch gap-3">
          <ActionButton type="outline" onClick={back}>
            Back
          </ActionButton>
          <ActionButton onClick={toNext}>Continue</ActionButton>
        </div>
      </div>
    </div>
  )
}

export default TeachingPractice1
