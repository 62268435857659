// import React from 'react'
import CategoryItem from 'pages/Contact/Components/CategoryItem'
import { useState } from 'react'
import useResize from 'utils/useResize'
import CardItem from './CardItem'

const ResearchCategorySection = () => {
  const sections = [
    'see all',
    'unreleased',
    'data analysis',
    'machine learning',
    'ai',
    'cybersecurity',
    'eeg',
    'meditation',
    'blockchain'
  ]

  const [active, setActive] = useState(sections[0])
  const screenSize = useResize()

  return (
    <div className="px-5 md:px-0 container mx-auto">
      <div className="flex items-center justify-center">
        <p
          style={{
            fontSize: screenSize.width > 765 ? '32px' : '18px',
            color: '#FCFCFC',
            width: screenSize.width > 765 ? '618px' : undefined
          }}
          className="text-center mb-10">
          Discover new opportunities through research based on cutting-edge technology
        </p>
      </div>
      <div className="flex md:w-9/12 mx-auto flex-wrap justify-center flex-row items-center">
        {sections.map((item) => (
          <CategoryItem key={item} onClick={() => setActive(item)} active={active === item}>
            {item}
          </CategoryItem>
        ))}
      </div>
      <div
        style={{
          marginTop: 20
        }}
        className="flex flex-row items-center flex-wrap justify-between">
        <a
          href="https://www.frontiersin.org/articles/10.3389/fnsys.2020.00053/full"
          target="_blank"
          rel="noopener noreferrer">
          <CardItem
            name="A Critical Analysis on Characterizing the Meditation Experience Through the Electroencephalogram"
            authors="Deolindo, C. S., Ribeiro, M. A., et al."
            year={2020}
          />
        </a>

        <a
          href="https://www.ruhr-uni-bochum.de/neuropsy/publikation/axmacher/2010_Fell_MedHypotheses.pdf"
          target="_blank"
          rel="noopener noreferrer">
          <CardItem
            name="From alpha to gamma: Electrophysiological correlates of meditation-related states of consciousness"
            authors="Juergen Fell, Nikolai Axmacher, Sven Haupt"
            year={2010}
          />
        </a>

        <a
          href="https://www.sciencedirect.com/science/article/abs/pii/S0301051118301728?via%3Dihub"
          target="_blank"
          rel="noopener noreferrer">
          <CardItem
            name="Dissociating meditation proficiency and experience dependent EEG changes during traditional Vipassana meditation practice"
            authors="Kakumanu, Ratna Jyothi et al."
            year={2018}
          />
        </a>

        <a
          href="https://www.pnas.org/doi/10.1073/pnas.0407401101?url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org&rfr_dat=cr_pub++0pubmed"
          target="_blank"
          rel="noopener noreferrer">
          <CardItem
            name="Long-term meditators self-induce high-amplitude gamma synchrony during mental practice"
            authors="Lutz et al."
            year={2004}
          />
        </a>

        <a
          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4751480/"
          target="_blank"
          rel="noopener noreferrer">
          <CardItem
            name="Activation and Connectivity within the Default Mode Network Contribute Independently to Future-Oriented Thought"
            authors="Zu et al."
            year={2016}
          />
        </a>

        <a
          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4529365/"
          target="_blank"
          rel="noopener noreferrer">
          <CardItem
            name="Meditation leads to reduced default mode network activity beyond an active task"
            authors="Garrison et al"
            year={2015}
          />
        </a>
      </div>
    </div>
  )
}

export default ResearchCategorySection
