import IconAssets from 'assets/IconAssets'
import COLORS from 'constants/Colors'
import { useState } from 'react'

interface WebInputProps {
  label: string
  placeholder?: string
  isPassword?: boolean
  value?: string | number
  setValue?: any
}

const WebInput = ({
  label,
  placeholder = '',
  isPassword = false,
  value,
  setValue
}: WebInputProps) => {
  const [password, setPassword] = useState(isPassword)
  return (
    <div className="w-full mb-5">
      <p
        className="text-xs mb-2 font-bold"
        style={{
          color: COLORS.white
        }}>
        {label}
      </p>
      <div
        className="w-full gap-2 flex items-center justify-center rounded-lg bg-[#413847]"
        style={{
          padding: '12px 16px 14px'
        }}>
        <input
          value={value}
          onChange={(inp) => setValue(inp.target.value)}
          placeholder={placeholder}
          className="bg-transparent flex-1 outline-none text-sm opacity-60"
          style={{
            color: COLORS.white
          }}
          type={`${password ? 'password' : 'text'}`}
        />
        {isPassword && (
          <img
            onClick={() => setPassword(!password)}
            className="cursor-pointer"
            src={IconAssets.eyeClosed}
            alt=""
          />
        )}
      </div>
    </div>
  )
}

export default WebInput
