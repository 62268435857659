import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'
import TeachingSection from '../components/TeachingSection'
import Divider from 'components/Divider/Divider'
import ActionButton from '../components/ActionButton'
import { StudentPracticeProps } from './StudentPractice2'
import StudentReason from './components/StudentReason'
import { useGetStarted, useUpdateGetStarted } from 'contexts/GetStartedContextProvider'
import { useState } from 'react'

const DATA = [
  'Increased focus',
  'Reduce stress',
  'Better sleep patterns',
  'Calm anxiety',
  'Cultivate gratitude',
  'Practice mindfulness',
  'Start a meditation habit',
  'Increase happiness',
  'Relaxation techniques',
  'Exploration of soothing sounds',
  'Personal growth',
  'Spiritual and physical healing',
  'Advance the science of spirituality',
  'Manage anger effectively',
  'Incorporate yoga & movement into routine',
  'Boost energy levels',
  'Unlock your full potential',
  'Heal the world through conscious actions',
  'Decentralized universal basic income',
  'Earn tokens & blockchain technology',
  'Participate in a social media community',
  'EEG for personalized neurofeedback',
  'Machine learning for meditative state',
  'Interact with an AI assistant',
  'Learn from esteemed teachers',
  'Contribute to brainwave data research',
  'Learn to lucid dream',
  'Transcend spiritual plateaus'
]

const StudentPractice1 = ({ back, next }: StudentPracticeProps) => {
  const { reasons } = useGetStarted()
  const updateProperty = useUpdateGetStarted()
  const [errorMessage, setErrorMessage] = useState('')
  const checkInformation = () => {
    setErrorMessage('')
    if (reasons.length < 6) {
      setErrorMessage('please select at least 6')
      return false
    }
    return true
  }

  const toNext = () => {
    if (!checkInformation()) {
      return
    }
    next()
  }
  const checkActive = (item: string) => {
    const res = reasons.find((value) => item === value)
    if (res) {
      return true
    }
    return false
  }

  const addRemoveReason = (reason: string) => {
    const res = reasons.findIndex((item) => item === reason)
    const newReasons = [...reasons]
    if (res >= 0) {
      // remove
      newReasons.splice(res, 1)
      updateProperty('reasons', newReasons)
    } else {
      // add
      newReasons.push(reason)
      updateProperty('reasons', newReasons)
    }
  }

  return (
    <div className="flex mb-10 md:mb-0 items-center flex-col justify-center gap-3 w-[1200px]">
      <h3
        className="text-center font-semibold"
        style={{
          color: COLORS.white,
          fontSize: '24px'
        }}>
        Tell Us More About You
      </h3>
      <p
        className="text-sm text-center opacity-60"
        style={{
          color: COLORS.white
        }}>
        To tailor your Metawise experience and help us understand your needs better, please fill out
        this questionnaire
      </p>
      <SizedBox height={10} />
      <div
        className="w-[90%] md:w-full mx-auto gap-2 flex flex-col items-start"
        style={{
          backgroundColor: '#2e2633',
          borderRadius: '10px',
          padding: '20px'
        }}>
        <div className="self-center">
          <TeachingSection number={1} />
        </div>
        <div className="flex self-center items-center gap-2">
          <h4
            className="font-semibold"
            style={{
              color: COLORS.white,
              fontSize: '16px'
            }}>
            What are you seeking from your Metawise journey?
          </h4>
          <p
            className="opacity-60"
            style={{
              fontSize: '14px',
              color: COLORS.white
            }}>
            (Choose at least 6)
          </p>
        </div>
        <Divider top={10} bottom={10} />
        <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
          {DATA.map((item) => (
            <StudentReason
              onClick={addRemoveReason}
              active={checkActive(item)}
              label={item}
              key={item}
            />
          ))}
        </div>
        {errorMessage !== '' ? (
          <p className="text-sm self-center text-center text-red-400 mt-2 mb-2">{errorMessage}</p>
        ) : (
          <></>
        )}
        <SizedBox height={40} />
        <div className="flex w-full md:w-[45%] items-center self-center justify-stretch gap-3">
          <ActionButton type="outline" onClick={back}>
            Back
          </ActionButton>
          <ActionButton onClick={toNext}>Continue</ActionButton>
        </div>
      </div>
    </div>
  )
}

export default StudentPractice1
