import ImageAssets from 'assets/ImageAssets'
import IconAssets from 'assets/IconAssets'
import SizedBox from 'components/Divider/SizedBox'
import useResize from 'utils/useResize'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import { motion } from 'framer-motion'
import { slideInNoDelay } from 'utils/motion'

const QuotesSection = () => {
  const screenSize = useResize()
  return (
    <StaggerWrapper>
      <div
        className={`flex mx-auto ${
          screenSize.width > 765 ? 'flex-row' : 'flex-col'
        } items-center justify-between container px-5 md:px-0`}>
        <motion.div
          variants={slideInNoDelay('left')}
          className="relative"
          style={{
            width: screenSize.width > 765 ? '730px' : undefined,
            borderRadius: '12px',
            backgroundColor: '#2E2633',
            padding: '48px'
          }}>
          <img
            className="absolute"
            src={IconAssets.quotes}
            style={{
              top: '20px',
              right: '20px'
            }}
            alt=""
          />
          <p
            style={{
              color: '#FCFCFC',
              fontSize: screenSize.width > 765 ? '32px' : '22px',
              lineHeight: screenSize.width > 765 ? '44px' : undefined
            }}>
            The roots of all goodness lie in the soil of appreciation for goodness
          </p>
          <div
            style={{
              marginTop: '32px'
            }}
            className="flex flex-row items-center">
            <img src={ImageAssets.dalai} alt="" />
            <SizedBox width={10} />
            <div>
              <p
                style={{
                  color: '#FCFCFC',
                  fontSize: '18px'
                }}>
                Dalai Lama
              </p>
              <p
                style={{
                  color: '#FCFCFC',
                  fontSize: '16px',
                  opacity: '0.7'
                }}>
                Religious Leader
              </p>
            </div>
          </div>
        </motion.div>
        <motion.div
          variants={slideInNoDelay('left')}
          style={{
            marginTop: screenSize.width > 765 ? undefined : '40px',
            width: screenSize.width > 765 ? '510px' : undefined,
            backgroundColor: 'transparent',
            border: '1px solid rgba(252, 252, 252, 0.3)',
            borderRadius: '12px',
            padding: '48px'
          }}>
          <p
            style={{
              color: '#FCFCFC',
              fontSize: '20px'
            }}>
            We appreciate the goodness that you bring to the world, and we&apos;re committed to
            helping you share it with as many people as possible.
          </p>
          <div
            style={{
              marginTop: '32px'
            }}
            className="flex flex-row items-center">
            <img
              style={{
                width: '64px',
                height: '64px',
                objectFit: 'contain',
                borderRadius: '50%',
                backgroundColor: '#413847'
              }}
              src={ImageAssets.logo}
              alt=""
            />
            <SizedBox width={10} />
            <div>
              <p
                style={{
                  color: '#FCFCFC',
                  fontSize: '18px'
                }}>
                Metawise
              </p>
              <p
                style={{
                  color: '#FCFCFC',
                  fontSize: '16px',
                  opacity: '0.7'
                }}>
                Team
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </StaggerWrapper>
  )
}

export default QuotesSection
