import useResize from 'utils/useResize'
import { motion } from 'framer-motion'
import { slideInNoDelay } from 'utils/motion'

const ContactForm = () => {
  const screenSize = useResize()
  return (
    <motion.div
      variants={slideInNoDelay('right')}
      className="bg-white"
      style={{
        width: screenSize.width > 765 ? '620px' : undefined,
        backgroundColor: '#FFF',
        padding: screenSize.width > 765 ? '48px' : '24px',
        borderRadius: '20px'
      }}>
      <input
        placeholder="Your full name"
        className="outline-none w-full"
        style={{
          borderRadius: '100px',
          border: '1px solid #231E2F80',
          padding: '12px 14px'
        }}
        type="text"
      />
      <input
        placeholder="Email address"
        className="outline-none w-full"
        style={{
          marginTop: screenSize.width > 765 ? '24px' : '12px',
          borderRadius: '100px',
          border: '1px solid #231E2F80',
          padding: '12px 14px'
        }}
        type="email"
      />
      <input
        placeholder="Email address"
        className="outline-none w-full"
        style={{
          marginTop: screenSize.width > 765 ? '24px' : '12px',

          borderRadius: '100px',
          border: '1px solid #231E2F80',
          padding: '12px 14px'
        }}
        type="email"
      />
      <textarea
        placeholder="Write us something"
        className="outline-none w-full"
        style={{
          height: '200px',
          marginTop: screenSize.width > 765 ? '24px' : '12px',
          borderRadius: '20px',
          border: '1px solid #231E2F80',
          padding: '14px'
        }}
      />
      <button
        className="w-full font-semibold uppercase"
        style={{
          marginTop: screenSize.width > 765 ? '24px' : '12px',
          padding: '20px',
          borderRadius: '100px',
          fontSize: '14px',
          color: '#FCFCFC',
          backgroundColor: '#9D59EF'
        }}>
        Submit
      </button>
      <p
        className="text-center"
        style={{
          marginTop: screenSize.width > 765 ? '24px' : '12px',
          color: 'rgba(35, 30, 47, 0.8)'
        }}>
        This site is protected by reCAPTCHA and the Google{' '}
        <a
          href="/privacy-policy"
          style={{
            color: '#9D59EF'
          }}>
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href="/terms"
          style={{
            color: '#9D59EF'
          }}>
          Terms of Service
        </a>{' '}
        apply.
      </p>
    </motion.div>
  )
}

export default ContactForm
