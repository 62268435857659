import IconAssets from 'assets/IconAssets'
import ImageAssets from 'assets/ImageAssets'
import Stars from 'components/Background/Stars/Stars'
import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import Header from 'components/Header/Header'
import { motion } from 'framer-motion'
import SlideButton from 'pages/Home/Components/SlideButton'
import { useNavigate } from 'react-router'
import { slideIn, slideUp, slideUpNoDelay, staggerContainer } from 'utils/motion'
import useResize from 'utils/useResize'
const StudentOnboardingHeroSection = () => {
  const navigate = useNavigate()
  const screenSize = useResize()
  return (
    <motion.div className="relative">
      <Stars />
      <Header />
      <div className="relative pt-[80px] z-[5]">
        <div className="relative mx-auto px-5 md:px-0 container flex flex-col md:flex-row items-center justify-between">
          <motion.div
            className="flex flex-col items-center md:items-start"
            style={{
              zIndex: 3,
              width: screenSize.width > 765 ? '600px' : undefined
            }}>
            <motion.h1
              variants={staggerContainer()}
              viewport={{ once: true }}
              initial="hidden"
              whileInView="show"
              style={{
                fontSize: screenSize.width > 765 ? '78px' : '50px',
                lineHeight: screenSize.width > 765 ? '78px' : '50px',
                width: screenSize.width > 765 ? '510px' : undefined
              }}
              className="text-center md:text-left text-white">
              Practitioner Onboarding
            </motion.h1>
            <SizedBox height={screenSize.width > 765 ? 40 : 24} />
            <motion.p
              variants={slideUp(screenSize.width > 765 ? 0.5 : 0, 0.25)}
              style={{
                color: '#FCFCFC',
                fontSize: screenSize.width > 765 ? '20px' : '14px',
                opacity: 0.8,
                lineHeight: '140%'
              }}
              className="text-center md:text-left w-9/12">
              Welcome to Metawise! Embark on a revolutionary journey into mental wellbeing, where
              technology meets spirituality. We invite you to join us in pioneering a new frontier
              in personal growth and self-discovery
            </motion.p>
            <SizedBox height={screenSize.width > 765 ? 40 : 24} />
            <SlideButton
              variant={slideUpNoDelay()}
              href="https://dojo.metawise.gg"
              image={
                <img
                  style={{
                    width: '40px'
                  }}
                  src={IconAssets.discord}
                />
              }>
              BEGIN YOUR JOURNEY
            </SlideButton>
          </motion.div>
          <SizedBox height={screenSize.width > 765 ? 0 : 54} />
          <motion.div
            viewport={{ once: true }}
            initial="hidden"
            whileInView="show"
            variants={slideIn('right', 'tween', 0.75, 0.25)}
            className="relative">
            <motion.img
              animate={{
                top: [
                  screenSize.width > 765 ? '250px' : '150px',
                  screenSize.width > 765 ? '220px' : '140px',
                  screenSize.width > 765 ? '250px' : '150px'
                ]
              }}
              transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
              style={{
                right: '5px',
                top: screenSize.width > 765 ? '250px' : '150px'
              }}
              className="absolute"
              src={IconAssets.collective}
              alt=""
            />
            <motion.img
              animate={{
                bottom: ['40px', '50px', '40px']
              }}
              transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
              style={{
                left: '5px',
                bottom: '40px'
              }}
              className="absolute"
              src={IconAssets.track}
              alt=""
            />
            <img src={ImageAssets.teacheronboarding} alt="" />
          </motion.div>
        </div>
      </div>
      <div className="container mx-auto px-5 md:px-0">
        <SizedBox height={80} />
        <Divider />
        <SizedBox height={80} />
      </div>
    </motion.div>
  )
}

export default StudentOnboardingHeroSection
