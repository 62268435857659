import COLORS from 'constants/Colors'
import { Slide } from 'react-slideshow-image'
import SlideItem from './SlideItem'
import { useRef, useState } from 'react'

import SERVICES from '../data/services'
import SlideIndicator from './SlideIndicator'
import useResize from 'utils/useResize'
const ServicesSlide = () => {
  const slideRef = useRef<any>()
  const [currentIndex, setCurrentIndex] = useState(0)
  const screenSize = useResize()
  return (
    <div className="relative mb-[100px] mt-[100px] container mx-auto">
      <p
        className="text-center"
        style={{
          fontSize: screenSize.width > 765 ? '48px' : '32px',
          color: COLORS.white
        }}>
        Mindful Modules
      </p>
      <Slide
        transitionDuration={500}
        ref={slideRef}
        onChange={(_, to) => setCurrentIndex(to)}
        indicators={(number) => <SlideIndicator selected={number === currentIndex} />}
        arrows={false}
        autoplay={false}>
        {SERVICES.map((data, index) => (
          <div key={index} className="flex items-center justify-center">
            <SlideItem
              hasBack={index !== 0}
              slideRef={slideRef}
              hasNext={index !== SERVICES.length - 1}
              data={data}
            />
          </div>
        ))}
      </Slide>
    </div>
  )
}

export default ServicesSlide
