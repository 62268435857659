import { useState } from 'react'
import { motion } from 'framer-motion'
import IconAssets from 'assets/IconAssets'
import COLORS from 'constants/Colors'
import SizedBox from 'components/Divider/SizedBox'
import useResize from 'utils/useResize'

interface WaveFloatyProps {
  bottom?: string
  top?: string
  left?: string
  right?: string
  earnedBy: string
  utilities: string[]
  tokenName: string
}

const WaveFloaty = ({
  bottom = undefined,
  top = undefined,
  left = undefined,
  right = undefined,
  tokenName,
  earnedBy,
  utilities
}: WaveFloatyProps) => {
  const [show, setShow] = useState(false)
  const screenSize = useResize()
  const variants = {
    show: { opacity: 1, transition: { duration: 0.25 } },
    hide: { opacity: 0, transition: { duration: 0.25 } }
  }
  return (
    <div
      style={{
        zIndex: 5,
        bottom,
        top,
        left,
        right
      }}
      className={`absolute flex flex-col ${right !== undefined ? 'items-end' : 'items-start'}`}>
      <div
        style={{
          boxShadow:
            'inset 0px 5.07px 13.0273px -8.37468px rgba(255, 255, 255, 0.5), inset 0px 1.62841px 1px -0.93052px #FFFFFF, inset 0px -19.0757px 15.82px -14.8883px rgba(121, 108, 145, 0.15), inset 0px 22.7977px 23.263px -11.1662px rgba(202, 172, 255, 0.2), inset 0px 0.93052px 4.18734px rgba(154, 146, 210, 0.3), inset 0px 0.23263px 9.3052px rgba(227, 222, 255, 0.2)',
          backdropFilter: 'blur(32.7411px)',
          WebkitBackdropFilter: 'blur(32.7411px)',
          background: 'rgba(233, 207, 208, 0.01)',
          height: '32px',
          width: '32px'
        }}
        onClick={() => setShow(!show)}
        className="rounded-full flex items-center justify-center cursor-pointer">
        <img
          style={{
            width: screenSize.width > 765 ? '22px' : '14px'
          }}
          src={IconAssets.plus}
          alt=""
        />
      </div>
      {show && (
        <motion.div
          style={{
            marginTop: '20px',
            width: screenSize.width > 765 ? '467px' : 'auto',
            borderRadius: '12px',
            padding: '24px',
            boxShadow:
              'inset 0px 5.07px 13.0273px -8.37468px rgba(255, 255, 255, 0.5), inset 0px -19.0757px 15.82px -14.8883px rgba(121, 108, 145, 0.15), inset 0px 22.7977px 23.263px -11.1662px rgba(202, 172, 255, 0.2), inset 0px 0.93052px 4.18734px rgba(154, 146, 210, 0.3), inset 0px 0.23263px 9.3052px rgba(227, 222, 255, 0.2)',
            background: 'rgba(233, 207, 208, 0.01)',
            backdropFilter: 'blur(32.7411px)',
            WebkitBackdropFilter: 'blur(32.7411px)'
          }}
          variants={variants}
          initial="hide"
          animate={show ? 'show' : 'hide'}>
          <div className="flex items-start flex-col">
            <div className="w-full">
              <h2
                className="font-bold text-center"
                style={{
                  color: COLORS.white,
                  fontWeight: 'bold'
                }}>
                {tokenName}
              </h2>
              <SizedBox height={10} />
            </div>
            <div className="w-full flex justify-between">
              <div className="w-1/2">
                <h3
                  className="font-bold"
                  style={{
                    color: COLORS.white,
                    fontWeight: 'bold'
                  }}>
                  Earned by
                </h3>
                <SizedBox height={10} />
                <p
                  className="text-sm"
                  style={{
                    color: COLORS.white
                  }}>
                  {earnedBy}
                </p>
              </div>
              <div
                className="opacity-30 self-stretch"
                style={{
                  margin: '0px 20px',
                  width: '1px',
                  backgroundColor: COLORS.white
                }}
              />
              <div className="w-1/2">
                <h3
                  className="font-bold"
                  style={{
                    color: COLORS.white,
                    fontWeight: 'bold'
                  }}>
                  Utility
                </h3>
                <SizedBox height={10} />
                {utilities.map((item: string, index: number) => {
                  return (
                    <div key={index} className="mb-1 flex items-center gap-2">
                      <div
                        className="rounded-full"
                        style={{
                          height: '5px',
                          width: '5px',
                          border: '1px solid white'
                        }}
                      />
                      <p
                        className="text-sm"
                        style={{
                          color: COLORS.white
                        }}>
                        {item}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  )
}

export default WaveFloaty
