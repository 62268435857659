/* eslint-disable @typescript-eslint/no-unused-vars */
import SizedBox from 'components/Divider/SizedBox'
import Footer from 'components/Footer/Footer'
import ModalHolder from 'components/Modals/ModalHolder'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import COLORS from 'constants/Colors'
import { useEffect, useState } from 'react'
import DojoSection from './Components/DojoSection'
import HeroSection from './Components/HeroSection'
import HorizontalSection from './Components/HorizontalSection'
import JourneySection from './Components/JourneySection'
import PlatformSection from './Components/PlatformSection'
import WaveSection from './Components/WaveSection'
import ServicesSlide from './Components/ServicesSlide'
import './Styles.scss'
import { useNavigate } from 'react-router'
import LotusMonkSection from './Components/LotusMonkSection'
import ImageAssets from 'assets/ImageAssets'

const HomePage = () => {
  const navigate = useNavigate()
  const [showRegister, setShowRegister] = useState(false)

  useEffect(() => {
    document.title = 'Metawise | Home'
  }, [])

  return (
    <div
      className="relative"
      style={{
        backgroundColor: COLORS.backgroundColor
      }}>
      {/* modal */}
      <ModalHolder
        modalType="register"
        visible={showRegister}
        close={() => setShowRegister(false)}
      />
      {/* hero section */}
      <HeroSection apply={() => navigate('/get-started')} />
      <div className="relative">
        <PlatformSection />
        <StaggerWrapper>
          <LotusMonkSection
            image={ImageAssets.monkm1}
            details={`Mystics, seers, and seekers of truth have embarked on inner journeys, unveiling profound insights into the nature of consciousness and the universe.\n\nWhile you read this sentence, you are engaging with the most sophisticated technology known to humankind.\n\nThese visionaries harnessed its potential to foster spiritual growth. Their profound spiritual legacy forms the bedrock of meditation practices that continue to inspire and guide us today`}
            title="The Spiritual Legacy"
            description="Throughout history, meditation has served as a gateway to inner wisdom and spiritual exploration"
          />
          <SizedBox height={20} />
          <LotusMonkSection
            imageSize="small"
            image={ImageAssets.futuref3}
            details="This unprecedented initiative is not only decoding the enigmas of consciousness but also redefining the field of meditation, marking a unique fusion of science and spirituality. As we journey into this uncharted frontier of human potential, we anticipate uncovering secrets of the mind that remain unknown. Your involvement is instrumental in advancing our understanding and unlocking the vast potential of human consciousness."
            title="The Scientific Frontier"
            description="Today, we are harnessing the power of advanced EEG analysis, machine learning, and
            blockchain technology through the integration of Neurosity CROWN™. This conscious
            confluence is facilitating the creation of the largest-scale brainwave dataset in
            history. By participating in our inaugural study, aimed at discerning the differences
            between meditative and non-meditative states, you are directly contributing to this
            groundbreaking endeavor."
          />
        </StaggerWrapper>
        {/* <StaggerWrapper>
          <MonkSection />
          <LotusSection />
        </StaggerWrapper> */}
        <SizedBox height={150} />
        <DojoSection />
        <HorizontalSection />
        {/* <SizedBox height={100} /> */}
        <WaveSection />
        {/* <SizedBox height={160} /> */}
        {/* <AssistantSection /> */}
        <ServicesSlide />
        <SizedBox height={100} />
        <JourneySection />
        <Footer />
      </div>
    </div>
  )
}

export default HomePage
