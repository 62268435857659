import HamburgerButton from 'components/Button/HamburgerButton'
import Logo from 'components/Logo/Logo'
import { useEffect, useState } from 'react'
import useResize from 'utils/useResize'
import Menu from './Components/Menu'
import SideMenu from './Components/SideMenu'
import OnboardingMenuItem from './Components/OnboardingMenuItem'
import GetStartedButton from './Components/GetStartedButton'
import HoverMenuItem from './Components/HoverMenuItem'
import SoundBar from './Components/SoundBar'
import HoverItem from './Components/HoverItem'
import SizedBox from 'components/Divider/SizedBox'
import { useNavigate } from 'react-router'
import COLORS from 'constants/Colors'

const Header = () => {
  const currentPath = window.location.pathname
  const screenSize = useResize()
  const navigate = useNavigate()
  const [menuVisible, setMenuVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', function (_event: any) {
      const mHeader = document.querySelector('header')
      if (mHeader) {
        if (this.scrollY >= 50) {
          mHeader.style.backgroundColor = `${COLORS.backgroundColor}4D`
        } else {
          mHeader.style.backgroundColor = 'transparent'
        }
      }
    })
  }, [])

  const closeMenu = () => {
    setMenuVisible(false)
  }
  const openMenu = () => {
    setMenuVisible(true)
  }
  return (
    <header className="fixed w-screen z-10">
      <div className="py-10 px-6 md:px-0 container mx-auto flex flex-row items-center justify-between">
        <Logo size={screenSize.width > 765 ? 'large' : 'small'} />
        <div
          className={`${screenSize.width > 888 ? 'flex' : 'hidden'} items-center justify-end`}
          style={{
            width: '20%'
          }}>
          <SoundBar />
        </div>

        <div className={`${screenSize.width > 888 ? 'flex' : 'hidden'} flex-row items-center`}>
          {/* menu icon */}
          <HoverMenuItem
            to="/about"
            description="Read our documentation"
            title="About"
            active={currentPath.includes('/about')}>
            <HoverItem to="/">Whitepaper</HoverItem>
            <SizedBox height={10} />
            <HoverItem to="/">Litepaper</HoverItem>
          </HoverMenuItem>
          <Menu active={currentPath.includes('roadmap')} to="/roadmap">
            Roadmap
          </Menu>
          <OnboardingMenuItem active={currentPath.includes('onboarding')}>
            Onboarding
          </OnboardingMenuItem>
          <HoverMenuItem
            to="/science"
            description="Learn more"
            title="Science"
            active={currentPath.includes('/science')}>
            <HoverItem to="/research">Research</HoverItem>
          </HoverMenuItem>
          <SizedBox width={40} />
          <GetStartedButton />
        </div>
        {screenSize.width > 888 ? (
          <></>
        ) : (
          <HamburgerButton size={screenSize.width > 888 ? 'large' : 'small'} onClick={openMenu} />
        )}
      </div>
      {menuVisible && <SideMenu close={closeMenu} />}
    </header>
  )
}

export default Header
