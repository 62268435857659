import IconAssets from 'assets/IconAssets'
import SlideButton from './SlideButton'
import useResize from 'utils/useResize'
import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import { motion } from 'framer-motion'
import { slideUpNoDelay, textVariantNoDelay } from 'utils/motion'
import { useNavigate } from 'react-router'

interface JourneySectionProps {
  description?: string
}
const JourneySection = ({
  description = "Join now and participate in the next phase of humanity's conscious evolution"
}: JourneySectionProps) => {
  const navigate = useNavigate()
  const screenSize = useResize()
  return (
    <StaggerWrapper delay={0.75}>
      <motion.div
        style={{
          marginTop: screenSize.width > 765 ? -120 : -200
        }}>
        <div
          style={{
            backgroundImage: `url(${ImageAssets.etherealBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: screenSize.width > 765 ? '100%' : '190%',
            backgroundPosition: 'center',
            backgroundPositionX: screenSize.width > 765 ? '20px' : '-170px',
            backgroundPositionY: screenSize.width > 765 ? undefined : '230px'
          }}
          className="container px-5 md:px-0 mx-auto flex flex-col items-center">
          <SizedBox height={screenSize.width > 765 ? 200 : 300} />
          <motion.p
            variants={textVariantNoDelay()}
            style={{
              fontSize: screenSize.width > 765 ? '32px' : '18px',
              color: '#FCFCFC',
              width: screenSize.width > 765 ? '618px' : undefined
            }}
            className="text-center mb-10">
            {description}
          </motion.p>
          <SlideButton
            href="https://dojo.metawise.gg"
            variant={slideUpNoDelay()}
            direction="left"
            image={<img src={IconAssets.bolt} />}>
            Begin Your Journey
          </SlideButton>
          <SizedBox height={200} />
        </div>
      </motion.div>
    </StaggerWrapper>
  )
}

export default JourneySection
