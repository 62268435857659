import SizedBox from 'components/Divider/SizedBox'
import COLORS from 'constants/Colors'

interface SimpleStepItemProps {
  title: string
  number: number
  description: string
}
const SimpleStepItem = ({ title, description, number }: SimpleStepItemProps) => {
  return (
    <div
      className="flex flex-col relative"
      style={{
        minHeight: '150px',
        backgroundColor: '#2E2633',
        padding: '10px',
        borderRadius: '10px'
      }}>
      <div
        style={{
          top: '-40px'
        }}
        className="absolute left-0 flex items-center justify-center right-0">
        <div
          style={{
            border: `10px solid ${COLORS.backgroundColor}`,
            width: '80px',
            height: '80px',
            backdropFilter: 'blur(15.9281px)',
            WebkitBackdropFilter: 'blur(15.9281px)',
            backgroundColor: 'rgba(233, 207, 208, 0.01)',
            boxShadow:
              'nset 0px 2.46649px 6.33759px -4.07417px rgba(255, 255, 255, 0.5), inset 0px 0.792199px 0.486486px -0.452685px #FFFFFF, inset 0px -9.28005px 7.69622px -7.24296px rgba(121, 108, 145, 0.15), inset 0px 11.0908px 11.3171px -5.43222px rgba(202, 172, 255, 0.2), inset 0px 0.452685px 2.03708px rgba(154, 146, 210, 0.3), inset 0px 0.113171px 4.52685px rgba(227, 222, 255, 0.2)'
          }}
          className="rounded-full flex items-center justify-center">
          <p className="text-white text-center">{number}</p>
        </div>
      </div>
      <SizedBox height={40} />
      <h2 className="font-bold text-center text-white">{title}</h2>
      <p
        className="text-center text-sm text-white"
        style={{
          opacity: 0.6
        }}>
        {description}
      </p>
    </div>
  )
}

export default SimpleStepItem
