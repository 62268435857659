import IconAssets from 'assets/IconAssets'
import StaggerWrapper from 'components/Wrappers/SectionWrapper'
import useResize from 'utils/useResize'
import AboutSlideShow from './AboutSlideShow'
import { motion } from 'framer-motion'
import { slideInNoDelay, textVariantNoDelay } from 'utils/motion'

const CombinationSection = () => {
  const screenSize = useResize()

  return (
    <StaggerWrapper>
      <div
        className={`flex ${
          screenSize.width > 765 ? 'flex-row' : 'flex-col'
        } items-start container justify-between mx-auto px-5 md:px-0`}>
        <motion.img
          animate={{
            marginTop: ['0px', '10px', '0px']
          }}
          transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
          variants={slideInNoDelay('left')}
          className="hidden md:block"
          src={IconAssets.accessibility}
          alt=""
        />
        <div
          style={{
            marginTop: '50px'
          }}
          className="flex flex-col items-center">
          <motion.p
            variants={textVariantNoDelay()}
            className="text-center"
            style={{
              color: '#FCFCFC',
              fontSize: '32px'
            }}>
            The combination of blockchain technology, neuroscience and spiritual practices has
            several advantages:
          </motion.p>
          <motion.img
            animate={{
              marginTop: ['32px', '28px', '32px']
            }}
            transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
            variants={slideInNoDelay('right')}
            className="hidden md:block"
            style={{
              marginTop: '32px'
            }}
            src={IconAssets.globalProgress}
            alt=""
          />
        </div>
        <motion.img
          animate={{
            marginTop: ['200px', '180px', '200px'],
            transform: ['rotate(0deg)', 'rotate(15deg)', 'rotate(0deg)']
          }}
          transition={{ repeat: Infinity, repeatDelay: 0.5, duration: 2 }}
          variants={slideInNoDelay('right')}
          className="hidden md:block"
          style={{
            marginTop: '200px'
          }}
          src={IconAssets.supporting}
          alt=""
        />
        <AboutSlideShow />
      </div>
    </StaggerWrapper>
  )
}

export default CombinationSection
