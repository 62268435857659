export default {
  logo: 'assets/images/logo.png',
  headerLogo: 'assets/images/header-logo.png',
  textLogo: 'assets/images/text-logo.png',
  twitterLogo: 'assets/images/TwitterLogo.png',
  discordLogo: 'assets/images/DiscordLogo.png',
  nebula: 'assets/images/nebula.png',
  brain: 'assets/images/brain.png',
  brain2: 'assets/images/brain-2.png',
  heroVideo: 'assets/images/hero-video.png',
  tree: 'assets/images/tree.png',
  dojo: 'assets/images/dojo.png',
  //   monk : 'assets/images/monk.png'
  monkm1: 'assets/images/monk_m1.png',
  waves: 'assets/images/waves.png',
  //   lotus : 'assets/images/lotus.png'
  //  futuref2 : 'assets/images/future_f2.png'
  futuref3: 'assets/images/lotus-raksa3.png',
  brainLight: 'assets/images/brain-light.svg',
  //  heroBG : 'assets/images/BG.png'
  heroBG1: 'assets/images/BG_brain1_fade_trans.png',
  heroBG2: 'assets/images/BG_brain2_fade_trans.png',
  medWave: 'assets/images/med-wave.png',
  waveBG: 'assets/images/wave-bg.png',
  menuImage: 'assets/images/menu-image.png',
  manWaves: 'assets/images/man-waves.png',
  smallMenuImage: 'assets/images/small-menu-image.png',
  smallHeroBg: 'assets/images/small-herobg.png',
  brainBg: 'assets/images/brainBG.png',
  journeyBg: 'assets/images/journeyBG.png',
  etherealBg: 'assets/images/etherealBG.png',
  transmute: 'assets/images/Transmute.png',
  ce: 'assets/images/CE.png',
  re: 'assets/images/RE.png',
  playlist: 'assets/images/playlist.png',
  collectiveMeditation: 'assets/images/collective-meditations.png',
  vote: 'assets/images/vote.png',
  roadmapHero: 'assets/images/roadmap-hero.png',
  roadmap1: 'assets/images/roadmap1.png',
  roadmapBackground: 'assets/images/roadmap-background.png',
  contactButton: 'assets/images/contact-button.png',
  contactButton2: 'assets/images/contact-button2.png',
  research: 'assets/images/research.png',
  technology: 'assets/images/technology1.png',
  science: 'assets/images/science.png',
  mysticscience: 'assets/images/mysticismscience3.png',
  ml: 'assets/images/ml.png',
  mri: 'assets/images/mri.png',
  eeg: 'assets/images/eeg.png',
  fusion: 'assets/images/fusion.png',
  mission: 'assets/images/mission.png',
  future: 'assets/images/future.png',
  benefits: 'assets/images/benefits.png',
  onboarding: 'assets/images/onboarding.png',
  teacheronboarding: 'assets/images/teacher-onboarding.png',
  community: 'assets/images/community1.png',
  logobrain: 'assets/images/logo-brain.png',
  interest: 'assets/images/interest.png',
  content: 'assets/images/content.png',
  ai: 'assets/images/ai.png',
  aiAssistant: 'assets/images/aiAssistant.png',
  aialanwatts: 'assets/images/aialanwatts.png',
  fair: 'assets/images/fair.png',
  dalai: 'assets/images/dalai.png',
  vission: 'assets/images/vission.png',
  missionvision: 'assets/images/mission-vision3.png',
  missionvision1: 'assets/images/mission-vision4.png',
  missionvision2: 'assets/images/mission-vision5.png',
  medit: 'assets/images/medit.png',
  femonk: 'assets/images/femonk.png',
  phone: 'assets/images/phone.png',
  flowers: 'assets/images/flowers.png',
  keyFunctions: 'assets/images/Key-functions.png',
  serviceLabs: 'assets/images/service-labs3.png',
  //  serviceUniversity : 'assets/images/service-university.png'
  serviceUniversity1: 'assets/images/mwuniversity.png',
  serviceCommunity: 'assets/images/services-community-rh.png',
  serviceAssistant: 'assets/images/service-ai.png',
  serviceWeb3: 'assets/images/service-metaverse.png',
  serviceDojo: 'assets/images/service-dojo3.png',
  starsBG: 'assets/images/starsBG.png',
  starsGIF: 'assets/images/starsGIF.gif',
  scienceLG: 'assets/images/science-lg.png',
  stars: 'assets/images/stars.png',
  twinkling: 'assets/images/twinkling.png',
  twinkle: 'assets/images/twinkle.png',
  hdStars: 'assets/images/hd_stars.png',
  ceFinal: 'assets/images/ce-final.png',
  reFinal: 'assets/images/re-final.png',
  activity: 'assets/images/activity.png',
  meditate: 'assets/images/meditate.png',
  transcend: 'assets/images/transcend.png',
  levelUp: 'assets/images/levelup.png',
  conscious: 'assets/images/consciuos.png',
  earn: 'assets/images/earn.png',
  headset: 'assets/images/headset.png',
  connect: 'assets/images/connect.png',
  platform: 'assets/images/platform.png',
  rent: 'assets/images/rent.png',
  metalabs: 'assets/images/metalabs.png',
  ramzi: 'assets/images/ramzi.png',
  rafael: 'assets/images/rafael.png',
  daniel: 'assets/images/daniel.png',
  kyle: 'assets/images/kyle.png',
  teamExpand: 'assets/images/team_expand.png',
  teamClose: 'assets/images/team_close.png',
  dat: 'assets/images/dat.png',
  david: 'assets/images/david.png',
  james: 'assets/images/james.png',
  soon: 'assets/images/soon.png',
  tokenStep: 'assets/images/tokenStep.png',
  alphaStep: 'assets/images/alphaStep.png',
  alphaStep1: 'assets/images/alphaStep1.png',
  referralStep: 'assets/images/referralStep.png'
}
