import Logo from 'components/Logo/Logo'
import COLORS from 'constants/Colors'
import { useEffect } from 'react'
import useResize from 'utils/useResize'

interface GetStartedHeaderProps {
  currentPage: number
}

const GetStartedHeader = ({ currentPage }: GetStartedHeaderProps) => {
  useEffect(() => {
    window.addEventListener('scroll', function (_event: any) {
      const mHeader = document.querySelector('header')
      if (mHeader) {
        if (this.scrollY >= 50) {
          mHeader.style.backgroundColor = `${COLORS.backgroundColor}4D`
        } else {
          mHeader.style.backgroundColor = 'transparent'
        }
      }
    })
  }, [])

  const screenSize = useResize()
  return (
    <header
      style={{
        zIndex: 20
      }}
      className="fixed w-screen">
      <div className="relative container py-10 mx-auto px-6 md:px-0">
        <div className="flex w-full items-center justify-between">
          <Logo size={screenSize.width > 765 ? 'large' : 'small'} />
          <p
            className="font-semibold text-sm"
            style={{
              color: COLORS.white
            }}></p>
        </div>
      </div>
    </header>
  )
}

export default GetStartedHeader
