import ImageAssets from 'assets/ImageAssets'
import SizedBox from 'components/Divider/SizedBox'
import Footer from 'components/Footer/Footer'
import ScienceParagraph from 'pages/Science/Components/ScienceParagraph'
import useResize from 'utils/useResize'
import DetailSection from './Components/DetailSection'
import OnboardingHeroSection from './Components/OnboardingHeroSection'
import OnboardingPlatformSection from './Components/OnboardingPlatformSection'
import QuotesSection from './Components/QuotesSection'
import SimpleStepsSection from './Components/SimpleStepsSection'
import JourneySection from 'pages/Home/Components/JourneySection'
import ContactCategorySection from 'pages/Contact/Components/ContactCategorySection'
import { useEffect } from 'react'

const Onboarding = () => {
  const screenSize = useResize()
  useEffect(() => {
    document.title = 'Metawise | Teachers'
  }, [])
  return (
    <div>
      <OnboardingHeroSection />
      <div className="relative overflow-x-hidden md:overflow-x-auto">
        <OnboardingPlatformSection />
        <SizedBox height={100} />
        <DetailSection subtitle="Customize teaching" image={ImageAssets.interest}>
          <ScienceParagraph>
            Begin by filling out a questionnaire which will assist us in comprehending your teaching
            style, interests, and areas of proficiency. With this questionnaire, we can design a
            tailored experience for both you and our users that meets your distinct needs and goals.
          </ScienceParagraph>
        </DetailSection>
        <DetailSection direction="left" subtitle="Content Creation" image={ImageAssets.content}>
          <ScienceParagraph>
            Once you&apos;ve completed the questionnaire, you&apos;ll be ready to start creating
            diverse and high-quality content in a variety of formats, including guided meditations,
            bedtime stories, teaching videos, Q&A sessions, personalized messages, and workshops.
          </ScienceParagraph>
          <ScienceParagraph>
            Whether you&apos;re a seasoned veteran or a newcomer to the world of meditation, we
            believe that your unique perspective and knowledge will help transform the lives of our
            users.
          </ScienceParagraph>
        </DetailSection>
        <div
          style={{
            margin: '40px auto'
          }}
          className="flex container mx-auto px-5 md:px-0 items-center justify-center">
          <p
            style={{
              color: '#FCFCFC',
              fontSize: screenSize.width > 765 ? '32px' : '18px',
              lineHeight: '140%',
              width: '518px'
            }}
            className="text-center">
            Our platform uses machine learning and blockchain to provide a secure, transparent, and
            fair environment that rewards your expertise and commitment.
          </p>
        </div>
        <div>
          <DetailSection subtitle="Train Your AI Avatar" image={ImageAssets.ai}>
            <ScienceParagraph>
              As you create content on our platform, we&apos;ll be using a sophisticated machine
              learning algorithm to train a personalized AI model that simulates your personality
              and style. This AI model will allow users to continue learning and meditating with
              you, even when you&apos;re not present. By leveraging the power of blockchain
              technology, we&apos;re able to ensure that you&apos;re rewarded fairly and securely
              for your contributions.
            </ScienceParagraph>
          </DetailSection>
          <DetailSection direction="left" subtitle="Simple analytics" image={ImageAssets.eeg}>
            <ScienceParagraph>
              With our easy-to-use dashboard, you&quot;ll be able to track you and your student’s
              progress, monitor your earnings, and see how much content you need to create to unlock
              the &quot;immortal&quot; or &quot;simulated AI&quot; mode.
            </ScienceParagraph>
            <ScienceParagraph>
              We believe that by working together, we can create a platform that empowers you to
              share your knowledge and expertise with the world, and achieve your personal and
              professional goals.
            </ScienceParagraph>
          </DetailSection>
          <DetailSection subtitle="Supportive & fair community" image={ImageAssets.fair}>
            <ScienceParagraph>
              As you create content on our platform, we&apos;ll be using a sophisticated machine
              learning algorithm to train a personalized AI model that simulates your personality
              and style. This AI model will allow users to continue learning and meditating with
              you, even when you&apos;re not present. By leveraging the power of blockchain
              technology, we&apos;re able to ensure that you&apos;re rewarded fairly and securely
              for your contributions.
            </ScienceParagraph>
          </DetailSection>
        </div>
        <QuotesSection />
        <SizedBox height={140} />
        <SimpleStepsSection />
        <SizedBox height={140} />
        <JourneySection description="Ready to transform your meditation practice and unlock your full potential? Join Metawise now and participate in the next phase of humanity’s conscious evolution" />
        <ContactCategorySection mCategory="teachers" />
        <SizedBox height={100} />
        <Footer />
      </div>
    </div>
  )
}

export default Onboarding
