import Header from 'components/Header/Header'
import DashboardHeader from './components/DashboardHeader'
import SizedBox from 'components/Divider/SizedBox'
import FONTS from 'constants/Fonts'
import Stars from 'components/Background/Stars/Stars'
import axios from 'axios'
import { useEffect, useState } from 'react'
import DashboardTableContext from './components/DashboardTableContent'
import Footer from 'components/Footer/Footer'

const DashboardPage = () => {
  const [users, setUsers] = useState([])
  const getUsers = () => {
    axios
      .get('https://metawisedojoapi.metawise.gg/api/v1/all-users')
      .then(({ data }) => {
        console.log(data)
        setUsers(data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <div>
      <Header />
      <Stars />
      <div className="relative container mx-auto text-[#FCFCFC]">
        <SizedBox height={80} />
        <h3
          className="text-[78px]"
          style={{
            fontFamily: FONTS.bold
          }}>
          Users
        </h3>
        <p>Below is a list of all users that have signed up through the platform</p>
        <div className="my-10">
          <DashboardHeader />
          <DashboardTableContext data={users} />
        </div>
      </div>
      <div className="relative">
        <Footer />
      </div>
    </div>
  )
}

export default DashboardPage
