/* eslint-disable @typescript-eslint/no-unused-vars */
import SizedBox from 'components/Divider/SizedBox'
import Footer from 'components/Footer/Footer'
import ContactCategorySection from './Components/ContactCategorySection'
import ContactHeroSection from './Components/ContactHeroSection'

const Contact = () => {
  return (
    <div>
      <ContactHeroSection />
      <div className="relative overflow-x-hidden md:overflow-x-auto">
        <ContactCategorySection />
        <SizedBox height={120} />
        <Footer />
      </div>
    </div>
  )
}

export default Contact
