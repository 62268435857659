import Divider from 'components/Divider/Divider'
import SizedBox from 'components/Divider/SizedBox'
import Footer from 'components/Footer/Footer'
import Header from 'components/Header/Header'
import Paragraph from './Components/Paragraph'
import Subtitle from './Components/Subtitle'

const Terms = () => {
  return (
    <div>
      <Header />
      <div className="container px-5 md:px-0 mx-auto">
        <div className="w-full md:w-8/12 mx-auto">
          <h1
            style={{
              color: '#FCFCFC',
              fontSize: '78px'
            }}
            className="text-start md:text-center font-bold">
            Terms Of Service
          </h1>
          <SizedBox height={40} />
          <Divider />
          <SizedBox height={40} />
          <Paragraph>
            Welcome to Metawise, our social EEG meditation platform that leverages AI, ML, and
            blockchain technology. These terms of service (&quot;ToS&quot;) outline the rules and
            regulations for accessing and using our platform. By accessing or using our platform,
            you agree to be bound by these ToS. If you do not agree with any of the terms outlined
            in this document, you may not use our platform.
          </Paragraph>
          {/* user conduct */}
          <Subtitle>User Conduct:</Subtitle>
          <Paragraph>
            1. You are responsible for your behavior on our platform. You agree to use our platform
            in a lawful and responsible manner and not to engage in any behavior that may harm other
            users or our platform.
          </Paragraph>
          <Paragraph>
            2. You agree not to use our platform to engage in any activity that is illegal,
            fraudulent, or infringes on the rights of others.
          </Paragraph>
          <Paragraph>
            3. You agree not to use our platform to upload or distribute any content that is
            unlawful, harmful, threatening, defamatory, obscene, or otherwise objectionable.
          </Paragraph>

          {/* ip */}
          <Subtitle>Intellectual Property:</Subtitle>
          <Paragraph>1. Usage Requirements:</Paragraph>
          <ul className="ml-5">
            <li>
              <Paragraph>
                Use of Services. You may access, and we grant you a non-exclusive right to use, the
                Metawise platform in accordance with these Terms. You will comply with these Terms
                and all applicable laws when using the Metawise platform. We and our affiliates own
                all rights, title, and interest in and to the Metawise platform.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Feedback. We appreciate feedback, comments, ideas, proposals, and suggestions for
                improvements. If you provide any of these things, we may use it without restriction
                or compensation to you.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Restrictions. You may not (i) use the Metawise platform in a way that infringes,
                misappropriates, or violates any person’s rights; (ii) reverse assemble, reverse
                compile, decompile, translate, or otherwise attempt to discover the source code or
                underlying components of models, algorithms, and systems of the Metawise platform
                (except to the extent such restrictions are contrary to applicable law); (iii) use
                the Metawise platform to develop foundation models or other large-scale models that
                compete with us; (iv) use any method to extract data from the Metawise platform,
                including web scraping, web harvesting, or web data extraction methods, other than
                as permitted through the API; (v) represent that output from the Metawise platform
                was human-generated when it is not; or (vii) buy, sell, or transfer API keys without
                our prior consent.
              </Paragraph>
            </li>
          </ul>
          <Paragraph>2. Content Ownership:</Paragraph>
          <ul className="ml-5">
            <li>
              <Paragraph>
                Your Content. You retain all rights, title, and interest in and to any content you
                upload or create on the Metawise platform. However, by using our platform, you grant
                us a non-exclusive, worldwide, royalty-free, and perpetual license to use,
                reproduce, modify, distribute, and display your content for the purposes of
                providing and improving our services.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                Our Content. We own all rights, title, and interest in and to the Metawise platform
                and any content we provide on the platform, including our trademarks, logos, and any
                copyrighted material.
              </Paragraph>
            </li>
          </ul>

          {/* privacy policy */}
          <Subtitle>Privacy Policy:</Subtitle>
          <Paragraph>
            1. Our privacy policy outlines how we collect, use, and protect your personal data. By
            using our platform, you agree to our privacy policy.
          </Paragraph>
          <Paragraph>
            2. You acknowledge that we will collect EEG data and other sensitive information about
            you, and you consent to our collection and use of such data.
          </Paragraph>

          {/* payment and fees */}
          <Subtitle>Payment and Fees:</Subtitle>
          <Paragraph>
            1. Access to the platform requires a digital access pass, also known as our Alpha Pass,
            or your SAIJ avatar, that will be set at $500 each.
          </Paragraph>
          <Paragraph>
            2. The usage of web 3 functions will require making the pass non-transferable or
            Soulbound.
          </Paragraph>
          <Paragraph>
            3. There may be additional payments required for continued usage of functionality such
            as the AI Chatbot. An initial free amount of credits will be given to the user.
          </Paragraph>
          <Paragraph>
            4. Courses will be priced in our native tokens $BE and $CE and set by the teachers
            providing them.
          </Paragraph>

          {/* limitations */}
          <Subtitle>Limitations on Use:</Subtitle>
          <Paragraph>
            1. You may not use our platform to harm others, including by uploading or distributing
            harmful content.
          </Paragraph>
          <Paragraph>
            2. You may share your login information with members of your household, but they are
            required to create their own sub-account. This is to prevent the mixing of data and
            ensure the accuracy of EEG measurements and other user-specific data.
          </Paragraph>
          <Paragraph>
            3. You may not use our platform for commercial purposes, except with our prior written
            permission.
          </Paragraph>

          {/* termination */}
          <Subtitle>Termination:</Subtitle>
          <Paragraph>
            1. We reserve the right to terminate your account at any time for any reason, including
            but not limited to violations of these ToS.
          </Paragraph>
          <Paragraph>
            2. You may terminate your account at any time by burning your soulbound NFT and
            associated data, thereby permanently deleting all data associated with your account.
          </Paragraph>

          {/* indemnifacation */}
          <Subtitle>Indemnification; Disclaimer of Warranties; Limitations on Liability:</Subtitle>
          <Paragraph>
            1. Indemnification. You agree to defend, indemnify, and hold harmless us, our
            affiliates, and our personnel, from and against any claims, losses, and expenses
            (including attorneys’ fees) arising from or relating to your use of the Services,
            including your Content, products or services you develop or offer in connection with the
            Services, and your breach of these Terms or violation of applicable law.
          </Paragraph>
          <Paragraph>
            2. Disclaimer. THE SERVICES ARE PROVIDED &quot;AS IS.&quot; EXCEPT TO THE EXTENT
            PROHIBITED BY LAW, WE AND OUR AFFILIATES AND LICENSORS MAKE NO WARRANTIES (EXPRESS,
            IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO THE SERVICES, AND DISCLAIM ALL
            WARRANTIES INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY
            WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT
            THE SERVICES WILL BE UNINTERRUPTED, ACCURATE, OR ERROR-FREE, OR THAT ANY CONTENT WILL BE
            SECURE OR NOT LOST OR ALTERED.
          </Paragraph>
          <Paragraph>
            3. Limitations of Liability. NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE
            LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
            INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF
            WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY UNDER
            THESE TERMS SHALL NOT EXCEED THE GREATER OF THE AMOUNT YOU PAID FOR THE SERVICE THAT
            GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR ONE HUNDRED
            DOLLARS ($100). THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW.
          </Paragraph>

          {/* laws */}
          <Subtitle>Governing Law and Dispute Resolution:</Subtitle>
          <Paragraph>
            1. These ToS will be governed by and construed in accordance with the laws of the state
            or country where we are located.
          </Paragraph>
          <Paragraph>
            2. Any disputes arising out of or relating to these ToS will be resolved through binding
            arbitration in accordance with the rules of the American Arbitration Association, with
            the arbitration taking place in the state or country where we are located. The
            arbitration will be conducted in English.
          </Paragraph>
          <Paragraph>
            3. You waive any right to participate in a class action lawsuit or class-wide
            arbitration.
          </Paragraph>
          <Paragraph>
            4. Any legal action arising out of or relating to these ToS must be filed within one
            year after the cause of action arises.
          </Paragraph>

          {/* provisions */}
          <Subtitle>General Provisions:</Subtitle>
          <Paragraph>
            1. These ToS constitute the entire agreement between you and us regarding the use of our
            platform.
          </Paragraph>
          <Paragraph>
            2. If any provision of these ToS is found to be invalid or unenforceable, the remaining
            provisions will remain in full force and effect.
          </Paragraph>
          <Paragraph>
            3. Failure to enforce any provision of these ToS does not constitute a waiver of that
            provision.
          </Paragraph>
          <Paragraph>
            4. You may not assign these ToS or transfer any rights to use our platform without our
            prior written consent.
          </Paragraph>
          <Paragraph>
            5. We may assign these ToS or transfer our rights to use our platform without notice or
            consent.
          </Paragraph>

          {/* contact us */}
          <Subtitle>Contact Us:</Subtitle>
          <div className="flex flex-row items-center">
            <Paragraph>
              If you have any questions or concerns regarding these ToS, please contact us at
            </Paragraph>
            <a href="mailto:info@metawise.gg" className="ml-1">
              <p
                style={{
                  color: '#FCFCFC',
                  marginBottom: 10
                }}
                className="underline font-bold">
                info@metawise.gg
              </p>
            </a>
          </div>
        </div>
      </div>
      <SizedBox height={140} />
      <Footer />
    </div>
  )
}

export default Terms
